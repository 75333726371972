import React,{useState,useEffect} from 'react'
import { Link,useLocation } from 'react-router-dom';
import Axios from 'axios';
import {getUserdata} from '../../auth/Athentication'
export default function EmailConfirmationWarning()
{
    const userInfo = getUserdata();
    let location = useLocation();
    const currentPage = location.pathname;
    const [sentMessage,setSentMessage] =useState(false)
    const [showWarningMessage,setShowWarningMessage] =useState(false)
    const [isSubmitting,setIsSubmitting] =useState(false)
    const resendConfirmationEmail = (event) =>{
        setIsSubmitting(true);
        event.preventDefault();
        Axios.post('user/resend-email-confirmation').then(res=>{
            if(typeof(res.data.status)!='undefined' && res.data.status== 'success'){
                setIsSubmitting(false);
                setSentMessage(true);
            }
        }).catch(err=>{
            setIsSubmitting(false);
            console.log(err);
        })
    }
    const getCurrentPathWithoutLastPart = () => {
        return currentPage.slice(0, currentPage.lastIndexOf('/'))
    }

    useEffect(() => {
        handleWarningMessage();
    }, []);

    function handleWarningMessage()
    {
        var curPage = getCurrentPathWithoutLastPart();
        console.log('page is '+curPage);
        if(curPage!='/user-email-confirmation'){
            setShowWarningMessage(true);
            return;
        }
        setShowWarningMessage(false);
        return false;
    }
    return(
        <>
        {!userInfo.email_verified_at && showWarningMessage ?
        <section className="email_alert">
          <div className="conti">
            <div className="row">
              <div className="col-md-12">
                {sentMessage ? <div className="mb-0 alert alert-info">We sent an email to {userInfo.email}, please check your email and confirm.</div> : isSubmitting ? <div className="mb-0 alert alert-warning">E-mail is sending please wait...</div> : <div className="mb-0 alert alert-warning">Please confirm your email by clicking the link we sent to {userInfo.email} <Link to="#" onClick={resendConfirmationEmail}>Resend</Link> </div>  }
              </div>
            </div>
          </div>
        </section>
        :''}
        </>
    )
}