import React,{useEffect,useState} from "react";
import axios from 'axios';
import {IsAuthenticated,siteName} from '../auth/Athentication'
import {Link, useLocation } from "react-router-dom";

function Footer() {
    let location = useLocation();
    const [followusLinks,setFollowusLinks] = useState({});
    const [siteInfo,setSiteInfo] = useState({});
    const [showGetstartedBtn,setShowGetstartedBtn] = useState(false);
    useEffect(()=>{
      get_footer_data();
    },[]);
    async function get_footer_data()
    {
      const res = await axios.get('get-footer-data');
      if(res.data.data && res.data.status == 'success'){
          setFollowusLinks(res.data.data.followus);
          setSiteInfo(res.data.data.site_info);
      }else{
        alert('internal server Error');
        console.log(res);
      } 
    } 

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function handleScroll() {  
      if(document.documentElement.scrollTop > 200){
        setShowGetstartedBtn(true);
      }else{
        setShowGetstartedBtn(false);
      }
    }
    const siteTitle = siteName();

    return (
      <div>
        <section className="ftr-sec">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-3 half-width">
                <img src="/assets/img/logo.svg" className="ftr_logo" />
                <h3 className="footer-logo-slogon">{siteInfo && Object.keys(siteInfo).length ? siteInfo.logo_slogan : ''}</h3>
                <p>
                  {siteInfo && Object.keys(siteInfo).length ? siteInfo.footer_aboutus : ''}
                  <br />
                  <Link to="/about" className="read_button">
                    Read More
                  </Link>
                </p>
              </div>
              <div className="col-md-2 half-width">
                <h3>Quick Links</h3>
                <ul className="ftr-links">
                  <li>
                    <Link to="/about">
                      <img src="/assets/img/next.png" /> About
                    </Link>
                  </li>
                  <li>
                    <a href={process.env.REACT_APP_BLOGS_URL} target="_blank">
                      <img src="/assets/img/next.png" /> Blog
                    </a>
                  </li>
                  <li>
                    <Link to="/faq">
                      <img src="/assets/img/next.png" /> FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <img src="/assets/img/next.png" /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 half-width">
                <h3>Information</h3>
                <ul className="ftr-links">
                  {/* <li>
                    <Link to ="#">
                      <img src="/assets/img/next.png" /> Account Profile
                    </Link>
                  </li> */}
                  <li>
                    <Link to ="/search">
                      <img src="/assets/img/next.png" /> Event Search
                    </Link>
                  </li>
                  <li>
                    <Link to ="/teach">
                      <img src="/assets/img/next.png" /> {process.env.REACT_APP_TEACHER_HELPER}s
                    </Link>
                  </li>
                  <li>
                    <Link to ="/partners">
                      <img src="/assets/img/next.png" /> Partners
                    </Link>
                  </li>
                  
                  <li>
                    <Link to ="/volunteers">
                      <img src="/assets/img/next.png" /> Volunteers
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 half-width">
                <h3>Policies</h3>
                <ul className="ftr-links">
                  <li>
                    <Link to ="/safety">
                      <img src="/assets/img/next.png" /> Safety
                    </Link>
                  </li>
                  <li>
                    <Link to="/cancellation-policy">
                      <img src="/assets/img/next.png" /> Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/diversity-equity-inclusion">
                      <img src="/assets/img/next.png" /> Diversity, Equity and
                      Inclusion
                    </Link>
                  </li>
                  <li>
                    <Link to ="/terms">
                      <img src="/assets/img/next.png" /> Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <h3>Contact Info</h3>
                <ul className="ftr-links">
                {siteInfo && Object.keys(siteInfo).length && siteInfo.display_phone == '1' ? 
                  <li>
                    <span>
                      <img src="/assets/img/phone.svg" className="icon" />
                    </span>
                      Phone No: <a href ={"tel:"+siteInfo.site_phone}>{siteInfo.site_phone}</a>
                  </li>
                   : ''}
                {siteInfo && Object.keys(siteInfo).length && siteInfo.site_email.length ? 
                  <li>
                    <span>
                      <img src="/assets/img/mail.svg" className="icon" />
                    </span>
                    Email ID: <a  href ={"mailto:"+siteInfo.site_email}>{siteInfo.site_email}</a>
                  </li>
                  :''}
                </ul>
                <p className="sign-up-p">
                  <strong>Sign Up for Exclusive Offers</strong>
                </p>
                <div className="form-group signup-group">
                  <input
                    type="text"
                    className="form-control join-input"
                    id="search"
                    placeholder="Email ID"
                    name="search"
                  />
                  <button type="submit" className="btn btn-primary send_btn">
                    <img src="/assets/img/send.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer id="footer">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-6 text-left">
                <div className="copyright">
                  ©2021{" "}
                  <u>
                    <span>{siteName()}</span>
                  </u>{" "}
                  | All Rights Reserved.
                </div>
              </div>
              <div className="col-md-6 text-right">
                <ul className="social_links">
                  <li>Follow Us:</li>
                  <li>
                    <a href={followusLinks.facebook_link ? followusLinks.facebook_link :'#'}  target={followusLinks.facebook_link ? '_blank':'_self'}>
                      <img src="/assets/img/facebook.svg" className="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href={followusLinks.twitter_link ? followusLinks.twitter_link :'#'} target={followusLinks.twitter_link ? '_blank':'_self'}>
                      <img src="/assets/img/twitter.svg" className="twitter" />
                    </a>
                  </li>
                  <li>
                    <a href={followusLinks.instagram_link ? followusLinks.instagram_link :'#'} target={followusLinks.instagram_link ? '_blank':'_self'}>
                      <img src="/assets/img/instagram.svg" />
                    </a>
                  </li>
                  <li>
                    <a href={followusLinks.linkedin_link ? followusLinks.linkedin_link :'#'} target={followusLinks.linkedin_link ? '_blank':'_self'}>
                      <img src="/assets/img/linkedin.svg" />
                    </a>
                  </li>
                  {/* <li>
                    <a href={followusLinks.youtube_link ? followusLinks.youtube_link :'#'} target={followusLinks.youtube_link ? '_blank':'_self'}>
                      <img src="/assets/img/youtube.svg" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </footer>
        {!IsAuthenticated() && showGetstartedBtn && (location.pathname !='/teach' && location.pathname !='/signup')  ? 
        <a
          href="/signup"
          data-toggle="modal"
          data-target="#getModal"
          className="sticky-number">
          Join for Free
        </a>
        : ''}
        <a href="#" className="back-to-top">
          <i className="ri-arrow-up-line" />
        </a>
      </div>
    );
  }

export default Footer