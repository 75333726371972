import React, { useState, useEffect,useRef,useContext } from 'react';
import axios from 'axios';
import HeaderTextBanner from '../../../layout/HeaderTextBanner'
import LoadingIcon from '../../../helpers/LoadingIcon';
import SubmittingLoadingImg from '../../../helpers/SubmittingLoadingImg';
import ClassListings from './ClassListings';
import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import Success from '../../../layout/alert/Success'
import {getLoggedFullname} from '../../../auth/Athentication'

import '../../../../css/about.css'
import GlobalContext from "../../../../GlobalContext";

export default function MyClassRoom(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [classList,setClassList]=useState([])
  const [userReviews,setUserReviews]=useState([])
  const [isFetched, setIsFetched] = useState(false);
  const [isPostsFetching, setIsPostsFetching] = useState(false);
  const [roomInfo, setRoomInfo] = useState({});
  const [inputs,setInputs]=useState({review:'',rating:0});
  const [commentFormData,setCommentFormData]=useState({comment:'',post_id:0});
  const [commentErrors,setCommentErrors]=useState({});
  const [teacherInfo, setTeacherInfo] = useState({});
  const [reviewErrors, setReviewErrors] = useState({});
  const [callPosts, setCallPosts] = useState([]);
  const [upcommingClasses, setUpcommingClasses] = useState([]);
  const [completedClasses, setCompletedClasses] = useState([]);
  const [isClassesFetching, setIsClassesFetching] = useState(true);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  
  useEffect(() => {
    getEnrolledClassList();
  },[]);
  
  useEffect(() => {
    getClassMeetings(selectedSectionId);
    if(selectedSectionId){
       getTeacherClassPosts(); 
    }
  }, [selectedSectionId]);
 
  useEffect(() => {
    if(commentFormData.post_id){
       setIsCommentModalOpen(true); 
    }
  }, [commentFormData.post_id]);
  useEffect(() => {
    if(isCommentModalOpen ==false){
      setCommentFormData(commentFormData => ({...commentFormData, post_id:0}));
    }
  }, [isCommentModalOpen]);

  async function getEnrolledClassList(){ //select box list and 
    const res = await axios.get("get-user-enrolled-class-list");
    if(res.data.data){
      setClassList(res.data.data.classes);
      setRoomInfo(res.data.data.meetingInfo)
    }
    setIsFetched(true)
  }

  const getTeacherClassPosts = async () =>{
    setIsPostsFetching(true);
    const res = await axios.get("get-teacher-class-posts",{params:{section_id:selectedSectionId}});
    if(res.data.data){
      setCallPosts(res.data.data);
    }
    setIsPostsFetching(false)
  }
  const handleOnChange = (event)=>{
    event.preventDefault();
    const input = event.target;
    if(input.name == 'review'){
      setInputs(inputs => ({...inputs, [input.name]: input.value}));
    }
  }

  const handleRatingChange = (inputRating)=>{
    setInputs(inputs => ({...inputs, rating: inputRating}));
  }

  const handleSeletedSectionId = (event)=>{
    const input = event.target;
    setSelectedSectionId(input.value);
  }

  async function getClassMeetings(sectionID){
    setIsClassesFetching(true)
    const reqData = {
      params:{
        section_id:sectionID,
      }
    }
    const res = await axios.get("get-user-class-meetings",reqData);
    if(res.data.data){
      setUpcommingClasses(res.data.data.upcomming);
      setCompletedClasses(res.data.data.completed)
      setUserReviews(res.data.data.user_reviews);
      setTeacherInfo(res.data.data.teacher_info);
    }
    setIsClassesFetching(false)
  }

  const deleteUserComment = (e,comment_id) => {
    e.preventDefault();
    if(comment_id){
      e.target.innerText = 'Deleting..';
      axios.post('delete-user-comment',{comment_id:comment_id}).then((res)=>{
        if(res.data.status=='success'){
          getTeacherClassPosts(selectedSectionId);
          setSuccessMessage('Comment removed successfully.');
          setAlertShow(true);
        }
      })
    }
  }

  const handleCommentChange = (event) =>{
    const input = event.target;
    setCommentFormData(commentFormData => ({...commentFormData, comment: input.value}));
  }
  
  const writeComment = (event,post_id) =>{
    event.preventDefault();
    if(post_id){
      setCommentFormData(commentFormData => ({...commentFormData, post_id: post_id}));
    }
  }


//submit review form  
  const submitFormHandler = (event) =>{
    setReviewErrors({review:'',rating:''});
    event.preventDefault();
    if(inputs.review == ''){
      console.log('no review')
      setReviewErrors(reviewErrors => ({...reviewErrors, review: 'Please provide your review.'}));
      return false;
    }else if(inputs.rating == ''){
      setReviewErrors(reviewErrors => ({...reviewErrors, rating: 'Please select rating.'}));
      return false;
    }
  
    let formdata = new FormData();
   
    for ( var key in inputs ) {
      formdata.append(key, inputs[key]);
    }

    if(Object.keys(teacherInfo).length > 0){
      formdata.append('teacher_uuid',teacherInfo.teacher_uuid);
    }else{
      alert('Could not get teacher information.');
      return false;
    }

    axios.post('user/submit-user-rating',formdata).then((res)=>{
      if(res.data.status == 'success'){
        getClassMeetings(selectedSectionId)
      }else{
        alert('Sorry something went wrong.')
      }
    }).catch((err)=>{
      console.log(err)
    })

  }

//Submit comment 
  const submitComment = (event) =>{
    event.preventDefault();
    setIsSubmitting(true);
    if(commentFormData.comment == ''){
      setCommentErrors(commentErrors => ({...commentErrors, comment: 'Please enter your comment.'}));
      setIsSubmitting(false);
      return false;
    }else{
      axios.post('save-post-user-comment',commentFormData).then((res)=>{
        if(res.data.status == 'success'){
          setSuccessMessage('Comment added successfully.');
          getTeacherClassPosts(selectedSectionId);
          setIsCommentModalOpen(false);
        }else{
          alert('Sorry something went wrong.')
        }
      })
    }
  }
  
  return (
    <>
    {alertShow && successMessage && <Success setAlertShow={setAlertShow} message={[successMessage]} show={alertShow} /> }
    <HeaderTextBanner heading="My Events" />
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="parent-profile">
            <div className="row">
              <div className="col-md-12">
                {isFetched ?
               <>
               <div className="row">
                    <div className="col-md-12">
                        <p>Hi, <span>{getLoggedFullname()}</span></p>
                        {classList.length > 0 ? <p>Here is the link to attend the current section</p> :''}
                    </div>
                </div>
             {classList.length > 0 ? 
             <>  
                <div className="row">
                    <div className="col-md-12">
                        { Object.keys(roomInfo).length > 0  ? 
                          <p>Next meeting for a {process.env.REACT_APP_CLASS_HELPER} <strong>{roomInfo.title}</strong> is on {roomInfo.start_time}</p>
                        :''}
                        <div className="meet_box">
                            { Object.keys(roomInfo).length > 0  ? 
                                <a target="_blank" href={roomInfo.join_url} className="get_button">Join Meeting at {roomInfo.start_time}</a>
                               : <p>No meeting found.</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-5">
                        <div className="control">
                            <select className="form-control" onChange={handleSeletedSectionId} name="class-id" id="class">
                                <option value="">Select Class</option>
                                {classList.length > 0 && classList.map((call,index)=>{
                                  return (
                                    <option key={index} value={call.id}>{call.title}</option>
                                  )
                                })} 
                            </select>
                        </div>  
                        </div>
                      </div>
                        {isClassesFetching ?
                      <LoadingIcon /> :
                      <>
                        <div className="class-list-wrapper">
                          <h3>Upcoming Sections</h3>
                          {upcommingClasses.length ?
                              <ClassListings classes={upcommingClasses}  /> : 
                          <p>No upcomming sections found. </p> }
                        </div>
                        <hr/>
                        <div className="class-list-wrapper">
                          <h3>Completed Sections</h3>
                          {completedClasses.length ?
                              <ClassListings classes={completedClasses}  /> : 
                          <p>No completed sections found. </p> }
                        </div>
                        </> }
                    </div>
                    <div className="col-md-4 review_sec">
                      {userReviews.length ? 
                        <>
                          <h3>Reviews</h3>
                          {userReviews.map((review,index)=>{
                            return(
                              <>
                                <hr/>
                                <p className="review_p">{review.review}</p>
                                <p className="star_p">
                                <ReactStars count={5} value={review.rating} edit={false} size={24} activeColor="#FFC107"/>
                                </p>
                              </>
                            )
                          })}

                        </>
                      : selectedSectionId && Object.keys(teacherInfo).length > 0 ? 
                        <div className="review-block pt-4">
                          <form onSubmit={submitFormHandler}>
                          <div className="row">
                            <div className="col-md-12 card card-body">
                                <h4>Rating</h4>
                                <p>Please leave the review for the {process.env.REACT_APP_TEACHER_HELPER} <strong>{teacherInfo.teacher_name}</strong> </p>
                                <div className="form-group">
                                  <label htmlFor="">Description</label>
                                  <textarea value={inputs.review} onChange={handleOnChange} name="review" id="review-description" rows={5} className="form-control"></textarea>
                                    {Object.keys(reviewErrors).length > 0  && reviewErrors.review ? <span className="text-danger">{reviewErrors.review}</span> : ''}
                                </div>
                                <div className="form-group">
                                  <ReactStars onChange={handleRatingChange} count={5} size={45} activeColor="#FFC107"/>
                                    {Object.keys(reviewErrors).length > 0  && reviewErrors.rating ? <span className="text-danger">{reviewErrors.rating}</span> : ''}
                                </div>
                                <div className="form-group">
                                  <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                          </div>
                          </form>
                        </div>
                      : ''}
                    </div>
                </div>

      <div className="row">
        <div className="col-md-12">
          <h2>{process.env.REACT_APP_TEACHER_HELPER} Posts</h2>
          {isPostsFetching==false && callPosts.length > 0 ? callPosts.map((post,index2)=>{
            return (
          <div className="post_box" key={index2}>
            <div className="row">
              <div className="col-md-8">
                <div className="tb-row">
                  <div className="tb-cell">
                    <img src={post.teacher_photo} />
                  </div>
                  <div className="tb-cell text-left pd-left">
                    <p>{post.created_date}</p>
                    <p><a href="#teacher"><strong>{post.teacher_name}</strong></a></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-right">
              </div>
            </div>
            <div className="col-md-12">
            <p>{post.post_message}</p>
            </div>
            <hr />
            <div className="col-md-12">
            {post.comments.length ? post.comments.map((comment,index1)=>{
              return(
                  <div key={index1} className="card card-body post-comment mb-2">
                    <p><img className="img rounded-circle" src={comment.photo} alt="user photo" height={40} width={40} /> <strong>{comment.is_current_user_comment ? 'You' : comment.student_name}</strong> <span>{comment.created_date}</span></p>
                    <p className="mb-0">{comment.comment}</p>
                    {comment.is_current_user_comment ? 
                      <div className="text-right">
                        
                        <Link href="#" className="text-danger" onClick={(e)=>deleteUserComment(e,comment.id)}>Delete</Link></div>
                      :''}
                  </div>
                )}
                ) : ''}
                { <div className="text-right">
                    <Link to="#" className="btn btn-primary" onClick={(e)=>writeComment(e,post.id)} >Write comment</Link>
                  </div>
                }
                </div>
          </div>
            )}
            ) : 
            <p>No posts found.</p>    
            }
        </div>
      </div>
      
                          </>: 
                        <div className="row">
                          <div className="col-md-12">
                            You have not purchased any Class.
                          </div>
                        </div>   
                      }
               </>
                : <LoadingIcon /> }
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal dialogClassName="profile-child-modal" show={isCommentModalOpen} onHide={()=>{setIsCommentModalOpen(false)}} >
        <Modal.Header closeButton>
        <div className="img-box  align-items-center">
            <img src="/assets/img/icon-logo.svg" />
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="form-box">
            <h3>Write a comment</h3>
            <div className="row">
              <div className="col-md-12">
                <form className="form-horizontal login_model" onSubmit={submitComment} >
                  <fieldset>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="controls">
                            <textarea name="comment" onChange={handleCommentChange} value={commentFormData.comment} className="form-control text-area" placeholder="Write your comment here." />
                          </div>
                          {commentErrors.comment && <p className="text-danger">{commentErrors.comment}</p>}
                        </div>
                      </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <button id="confirmsignup" disabled={isSubmitting} type="submit" name="confirmsignup" className="btn btn-success register-btn">Save</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <button type="button" onClick={()=>setIsCommentModalOpen(false)} className="btn btn-success register-btn clr_change">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
     
    </>
  );
}
