import React from 'react';
import { Link } from 'react-router-dom';
export default function DashboardUpcomingMeeting(props) {

    return (
        <div className="myclass">
            <div className="tb-row">
                <div className="tb-cell">
                    <img
                        className="img-responsive teacher-img-new"
                        src={props.section.class_photo}
                    />
                    <span className="date_span">{props.section.section_date}</span>
                </div>
                <div className="tb-cell text-left pd-left">
                    <p>
                        <Link onClick={() => { localStorage.setItem('sectionAcitveTab', 'EditSection') }} className="time_link" to={`/section/${props.section.class_uid}/${props.section.section_uid}`}>
                            <span>{props.section.meeting_time}</span>
                        </Link>
                    </p>
                    <p className="class-name">
                        <Link onClick={() => { localStorage.setItem('classDefaultTab', 'ClassSummaryTab') }} to={'/class/edit/' + props.section.class_uid}>
                            <strong>
                                {props.section.class_name}
                            </strong>
                        </Link>
                    </p>
                    <p> {props.section.enroll_count} enrolled</p>
                    <ul className="favorite_ul">
                        <li>
                            <a href="#" className="not-list">
                                {props.section.visibility}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}