import React, { useState,useEffect } from "react"
import { Link } from "react-router-dom";
import axios from 'axios';
import LoginModel from "../layout/LoginModel";
import HeaderLoggedOutNav from "../layout/HeaderLoggedOutNav";
import HeaderLoggedInNav from "../layout/HeaderLoggedInNav";
import MaintenanceHeader from "../layout/MaintenanceHeader";

function Header(props) {
  const [siteSettings,setSiteSettings] = useState({});
  const [isMobileMenuHidden, setIsMobileMenuHidden] = useState(false);

  useEffect(() => {
    getSiteSettings();
  }, []);
  

  async function getSiteSettings()
  {
    const response = await axios.get("home/site-settings");
    if(!response.data.data){
      alert('Invalid Request');
      return;
    }
    setSiteSettings(response.data.data);
  } 
  return (
    <>
    {Object.keys(siteSettings).length && siteSettings.display_header_message=='1' ? <MaintenanceHeader content={siteSettings.maintenance_message} /> : ''  }
      <header id="header" className="fixed-top hidden-mobile1">
        <div className="container-fluid d-flex align-items-center cf">
          <Link to="/" className="logo mr-auto scrollto normal-img">
            <img src="/assets/img/logo.svg" alt="" className="img-fluid" />
          </Link>
          <div className="mobile-search">
            <form id="demo-2">
              <input type="search" placeholder="Search for {classes}" />
            </form>
            <button
              type="button"
              className="mobile-nav-toggle d-lg-none"
              onClick={() =>
                setIsMobileMenuHidden(isMobileMenuHidden ? false : true)
              }
            >
              <i className="icofont-navigation-menu"></i>
            </button>
          </div>
          {props.loggedIn ? (
            <HeaderLoggedInNav
              isMobileMenuHidden={isMobileMenuHidden}
              setIsMobileMenuHidden={setIsMobileMenuHidden}
              />
              ) : (
                <HeaderLoggedOutNav
                setIsMobileMenuHidden={setIsMobileMenuHidden}
              isMobileMenuHidden={isMobileMenuHidden}
            />
          )}
        </div>
      </header>
      <LoginModel />
    </>
  );
}

export default Header