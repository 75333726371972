import React, { useState,useEffect,useContext } from "react";
import HeaderTextBanner from '../layout/HeaderTextBanner';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from 'react-bootstrap';
import './../../css/email-settings.css'
import {UserRole} from '../auth/Athentication'
import GlobalContext from "../../GlobalContext";
export default function EmailSettings() {
  
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
  const [inputs,setInputs] = useState({});
  const [settings,setSettings] = useState({});
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const { register,handleSubmit,clearErrors,setError,errors } = useForm();
        const [formErrors,setFormErrors] = useState(null);
        const formdata = new FormData();
          //for form inputs 
     const handleInputChange = async (event) => {
      event.persist();
      const input = event.target;
      if(input.checked == true){
          setInputs(inputs => ({...inputs, [input.name]: input.value}));
        }else{
            setInputs(inputs => ({...inputs, [input.name]:'no'}));
        } 
    };
    useEffect(() => {
        axios.get('user/email-settings').then(res=>{      
            const response = res.data.data;
            setSettings(response);
            if(response && Object.keys(response).length){
                response.map((record,index)=>{
                    setInputs(inputs => ({...inputs, [record.email_trigger]:record.status}));
                })
            }
        });
    },[]);
  //submission
const formSubmit = (data)=>{

  setIsSubmitting(true);
  for ( var key in inputs ) {
    formdata.append(key, inputs[key]);
  }
  var post_url ='user/update-email-settings';
  axios.post(post_url, formdata)
      .then(response => {
          let res = response.data;
          if(res.status=='success'){
              window.scrollTo(0, 0)
              setIsSubmitting(false);
              setIsSubmitted(true);
          }else{
              let msg_str ='';
              // console.log(res.message.max_guests[0]);
              Object.keys(res.message).map((ele)=>{
                  res.message[ele].map((msg,key) => {
                      console.log(msg)
                      msg_str += msg+'\n';
                      setIsSubmitting(false);
                      setFormErrors(msg_str);
                      window.scrollTo(0, 0)
                  });
  
              });
              
          }     
      })
      .catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  
  }; 
        return (
        <>
        <HeaderTextBanner heading="Email Settings" />
        <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
            {isSubmitted ? <div className="alert alert-success">Email settings updated successfully.</div> : '' }
                <form method="post" onSubmit={handleSubmit(formSubmit)}>
                <div className="payout_div">
                    <h2>Email settings</h2>
                    <p>Choose what emails you will receive. Be careful when stopping them, because some of these emails are important notifications about your classes, actions, and messages on Actyvate Learning.</p>
                    <div className="row">
                        {Object.keys(settings).length && settings.map((setting,index)=>{
                            return (
                            <div className="col-md-6" key={index}>
                            <div className="tb-row email_row">
                            <div className="tb-cell email_setting">
                                <p><strong>{setting.title}</strong></p>
                                <p>{setting.description}</p>
                            </div>
                            <div className="tb-cell text-left pd-left email_switch">
                                <div className="onoffswitch">
                                <input type="checkbox" disabled={setting.is_optional=='no'} defaultChecked={setting.status=='yes'} onChange={handleInputChange} value="yes" name={setting.email_trigger} className="onoffswitch-checkbox" id={"myonoffswitch"+setting.id} tabIndex={0} />
                                <label className="onoffswitch-label" htmlFor={"myonoffswitch"+setting.id}>
                                    <span className={"onoffswitch-inner"+(setting.is_optional=='no'?' diabled':'')} />
                                    <span className="onoffswitch-switch" />
                                </label>
                                </div>
                            </div>
                            </div>
                        </div>
                            )
                        }) }
                    </div>
                    <div className="control-group">
                        <div className="controls">
                        <button id="confirmsignup"  type="submit" name="confirmsignup" className="btn btn-success register-btn">Update Email settings</button>
                        </div>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
        </>
      );
    } 