import React, { useState,useEffect,useContext } from "react";
import { Redirect,useParams,useHistory } from 'react-router-dom';
import HeaderTextBanner from '../layout/HeaderTextBanner';
import axios from 'axios';
import {UserRole} from '../auth/Athentication'
import GlobalContext from "../../GlobalContext";
import Success from "../layout/alert/Success";
export default function EmailConfirmation() {
    const { token } = useParams();
    let history = useHistory();
    const redirectURL = UserRole() == 'teacher' ? '/teacher-dashboard':'/user-settings'; 
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [successMessage,setSuccessMessage] = useState(null);
  const [alertShow,setAlertShow] = useState(false);
  const [isSubmitted,setIsSubmitted] = useState(false);

    useEffect(() => {
       if(token){
           formSubmit();
       }
    },[]);
    
    useEffect(() => {
       if(!alertShow){
          return; 
       }
       setTimeout(function(){
        history.push(redirectURL);
       },3000);
    },[alertShow]);
  //submission
function formSubmit(){

  setIsSubmitting(true);  
    axios.post('user/update-email-confirmation', {token:token})
      .then(response => {
          let res = response.data;
          setIsSubmitting(false);
          setAlertShow(true)
          setIsSubmitted(true);
          if(res.status=='success'){
              localStorage.setItem('email_verified_at',true);
              setSuccessMessage(res.message);
          }     
      })
      .catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  
  }; 
        return (
        <>
        <HeaderTextBanner heading="Email Confirmation" />
        <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
            {isSubmitted && successMessage ? <Success setAlertShow={setAlertShow} message={[successMessage]} show={alertShow} /> :
            isSubmitted ? <div className="alert alert-warning p-3"><strong>Page was expired!</strong></div>:<p>Please wait we are confiming your email...</p>}
            </div>
          </div>
        </div>
      </section>
        </>
      );
    } 