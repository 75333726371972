import React,{useState,useEffect} from "react";
import axios from 'axios';
import ClassListItem from '../../../classes/blocks/ClassSingleItem';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {GetStringForUrl} from '../../../helpers/StringHelper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ClassListings(props)
{
   const classItems = props.classes;

    
   var settings = {
    dots: false,
        rows: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        infinite: Object(classItems).length > 4,
        arrows:true,  
        speed: 200,
        slidesToScroll: 1
  };

    return (
        <div className="row avl_time_sec" key={0} id="class-list-main">
            <div className="p-3 ss-slick price-sec">
            <Slider {...settings}>
                {classItems.map((item,index) =>{
                    return (
                        <div key={index} className="ss-item">
                        <div className="s-class-box text-center">
                            <img src={item.class_photo} className="img-thumbnail w-100" alt={item.title}/>
                            <Link to={'/class-details/'+GetStringForUrl(item.title)+'/'+item.class_uid}><h3>{item.title}</h3></Link>
                        <p>{item.start_time+' to '+item.end_time}</p>
                        <p>{item.timezone_name} <i className="icofont-ui-clock" /></p>
                        <Link to={'/class-details/'+GetStringForUrl(item.title)+'/'+item.class_uid} className="show-link">View Details </Link>
                        </div>
                    </div>
                    )
                } )}
            
            </Slider>
        </div>
        </div>
    )
}