import React, { useState,useEffect } from "react";
import HeaderTextBanner from '../../../layout/HeaderTextBanner';
import axios from 'axios';
import { Link,useParams } from 'react-router-dom';
import '../../../../css/edit-section.css'
import LoadingIcon from "../../../helpers/LoadingIcon";

function SectionZoomClassRoom(props) {  
    const {zoom_meeting_id,meeting_id} = useParams();
    const [isFeched,setIsFetched]=useState(false);
    const [zoomStartUrl,setZoomStartUrl]=useState();
   
  useEffect(() => {
    handleRedirectZoom();
  }, []);

    function handleRedirectZoom(){
        setIsFetched(false);  
        
        axios.post('teacher/classroom-zoom-start',{zoom_meeting_id:zoom_meeting_id,meeting_id:meeting_id}).then(res=>{
        setIsFetched(true);  
        if(res.data.status && res.data.status == 'success'){
            const zoomUrl = res.data.data.zoom_url;
            setZoomStartUrl(res.data.data.zoom_url);
            setIsFetched(true)
            window.location.href=zoomUrl

          }else{
              alert('Internal server Error.');
          }
      })  
    }
  return (
    <>
      <HeaderTextBanner heading={"Classroom"} />
      <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
                {!isFeched ? <LoadingIcon /> : 
                    (zoomStartUrl ? <p>Redirecting you to Zoom. If your browser doesn't autometically redirect you,<a href={zoomStartUrl.toString()}>Click here</a></p> : 'Please wait...')}
                </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionZoomClassRoom;
