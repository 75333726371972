import React,{useState,useEffect} from 'react';

export default function NotFoundPage()
{
    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className="row">
                <div className="w-100 text-center">
                    <img src={require('../../images/404.png')} alt="404"/>
                </div>
            </div>
        </>
    )
} 