import React, { useState,useEffect,useContext } from "react";
import FavouriteListSec from "./blocks/FavouriteListSec";
import RequestAClass from "../../home/blocks/RequestAClass";
import HeaderTextBanner from '../../layout/HeaderTextBanner'
import { useLocation } from "react-router-dom";

import '../../../css/search.css'
import GlobalContext from "../../../GlobalContext";

function FavouriteClass() {
  
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

  return (
    <>
      <HeaderTextBanner heading="My favorites" />
      <FavouriteListSec />
      <RequestAClass />
    </>
  );
}
export default FavouriteClass;
