import React, { useState,useEffect,useContext } from "react";
import PurchasesSec from './blocks/PurchasesSec'
import { useLocation } from "react-router-dom";
import HeaderTextBanner from '../../../layout/HeaderTextBanner'

import '../../../../css/about.css'
import GlobalContext from "../../../../GlobalContext";

function PurchasesPage(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

  return (
    <>
      <HeaderTextBanner heading="Purchases" />
      <PurchasesSec />
    </>
  );
}
export default PurchasesPage;
