import React,{useState,useEffect} from "react";
import axios from 'axios';
import BlogItem from './items/BlogItem';
function BlogGrid() {
const [blogItems,setBlogItems] = useState({});
const [limit,setLimit] = useState(3);

  useEffect(() => {
    getBlogs();
  }, []);

  async function getBlogs() {
    // console.log(process.env.PUBLIC_URL);
    const settings = await axios.get("home/site-settings");
      if(settings.data.data && settings.data.data.display_blog == '1'){
        const response = await axios.get(process.env.REACT_APP_BLOGS_API_URL);
          if(response.status != 200){
            alert('Invalid Request');
            return;
          }else{
            setBlogItems(response.data);
          }
      }
  }

    return (
      blogItems.length ?
      <section className="trending_section blog-sec">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              <h2>Blog</h2>
            </div>
          </div>
          <div className="row">
          { blogItems.slice(0,limit).map((item, index) => { 
            return (
              Object.keys(item).length ?
              <BlogItem item={item} key={index}/> :<></>
            )
          })
        }
          </div>
          { blogItems.length > limit ?
          <div className="row">
            <div className="col-md-12 text-center">
              <a onClick={()=>{setLimit(limit+3)}} className="get_button">Load More</a>
            </div>
          </div>
          :''}
        </div>
      </section>
      :<></>
    )
  }

  export default BlogGrid