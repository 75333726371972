import React, { useState, useEffect } from 'react';
import DashboardSectionListItem from './block/DashboardSectionListItem';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { useForm } from "react-hook-form";
import LoadingIcon from '../../../helpers/LoadingIcon';


export default function TeacherSectionsTab(props) {
  const [records, setRecords] = useState([]);
  const [classes, setClasses] = useState({});
  const [show, setShow] = useState(false);
  const [isFetched,setIsFetched]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(false);
  const { register,handleSubmit,errors } = useForm();
  const [sectionsType, setSectionsType] = useState('acitve');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  useEffect(() => {
    axios.get('teacher/get-class-titles').then(res=>{
      if(res.data && res.data.data && res.data.status=='success'){
        setClasses(res.data.data.classes);
      }
    })
  },[]);


  useEffect(()=>{
    getTeacherSections(sectionsType);  
  },[sectionsType]);
  
  
  async function getTeacherSections(type)
  {
    await axios.get('teacher/get-teacher-sections/'+type).then(res=>{    
      if(res.data && res.data.data && res.data.status=='success'){
        setRecords(res.data.data);
        setIsFetched(true)
      }else{
        alert('Could not be connected to server');
        return;
      }  
    });
  }
  const handleInputChange = (event) =>{
    const input = event.target;
    setSectionsType(input.value);
  }
  const formSubmit = (data)=>{
    localStorage.setItem('sectionAcitveTab','EditSection');
    setSelectedClassId(data.class_id);
  };
  return (
    selectedClassId ? <Redirect to={{pathname:"/section/"+selectedClassId}}/> :
      <div role="tabpanel" id="three">
        <div className="row class-board-sec">
          <div className="col-md-12">
            <div>
              <a
                href="#"
                onClick={handleShow}
                data-toggle="modal"
                data-target="#scheduleSection"
                className="get_button"
              >
                Schedule a section
              </a>
            </div>
            <form className="php-email-form section_form">
              <div className="row">
                <div className="col-md-4">
                  <label>Sections by start time</label>
                  <div className="selectdiv">
                    <select value={sectionsType} onChange={handleInputChange} name="sectionType" id="mname" className="form-control">
                      <option value="active">Active</option>
                      <option value="past">Past</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row class-board-sec">
          <div className="col-md-12">
            <div className="row">
            {isFetched && records ?
              records.map((record,index) => {
              return (
              <div className="col-md-4" key={index}>
                <DashboardSectionListItem section={record} />
              </div>
           )
           }) : ''}
            </div>
          </div>
        </div>
        {isFetched && records.length <=0 && <p>No sections found.</p>}
        {!isFetched ? <LoadingIcon /> : ''}
    <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
            <h3>Schedule a Section</h3>
              <form  role="form" className="php-email-form mb-20" onSubmit={handleSubmit(formSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="selectdiv">
                      <select name="class_id" ref={register} id="mname" className="form-control">
                        <option value="">Select</option>
                        {Object.keys(classes).length && classes.map((item,index)=>{
                          return (
                          <option key={index} value={item.class_uid}>{item.title}</option>  
                          )
                        })}
                      </select>
                    </div>
                    {errors.class_id && <p className="text-danger">{errors.class_id.meessage}</p>}

                  </div>           
                </div>
                <div className="row">
                        <div className="col-md-12 pt-3 text-right">
                          <button type="submit" className="get_button mt-10">Add Section</button>
                        </div>
                </div>
              </form> 
            </Modal.Body>
      </Modal>
      </div>
    );
}
