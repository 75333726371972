import React, { useState,useEffect,useContext } from "react";
import { useParams,useHistory } from 'react-router-dom';
import HeaderTextBanner from '../layout/HeaderTextBanner';
import { useForm } from "react-hook-form";
import PasswordStrengthBar from 'react-password-strength-bar';
import SubmittingLoadingImg from '../helpers/SubmittingLoadingImg'
import {validatePasswordToken} from '../api/ForgotPasswordApi'
import axios from 'axios';
import {UserRole} from '../auth/Athentication'
import GlobalContext from "../../GlobalContext";
import Success from "../layout/alert/Success";
export default function SetNewPassword() {
    const { token } = useParams();
    let history = useHistory();
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [isPageValid,setIsPageValid] = useState(true);
  const [formErrors, setFormErrors] = useState(null);      
  const [successMessage,setSuccessMessage] = useState(null);
  const [alertShow,setAlertShow] = useState(false);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const { register,handleSubmit,getValues,watch,setError,errors } = useForm();
    useEffect(() => {
        checkForgotPasswordToken();
    }, []);


    useEffect(() => {
       if(!alertShow){
          return; 
       }
       setTimeout(function(){
        history.push('/');
       },3000);
    },[alertShow]);

    async function checkForgotPasswordToken()
    {
        const res = await validatePasswordToken(token);
        if(res == 'success'){
            setIsPageValid(true);
        }else{
            setIsPageValid(false);
        }
    }
   

  //submission
function formSubmit(data){
    data.token = token;
  setIsSubmitting(true);  
    axios.post('user/set-new-password', data)
      .then(response => {
          let res = response.data;
          setIsSubmitting(false);
          if(res.status=='success'){
            setIsSubmitted(true);
              setAlertShow(true);
              setSuccessMessage(res.message);
          }else{
              if(typeof (res.message) == 'string'){
                setFormErrors(res.message);
              }else{
                Object.keys(res.message).map((ele)=>{
                    res.message[ele].map((msg,key) => {
                    setError(ele,{type:'manual',message:msg});
                    });
                });
            }     
          }     
      })
      .catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  
  }; 
        return (
        <>
        <HeaderTextBanner heading="Set new password" />
      <section className="user-dashboard">
          <div className="container-fluid cf">
          {isSubmitted && successMessage ? <Success setAlertShow={setAlertShow} message={[successMessage]} show={alertShow} /> :
            !isPageValid ? <div className="alert alert-warning p-3"><strong>Page was expired!</strong></div>:
            <div className="row">
              <div className="col-md-6 offset-3">
              {formErrors ? <div className="alert alert-danger">{formErrors}</div>:''}
                <form className="form-horizontal teacher_form change-pwd-from" onSubmit={handleSubmit(formSubmit)}>
                  {/* Text input*/}
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="controls">
                          <input id="password" name="password" ref={register({required:'This field is required.'})} className="form-control input-large" type="password" placeholder="New password" />
                              {errors.password && <p className="text-danger">{errors.password.message}</p>}
                        </div>
                        <PasswordStrengthBar password={watch("password")} barColors={['#ddd', '#ef4836', '#2b90ef', '#2b90ef', '#25c281']} minLength={6} scoreWords={['weak','weak','good','good', 'strong']} />
                      </div>
                      <div className="col-md-12">
                        <div className="controls">
                          <input id="confirm_password"  ref={ register({
                          required:'This field is required.', 
                          validate: {
                            matchesPreviousPassword: (value) => {
                              const { password } = getValues();
                              return password === value || 'Passwords should match!';
                              },
                          }}) } name="password_confirmation" className="form-control input-large" type="password" placeholder="Enter your new password again to confirm" />
                              {errors.password_confirmation && <p className="text-danger">{errors.password_confirmation.message}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isSubmitting ? <SubmittingLoadingImg /> : ''}
                  <div className="control-group">
                    <div className="controls">
                      <button id="confirmsignup"  type="submit" name="confirmsignup" className="btn btn-success register-btn">Update password</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            }
          </div>
        </section>
        </>
      );
    } 