import React,{ useState } from 'react'
import LoadingIcon from '../../../helpers/LoadingIcon';
import SearchResultClassItem from '../blocks/SearchResultClassItem';
import {IsAuthenticated} from '../../../auth/Athentication'

export default function SearchListSec(props) {
  const options = props.options;
  const classes = props.classes;
  const [isLoading,setIsLoading]=useState(options.isLoading);
  const handleInputChange = (event) => {
    const value = event.target.checked?'exclusive':'all';
    options.setInputExclusiveChannels(value);
  };
    return (
      <>
      <section className="search-topic">
        <div className="container-fluid cf">
          <div className="row results_records">
            <div className="col-md-12 text-right">
              <p>
          {IsAuthenticated() ?
                <span className="ex_cls"><input type="checkbox" className="search_input1" checked={options.inputExclusiveChannels == 'exclusive'?true:false} onChange={handleInputChange} value="exclusive" name="channel_type" id="channel_type"  />&nbsp; Exclusive {process.env.REACT_APP_CLASS_HELPER}s</span>
            :''}
                {options.totalRecords} results</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
             {classes && classes.length ?
              classes.map((item,index)=>{
                 return(
                  <SearchResultClassItem key={index} classItem ={item} />
               )
              }) :'' }

              {!classes.length && !isLoading && options.isFetched && <h3 className="text-center">No results found.</h3>}
            </div>
          </div>
          <div className="row">
            {options.Skip+options.Limit < options.totalRecords && options.isFetched ?
            <div className="col-md-12 text-center">
              <a href="#" onClick={props.handleLoadMore} className="get_button">Load More</a>
            </div>
            :''}
          </div>
        </div>
      </section>
      </>
    );
}
