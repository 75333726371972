import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import LoadingIcon from '../../../helpers/LoadingIcon';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal'
import "react-datepicker/dist/react-datepicker.css";
import {dateFormatString,ucFirst} from '../../../helpers/StringHelper';
import Sucess from '../../../layout/alert/Success'
import Axios from 'axios';

export default function TeacherEarningTab()
{
    const [isFetching,setIsFetching] = useState(false)
    const [SearchData, setSearchData] = useState();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [requestFormData, setRequestFormData] = useState({requested_amount:''});
    const [earningRecords, setEarningRecords] = useState([]);
    const [earningsData, setEarningsData] = useState();
    const [totalEarnings, setTotalEarnings] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState();
    const [Skip, setSkip] = useState(0);
    const [endDate, setEndDate] = useState();
    useEffect(() => {
        setIsFetching(true);
        getTeacherEnrolls(Skip)
    }, [Skip])
    
    
    const handleInput = (event)=>{
        event.preventDefault();
        const input = event.target; 
        setSearchData(SearchData => ({...SearchData, [input.name]: input.value}));
    }

    const handleReqFormInput = (event) =>{
        event.preventDefault();
        const input = event.target; 
        if(input.name == 'requested_amount'){
            if(input.value > earningsData.available_earnings){
                input.value = earningsData.available_earnings;
            }
            // else if(input.value < earningsData.minimum_payout_value){
            //     input.value = earningsData.minimum_payout_value;
            // }
        }
        setRequestFormData(requestFormData => ({...requestFormData, [input.name]: input.value}));
    }

    const handleReqFormSubmit = (event) =>{
        setErrorMessage('');
        event.preventDefault();
        if(!requestFormData.requested_amount){
            setErrorMessage('Please enter Requested amount.');
            return false;
        }else if(requestFormData.requested_amount < earningsData.minimum_payout_value || requestFormData.requested_amount > requestFormData.available_earnings ){
            setErrorMessage('Please check entered amount.');
            return false;
        }else{
            Axios.post('teacher/send-payout-request',{amount:requestFormData.requested_amount}).then(res=>{
                if(res.data.status== 'success'){
                    setSearchData({});
                    setIsFormSubmitted(true);
                    setShow(false);
                }
            }).catch(err=>{
                alert('Internal server error.')
                console.log(err);
            });
        }

    }
    const handleSearchFormSubmit = (event) =>{
         event.preventDefault();
         getTeacherEnrolls(0);
    } 
    async function getTeacherEnrolls(skip)
    {
        const getData = {skip:skip};
        getData.startDate = startDate;
        getData.endDate = endDate;
        getData.sortList = SearchData?SearchData.sortDate:'';
        const res =  await Axios.get('teacher/get-earnings',{params:getData});
        setTotalEarnings(res.data.data.total_earnings);
        setEarningRecords(res.data.data.records);
        if(skip == 0){
        
        }
        setIsFetching(false)
    }
    const handleClearSearch = (e) =>{
       window.location.reload()
    }

    return (
        <>
        <div role="tabpanel" id="Payouts">
            <section className="teacher-payouts-main user-dashboard">
            <div className="container-fluid cf">
                {isFetching ? <LoadingIcon /> : 
                <div className="row">
                    <div className="col-md-12">
                        <div className="payout_div">
                            {/* <form action="#" method="post" role="form" onSubmit={handleSearchFormSubmit} className="php-email-form mb-20">
                                <div className="row search_section">
                                <div className="col-md-3">
                                    <select name="sortDate" id="sortDate" onChange={handleInput} className="form-control">
                                        <option value="">All</option>
                                        <option value="-1 month">Last 30 days</option>
                                        <option value="-3 month">Last 3 months</option>
                                        <option value="-6 month">Last 6 months</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            selectsStart
                                            placeholderText="Start date"
                                            className="form-control"
                                            maxDate={new Date()}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                </div>
                                <div className="col-md-3">
                                        <DatePicker
                                            selected={endDate}
                                            className="form-control"
                                            placeholderText="End date"
                                            onChange={date => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            maxDate={new Date()}
                                            minDate={startDate}
                                        />
                                    </div>
                                    <div className="col-md-3 align-self-center">
                                        <button className="btn btn-primary search_btn"><img src="/assets/img/search.svg" /> Search</button>
                                        <button onClick={handleClearSearch} className="btn btn-default ml-1">Clear</button>
                                    </div>
                                </div>
                                
                            </form> */}
                        {totalEarnings ? <h4><strong>Total Earnings: </strong> {totalEarnings}</h4> : ''}
                        <table className="payout_table">
                            <thead>
                                <tr>
                                <th>{process.env.REACT_APP_CLASS_HELPER} Details</th>
                                <th>{process.env.REACT_APP_CLASS_HELPER} meetings</th>
                                <th>Earning Amount</th>
                                <th>View</th>
                                <th>Enrolled Date</th>
                                </tr>
                            </thead>
                            <tbody> 
                                { earningRecords && earningRecords.length ? earningRecords.map((record,index)=>{
                                    return (
                                        <tr key={index}>
                                        <td>
                                            <div>
                                                <div className="float-left pr-2">
                                                    <img src={record.class_photo} width={90} />
                                                </div>
                                                <div className="text-left">
                                                <strong className="text-primary">{record.class_name}</strong> <br/>
                                                <span><strong>section : </strong>{record.section_name}</span><br/>
                                                <p><strong>Timezone : </strong>{record.timezone_name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {record.meetings.length && record.meetings.map((meeting) =>
                                                <><span className="badge badge-warning">{meeting.meeting_time}</span><br/></>
                                            )}
                                        </td>
                                        <td>{record.teacher_commission}</td>
                                        <td><Link onClick={()=>{localStorage.setItem('sectionAcitveTab','ClassRoom')}} className="btn btn-primary" to={"/section/"+record.class_uid+'/'+record.section_uid}>View</Link></td>
                                        <td>{record.payment_date}</td>
                                       
                                    </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan={4}>No records found.</td>
                                </tr>
                                } 
                               </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </div>
        </section>
        </div>
    </>
    )
}