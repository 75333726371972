import React, { useState,useEffect,useContext } from "react";
import TeacherInfo from './../../classes/blocks/TeacherInfo';
import NotFoundPage from '../../layout/NotFoundPage'
import axios from 'axios';
import { useLocation,useParams } from "react-router-dom";

import '../../../css/search.css'
import GlobalContext from "../../../GlobalContext";

function ModerateTeacher(props) {
  const {teacherId} = useParams();
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [teacherInfo, setTeacherInfo] = useState({});
  const [isInvalid,setIsInvalid] = useState(false);

  let location = useLocation();
  
  useEffect(() => {
    getTeacherDetails();
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  },[]);
  async function getTeacherDetails()
  {
    console.log('calling..');
    const res = await axios.get("teacher/teacher-info",{params:{teacher_uid:teacherId}}).catch(err=>{
      alert('Internal server Errro.')
    });
      if(typeof(res.data.status) !='undefined' && res.data.status == 'success'){
          var response = res.data.data;
          setTeacherInfo(response);
        }else{
          setIsInvalid(true);
        }
      }

  return (
    isInvalid ? <NotFoundPage /> :
    <>
      <section className="class-details-sec">
        <div className="container-fluid cf">
          <div className="row fixed-div">
            <div className="col-md-12 teacher-sec">
              <div className="row">
                <div className="col-md-12">
                 {Object.keys(teacherInfo).length ? <TeacherInfo teacherInfo={teacherInfo} /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  );
}
export default ModerateTeacher;
