import React,{useState,useEffect} from "react";
import axios from 'axios';
import LoadingIcon from '../../../helpers/LoadingIcon'
import SearchResultClassItem from '../../search/blocks/SearchResultClassItem'

export default function FavouriteListSec() {
  const [classItems,setClassItems] = useState([]);
  const [isFetching,setIsFetching] = useState(true);
  const [totalRecords,setTotalRecords] = useState(0);
  const [page,setPage] =useState(1);

  useEffect(() => {
    getFavoriteClasses();  
  }, [page])


  async function getFavoriteClasses() {
    const response = await axios.get('user/get-user-favorite-classes',{params:{page:page}});
      if(!response.data.data){
        alert('Invalid Request');
        return;
      }
      if(page == 1){
        setClassItems(response.data.data);
        setTotalRecords(response.data.total_records)
      }else{
        setClassItems([...classItems,response.data.data]);
      }
      setIsFetching(false)
  }
  const handleLoadMore = (event)=>{
    event.preventDefault();
    setPage(page+1);
  }

    return (
      <>
      <section className="search-topic">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {
                !isFetching && Object.keys(classItems).length ? classItems.map((item,index)=>{
                  console.log(item)
                  return (
                    <SearchResultClassItem key={index} classItem ={item} />
                    )
                  }) : <p className="text-center">No upcoming {process.env.REACT_APP_CLASS_HELPER}s found from your favorites.</p>
                }
                {isFetching ? <LoadingIcon /> : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              {totalRecords > 0 && Object.keys(classItems).length < totalRecords ? <a href="#" onClick={handleLoadMore} className="get_button">Load More</a> :''}
            </div>
          </div>
        </div>
      </section>
      </>
    );
}
