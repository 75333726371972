import React,{useState,useEffect} from "react";
import axios from 'axios';
import ClassListItem from './ClassListItem';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ChannelClasses(props)
{
    const classId = props.class_id;
    const channelId = props.channel_id;
    const [classItems,setClassItems] = useState({});

    var settings1 = {
        dots: false,
            rows: 1,
            autoplay: true,
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 4,
            infinite: Object.keys(classItems).length > 4,
            arrows:true,  
            speed: 201,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 320,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
              },
              {
                breakpoint: 580,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
              },
              {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false },
              },
              {
                breakpoint: 1024,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false },
              },
            ],
      };

    useEffect(() => {
        getTeacherclasses();
    }, []);

    async function getTeacherclasses() {
        const response = await axios.get("class/get-classes",{params:{channel_id:channelId}});
        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        setClassItems(response.data.data.classes);
    }

    return (
        Object.keys(classItems).length > 1 ?
        <section className="trending_section class-details-sec gray_bg">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-12">
                <h2 className="ex-padding">More like this</h2>
              </div>
            </div>
            <div className="ss-slick price-sec">
              <Slider {...settings1}>
            { classItems.map((item, index) => { 
            return (
                item.id != classId ? <ClassListItem key={index} class={item} />:
                ''
                )
            })}
            </Slider>
            </div>
          </div>
        </section>
        : <></>
    )
}