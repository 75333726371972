import React,{useState} from "react";
import ReviewList from './ReviewList';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import '../../../css/modal-video.css';
import { lcFirst } from "../../helpers/StringHelper";


export default function TeacherInfo(props)
{
    const teacherInfo = props.teacherInfo;
    const classInfo = props.classInfo;
    const completed_meetings = classInfo ? classInfo.completed_meetings : teacherInfo.completed_meetings; 
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    return(
        <div className="col-md-12 teacher-sec" id="teacher">
                <div className="row">
                  <div className="col-md-12">
                    <h3>Meet the {process.env.REACT_APP_TEACHER_HELPER}</h3>
                    <div className="teacher-block">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tb-row">
                            <div className="tb-cell">
                              <img className="img-responsive teacher_profile" src={teacherInfo.teacher_photo} />
                            </div>
                            <div className="tb-cell text-left pd-left">
                          <Link to={"/teachers/"+teacherInfo.user.unique_id}><h3>{teacherInfo.teacher_name}</h3></Link>
                          {teacherInfo.current_school && <p className="heighlight">Professor at {teacherInfo.current_school}</p> }
                              <p>{teacherInfo.headline}</p>
                              <ul className="mrg_top">
                                <li><i className="bx bxs-star" /></li>
                                <li><i className="bx bxs-star" /></li>
                                <li><i className="bx bxs-star" /></li>
                                <li><i className="bx bxs-star" /></li>
                                <li><i className="bx bxs-star" /></li>
                                <li><span>{teacherInfo.reviews_count} total reviews</span></li>
                              </ul>
                              <ul className="info-list">
                                <li>{completed_meetings?completed_meetings:'0'} completed {lcFirst(process.env.REACT_APP_CLASS_HELPER)}s</li>
                                  <li>Joined {teacherInfo.joined_on}</li>
                                  {teacherInfo.teacher_video &&  
                                  <li className="watch_video_btn">
                                    <a onClick={()=>{setIsVideoOpen(true)}}  className="watch_video"><img className="img-responsive" src="/assets/img/play-button.svg" /> Watch My Intro Video</a>
                                  </li>}
                              </ul>
                              <p />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="com-md-12">
                          <div className="abt_p_new">
                            <p><strong>About me</strong></p>
                                  <p>{parse(teacherInfo.about_me)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ReviewList key={1} teacherId={teacherInfo.user_id}/>
                  </div>
                </div>
                    <ModalVideo channel='custom' isOpen={isVideoOpen} url={teacherInfo.teacher_video} onClose={() =>setIsVideoOpen(false)} />
              </div>
    )
}