import React from 'react';
import { Link } from "react-router-dom";
import {getCleanStatus} from '../../../helpers/StringHelper';

export default function ClassSummaryUpcommingSection(props){
    const section = props.section;
    const type = props.type;
    const classInfo = props.classInfo;
    return (
        <>
        <div className="up-sec">
            <div className="row">
                <div className="col-md-4">
                        <Link onClick={() => { localStorage.setItem('sectionAcitveTab', 'EditSection') }} to={"/section/" + classInfo.class_uuid + '/' + section.unique_id}> {section.section_name} </Link> 
                         <span className="not-list">{section.channel_type}</span>
                </div>
                <div className="col-md-2">
                        <span className="not-list cls-progress">{section.enroll_count} Enroll(s)</span>
                </div>
                <div className="col-md-2">
                    {type == 'upcoming' ? <span>{section.available_space} spaces available</span> :''}
                </div>
                <div className="col-md-2">
                        <Link className="not-list cls-progress1" onClick={() => { localStorage.setItem('sectionAcitveTab', 'EditSection') }} to={"/section/" + classInfo.class_uuid + '/' + section.unique_id}> View </Link> 
                </div>
            </div>
        </div>
        </>
        );
    }