import React,{useEffect,useState,useContext} from "react";
import GlobalContext from "../../../GlobalContext";
import parse from 'html-react-parser';
import Axios from "axios";
import HeaderTextBanner from "../../layout/HeaderTextBanner";
import LoadingIcon from '../../helpers/LoadingIcon'
import SubmittingLoadingImg from '../../helpers/SubmittingLoadingImg'

import '../../../css/about.css'

function HelpPage(props) {
  const [pageLoaded,setPageLoaded] = useState(false);
  const [isFetching,setIsFetching] = useState(false);
  const [keyword,setKeyword] = useState(null);
  const [searchedKeyword,setSearchedKeyword] = useState(null);
  const [cmsPage,setCmsPage] = useState([]); 
  const [cmsBlocks,setCmsBlocks] = useState([]);

  const {setIsInnerPage} = useContext(GlobalContext) 
  setIsInnerPage(true)
  
useEffect(() => {
  getCmsPage();
}, []);

useEffect(() => {
  if(isFetching){
    getCmsPage();
  }
}, [isFetching]);

const handleInutChange = (e) =>{
  const input = e.target;
  if(input.name == 'keyword'){
    setKeyword(input.value);
  }

}
async function getCmsPage()
  {
    console.log(keyword)
    const res = await Axios.get('cms/get-cms-page',{params:{cms_slug:'help',keyword:keyword}});
    if(res.data.status && res.data.status == 'success'){
      setCmsPage(res.data.data?res.data.data:[]); 
      setSearchedKeyword(keyword);
      setCmsBlocks(res.data.data?res.data.data.cms_blocks:[]);
      setPageLoaded(true);
    }
    setIsFetching(false)
  }

  const handleSearch = (e) =>{
    e.preventDefault();
    setIsFetching(true)
    
  }
  return (
    <>
      <HeaderTextBanner heading={cmsPage.cms_title?cmsPage.cms_title : ''} />
        <section className="user-dashboard">
        {pageLoaded ? 
      <div className="container-fluid cf">
        <div className="row">
          <div className="col-md-12">
            <form className="form-horizontal teacher_form change-pwd-from">
              {/* Text input*/}
              <div className="control-group">
                <div className="row">
                  <div className="col-md-9">
                    <div className="controls">
                      <input id="search" name="keyword" value={keyword} onChange={handleInutChange} className="form-control input-large search_input" type="text" placeholder="Search our articles" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    {/* Button */}
                    <div className="control-group">
                      <div className="controls">
                        <button id="confirmsignup" onClick={handleSearch} name="confirmsignup" className="btn btn-success register-btn">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-12">
            {isFetching ? <SubmittingLoadingImg /> : ''}
            {!isFetching && searchedKeyword ? <div class="color_one">Showing <strong>{cmsBlocks.length}</strong> results for keyword "<strong>{searchedKeyword}</strong>"</div>:''}
              {cmsBlocks.length ? cmsBlocks.map((block,index)=>{
                let blockClass = 'color_one';
                if(index % 2 == 0){
                  blockClass = 'color_two';
                }
                return (
                <div className={'text-block '+blockClass}>
                  <h2>{block.heading}</h2>
                  {parse(block.content)}
                </div>
                )
              }) : <div className="text-block color_one"><p className="text-center">No Records found.</p></div>}
            </div>
        </div>
      </div>
      : <LoadingIcon /> }
    </section>
    </>
  );
}
export default HelpPage;
