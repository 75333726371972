import React,{useContext,useEffect,useState} from "react";
import {Link} from 'react-router-dom'
import GlobalContext from "../../../GlobalContext";
import axios from 'axios'
import { useForm } from "react-hook-form";
import Success from '../../layout/alert/Success'
import {lcFirst} from '../../helpers/StringHelper'
import Modal from 'react-bootstrap/Modal'


function RequestAClass() {
  const {setLoginModelOpen,loggedIn,setIsModelBackdropOpen} = useContext(GlobalContext)
  const { register,handleSubmit,setError,errors } = useForm();
  const [successMessage, setSuccessMessage] = useState(null);
  const [alertShow,setAlertShow] = useState(false)
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [requestClassModalShow, setRequestClassModalShow] = useState(false)
  
  const handleRequestClass = (event)=>{
    event.preventDefault();
    if(loggedIn){
      setRequestClassModalShow(true);    
    }else{
        setLoginModelOpen(true)
        setIsModelBackdropOpen(true)
    }
  }

  useEffect(() => {
    if(alertShow == false && requestClassModalShow == true && successMessage){
      setRequestClassModalShow(false) 
    }
  }, [alertShow])
  const formSubmit = (data)=>{
    setIsSubmitting(true);
    setFormErrors(null);
    setSuccessMessage(null);
  
  axios.post("class/post-request-class", data)
      .then(response => {
          let res = response.data;
          if(res.status=='success'){
              setSuccessMessage(res.message);
              setAlertShow(true)
              setIsSubmitting(false);
          }else{
              let msg_str ='';
              // console.log(res.message.max_guests[0]);
              Object.keys(res.message).map((ele)=>{
                  res.message[ele].map((msg,key) => {
                    setError(ele,{type:'manual',message:msg,shouldFocus:true});
                      msg_str += msg+'\n';
                      setIsSubmitting(false);
                      setFormErrors(msg_str);
                  });
  
              });
              
          }     
      })
      .catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  }
  return ( 
    <>    
      <section className="request-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3>Interested in a specific topic?</h3>
              <p>
                <Link to="#" onClick={handleRequestClass} className="get_button">
                  Request an {process.env.REACT_APP_CLASS_HELPER}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Modal dialogClassName="login_model" show={requestClassModalShow} onHide={()=>{setRequestClassModalShow(false)}}>
          <Modal.Header closeButton>
              <div className="img-box  align-items-center">
                  <img src='/assets/img/icon-logo.svg'/>
              </div>
          </Modal.Header>
          <Modal.Body>
          <div className="singup-form-div form-box">
        <h3>Request a {process.env.REACT_APP_CLASS_HELPER}</h3>
        {alertShow ? 
          <Success setAlertShow={setAlertShow} message={[successMessage]} show={alertShow} /> :(formErrors?<div className="alert alert-danger">{formErrors}</div> 
      : '')}
        <p>Tell us what {lcFirst(process.env.REACT_APP_CLASS_HELPER)} topic you'd like to see. We'll send it to our {process.env.REACT_APP_TEACHER_HELPER}s to make it happen.</p>
        <div className="row">
          <div className="col-md-12">
            <form className="form-horizontal login_model" onSubmit={handleSubmit(formSubmit)}>
              <fieldset>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher"><strong>Topic</strong></label>
                    </div>
                    <div className="col-md-8">
                      <div className="controls">
                        <input id="class_name" ref={register({required:'This field is required.'})} name="class_name" className="form-control input-large" type="text" placeholder="Topic description, be specific" />
                      </div>
                      {errors.class_name && <p className="text-danger">{errors.class_name.message}</p>}
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <div className="selectdiv">
                          <select name="age" id="age" ref={register({required:'This field is required.'})} className="form-control">
                            <option value="">for age</option>
                            <option value="3">age 3</option>
                            <option value="4">age 4</option>
                            <option value="5">age 5</option>
                            <option value="6">age 6</option>
                            <option value="7">age 7</option>
                            <option value="8">age 8</option>
                            <option value="9">age 9</option>
                            <option value="10">age 10</option>
                            <option value="11">age 11</option>
                            <option value="12">age 12</option>
                            <option value={"13"}>age 13</option>
                            <option value={"14"}>age 14</option>
                            <option value={"15"}>age 15</option>
                            <option value={"16"}>age 16</option>
                            <option value={"17"}>age 17</option>
                            <option value={"18"}>age 18</option>
                          </select>
                        </div>
                      </div>
                      {errors.age && <p className="text-danger">{errors.age.message}</p>}
                    </div>
                    <div className="col-md-12">
                      <div className="controls">
                        <textarea name="description" placeholder="Description" ref={register} className="form-control" rows="3"></textarea>
                      </div>
                      {errors.description && <p className="text-danger">{errors.age.description}</p>}
                    </div>
                  </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    {/* Button */}
                    <div className="control-group">
                      <div className="controls">
                        <button id="confirmsignup" disabled={isSubmitting} name="confirmsignup" className="btn btn-success register-btn">Send</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* Button */}
                    <div className="control-group">
                      <div className="controls">
                        <button id="confirmsignup" onClick={()=>setRequestClassModalShow(false)} type="button" name="confirmsignup" className="btn btn-success register-btn clr_change">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
          </Modal.Body>
        </Modal>
      </>
  );
}

export default RequestAClass;
