import React, { useState, useContext,useRef } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import { useForm,Controller } from "react-hook-form";
import { useHistory,Link } from "react-router-dom";
import GlobalContext from "../../../../GlobalContext";
import {setUserInfo} from '../../../auth/Athentication'
import PasswordStrengthBar from 'react-password-strength-bar';
import Success from "../../../layout/alert/Success";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SignUpSec() {
  let history = useHistory();
  const {setLoggedIn} = useContext(GlobalContext)
  const [EmailRef,setEmailRef] = useState(useRef());
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [successMessage, setSuccessMessage] = useState(null);     
  const { register,handleSubmit,getValues,watch,setError,errors,control } = useForm();
  const startDate= new Date(new Date().setFullYear(new Date().getFullYear() - 20));
  const endDate= new Date(new Date().setFullYear(new Date().getFullYear() - 13));
  const userType = watch('user_type');

  

//submission
const formSubmit = (data)=>{
  // setError("email",{message:"Resume should not exceeds 2MB.",shouldFocus:true});
  // EmailRef.focus();

setIsSubmitting(true);

axios.post('user/signup', data)
.then(response => {
  let res = response.data;
  console.log(res);
  if(res.status && res.status == 'success'){
    setUserInfo(res.user);
    setLoggedIn(true)
    setSuccessMessage(res.message);
    setIsSubmitting(false);
      history.push("/account-interest-quesionnaire");
  }else{
      let msg_str ='';
      if(typeof(res.message)== 'string'){
          setFormErrors(res.message);
          setIsSubmitting(false);
      }else{ 
      Object.keys(res.message).map((ele)=>{
          res.message[ele].map((msg,key) => {
            setError(ele,{type:'manual',message:msg,shouldFocus:true});
            if(ele == 'email'){
              EmailRef.focus();
            }
              setIsSubmitting(false);
          });
      });
  }
      
  }     
})
.catch(error => {
  setIsSubmitting(false);
  console.log(error);
  alert('Internal server error');
});

};
    return (
      <section className="signup-sec">
        <div className="container">
          <div className="row signup-form-margin">
            <div className="col-md-6 singup-img-div">
              <img src="assets/img/left-img.jpg" />
            </div>
            <div className="col-md-6 singup-form-div">
              <div className="form-box">
                <h1>Join</h1>
                { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
                <form className="form-horizontal" onSubmit={handleSubmit(formSubmit)}>
                  <fieldset>
                    <div className="control-group">
                      <div className="controls">
                        <label className="radio inline" htmlFor="humancheck-0">
                          <input type="radio" name="user_type" id="humancheck-0" ref={register({required:'This field is required.'})} value="parent" /> I'm a Parent</label>
                        <label className="radio inline" htmlFor="humancheck-1">
                          <input type="radio" name="user_type" id="humancheck-1"  ref={register({required:'This field is required.'})} value="student" /> I'm a Student (You must be at least 13 years old)</label>
                      </div>
                      {errors.user_type && <p className="text-danger">{errors.user_type.message}</p>}
                    </div>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="controls">
                            <input id="fname" name="first_name" className="form-control input-large" ref={register({required:'This field is required.'})} type="text" placeholder="First Name" />
                            <img src="assets/img/form-user.svg" />
                          </div>
                          {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                        </div>
                        <div className="col-md-6">
                          <div className="controls">
                            <input id="lname" name="last_name" className="form-control input-large" type="text" ref={register({required:'This field is required.'})} placeholder="Last Name" />
                            <img src="assets/img/form-user.svg" />
                          </div>
                          {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input id="nickname" name="nick_name" className="form-control input-large" type="text" ref={register} placeholder="Preferred name to be displayed (optional)" />
                        <img src="assets/img/form-user.svg" />
                      </div>
                    </div>
                    {userType == 'student' ? 
                    <div className="control-group">
                      <div className="controls">
                        <Controller
                        control={control}
                        name="date_of_birth"
                        rules={{ validate: {
                          required: (value) => {
                            if (!value && userType=='student')
                              return "This field is required.";
                            return true;
                          }
                        } }}
                        render={({ onChange, onBlur, value }) => (
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            className="form-control input-large"
                            placeholderText="Date of Birth"
                            dateFormat="MM/yyyy"
                            maxDate={endDate}
                            minDate={startDate}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            selected={value}
                          />
                        )}
                      />
                        <img src="assets/img/calendar.svg" />
                        {errors.date_of_birth && <p className="text-danger">This field is required.</p>}     
                      </div>
                    </div>
                    :''}

                    <div className="control-group">
                      <div className="controls">
                        <input id="email" autoComplete={false} name="email" className="form-control input-large" type="text" ref={(ref)=>{setEmailRef(ref); register(ref,{required:'This field is required.',pattern:{value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,message:'Invalid Email format.'} })}} placeholder="Email" />
                        <img src="assets/img/signup-mail.svg" />
                      </div>
                      {errors.email && <p className="text-danger">{errors.email.message}</p>}
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input id="password" autoComplete={false} name="password" className="form-control input-large" type="password" ref={register({required:'This field is required.'})} placeholder="Password" />
                        <img src="assets/img/form-password.svg" />
                        
                        <PasswordStrengthBar password={watch("password")} barColors={['#ddd', '#ef4836', '#2b90ef', '#2b90ef', '#25c281']} minLength={6} scoreWords={['weak','weak','good','good', 'strong']} />
                      </div>
                      {errors.password && <p className="text-danger">{errors.password.message}</p>}
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <input id="reenterpassword" className="form-control input-large" name="confirm_password" 
                        ref={ register({
                          required:'This field is required.', 
                          validate: {
                            matchesPreviousPassword: (value) => {
                              const { password } = getValues();
                              return password === value || 'Passwords should match!';
                              },
                          }}) } type="password" placeholder="Re-Enter Password" />  
                        <img src="assets/img/form-password.svg" />
                      </div>
                      {errors.confirm_password && <p className="text-danger">{errors.confirm_password.message}</p>}
                    </div>
                    <div className="control-group">
                      <div className="controls">
                        <label className="radio inline terms_terms" htmlFor="terms">
                          {/* <input type="checkbox" name="terms" id="terms" value="robot">&nbsp; */} By joining, you agree to the <Link to={'/terms'} className="terms">Terms and Conditions</Link></label>
                      </div>
                    </div>
                    {/* Button */}
                    <div className="control-group">
                      <div className="controls">
                        <button id="confirmsignup" disabled={isSubmitting} name="confirmsignup" class="btn btn-success register-btn">Join {process.env.REACT_APP_SITE_NAME}</button>
                      </div>
                    </div>
                    {/* Terms input*/}
                    <div className="control-group offser-sec">
                      <div className="controls">
                        <label className="radio inline" htmlFor="offers">
                          <input type="checkbox" name="enable_special_offers" id="offers" ref={register} value="1" defaultChecked />&nbsp; Receive personalized recommendations and special offers. </label>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  export default SignUpSec