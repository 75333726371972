import React,{useState,useEffect,useContext} from "react";
import HeaderHero from "../home/blocks/HeaderHero";
import Welcome from "../home/blocks/Welcome";
import Search from "../home/blocks/Search";
import TrendingClasses from "../home/blocks/TrendingClasses";
import ClassGrid from "../home/blocks/ClassGrid";
import Testimonials from "../home/blocks/Testimonials";
import Press from "../page/teach/blocks/Press";
import axios from 'axios';
import FeaturedTeacher from "../home/blocks/FeaturedTeachers";
import BlogGrid from "../home/blocks/BlogGrid";
import {IsAuthenticated} from '../auth/Athentication';
import FlyerPopup from "../layout/FlyerPopup";
import GlobalContext from "../../GlobalContext";
import ExclusiveChannelClasses from "./blocks/ExclusiveChannelClasses";
import ClassesStartsIn24hrs from "../page/user/user-dashboard/blocks/ClassesStartsIn24Hrs";


function Home() {
  const [homepageCms, setHomepageCms] = useState({});
  const {setIsInnerPage,loggedIn} = useContext(GlobalContext) 
  setIsInnerPage(false)

  useEffect(() => {
    getHomePageCms();
  }, []);

  async function getHomePageCms() {
    const response = await axios.get("home/homepage-cms");
    if(!response.data.data){
      alert('Invalid Request');
      return;
    }
    setHomepageCms(response.data.data);
}
    return (
      Object.keys(homepageCms).length ?
      <>
        <FlyerPopup content={homepageCms.welcome_modal_content} />
        <HeaderHero heading={homepageCms.banner_heading} vimeo_id={homepageCms.vimeo_id ? homepageCms.vimeo_id :'453561652'} content={homepageCms.banner_description} />
        <Welcome cms={homepageCms} />
        <Search />
        {loggedIn == true ? <ExclusiveChannelClasses /> : ''}
        <ClassGrid />
        <TrendingClasses />
        <ClassesStartsIn24hrs />
        <Testimonials />
        <FeaturedTeacher />
        <BlogGrid />
        <Press/>
      </> : <></>
    );
}

export default Home