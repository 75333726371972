import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser';

export default function FlyerPopup(props){
    const content = props.content;
return (
      <>
        <p className="p-1 pl-3 font-weight-bold text-center m-0 text-white bg-warning">{content}</p>
      </>
);
}