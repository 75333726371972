import React from 'react';
import axios from 'axios';
import {Redirect,useHistory } from 'react-router-dom';


 export function IsAuthenticated()
{
    const token = localStorage.getItem('tunedInappToken');
    if(token){
        return true;
    }else{
        return false;
    }
}

export function setUserInfo(userInfo)
{
    localStorage.setItem("tunedInappToken", userInfo.token);
    localStorage.setItem("Firstname",userInfo.first_name);
    localStorage.setItem("Lastname",userInfo.last_name);
    localStorage.setItem("email",userInfo.email);
    localStorage.setItem("user_type",userInfo.user_type);
    localStorage.setItem("email_verified_at",userInfo.email_verified_at);
    localStorage.setItem("Avatar",userInfo.photo);
    localStorage.setItem("created_at",userInfo.created_at);
}

export function getUserdata()
{
    return {
        email_verified_at:localStorage.getItem('email_verified_at'),
        first_name:localStorage.getItem('Firstname'),
        email:localStorage.getItem('email'),
        user_type:localStorage.getItem('user_type'),
        avatar:localStorage.getItem('Avatar'),
    }
}

export function UserRole()
{
    if(IsAuthenticated()){
        return localStorage.getItem('user_type');
    }else{
        return false;
    }
}

export function getLoggedFullname()
{
    return localStorage.getItem('Firstname')+' '+localStorage.getItem('Lastname')
}

export function getUserEmail()
{
    return localStorage.getItem('email');
}

export function isSocialLogin()
{
    const socialLogin = localStorage.getItem('socialLogin');
    if(socialLogin) 
        return true;
    else
        return false;
}

export async function checkLogin()
{
    const response  = await axios.get('user/check-login').then(res=>{
        if(res.data.status=='success'){
            return true;
        }else{
           return false;
        }
    }).catch(err=>{
        return false;
    });
    
    return response;
}

export function checkRememberMe()
{
    if(localStorage.getItem('remember_email') && localStorage.getItem('remember_password') ){
        return true;
    }
    return false;
}
export function remembered_email(value)
{
    if(checkRememberMe()){
        return localStorage.getItem('remember_email');
    }
    return '';
}
export function remembered_password(value)
{
    if(checkRememberMe()){
        return localStorage.getItem('remember_password');
    }
    return '';
}
export function setRememberMeData(email,password){
    localStorage.setItem('remember_email',email);
    localStorage.setItem('remember_password',password);
}
export function clearRememberMeData()
{
    localStorage.removeItem('remember_email')
    localStorage.removeItem('remember_password')
}

export const siteInfo = async() =>{
    const response = await axios.get('home/site-info');
    if(response.data.status == 'success'){
        const res = response.data.data;
        localStorage.setItem('siteName',res.site_name);
    }else{
        localStorage.setItem('siteName','Oggi Learning');
    }
    
}
export const siteName = ()=>{
    if(localStorage.getItem('siteName')){
        return localStorage.getItem('siteName')
    }else{
        return "Oggi Learning"
    }
}

export async function logout()
{
    const formdata = new FormData();
    formdata.append('token',localStorage.getItem('tunedInappToken'));
    const rem_email =  localStorage.getItem('remember_email');
    const rem_password =  localStorage.getItem('remember_password');

    const response = await axios.post("user/logout",formdata).then(res=>{
        if(typeof window.FB !=='undefined'){
            window.FB.logout();
        }
        if(typeof(res.data.status)!='undefined' && res.data.status == 'success'){
            localStorage.setItem('tunedInappToken','');
            localStorage.setItem('user_type','');
            localStorage.setItem('Firstname','');
            localStorage.setItem('Avatar','');
            localStorage.clear();
            if(rem_email){
                setRememberMeData(rem_email,rem_password); 
            }
            window.location.reload();
            return true;
        }
    }).catch(err=>{
        console.log(err);
            localStorage.setItem('tunedInappToken','');
            localStorage.setItem('user_type','');
            localStorage.setItem('Firstname','');
            localStorage.setItem('Avatar','');
            localStorage.clear();
            if(rem_email){
                setRememberMeData(rem_email,rem_password); 
            }
            window.location.reload();
        // alert('Internal server error.')
        return;
    })
    return response;
    
}