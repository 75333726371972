import React, { useState,useEffect } from "react";
import BlogBanner from './blocks/BlogBanner'
import BlogGrid from './blocks/BlogGrid'
import { useLocation } from "react-router-dom";


function Blog(props) {
  props.setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

  return (
    <>
      <BlogBanner />
      <BlogGrid />
    </>
  );
}
export default Blog;
