import React,{useEffect,useState} from 'react'
import {UserRole} from '../../../../auth/Athentication'
import { Link,useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';

export default function QuestionnaireSec() {
  let history = useHistory();
  const user_role = UserRole();
  const [channels,setChannels] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [formErrors, setFormErrors] = useState();
  const { register,handleSubmit,errors } = useForm();

  useEffect(() => {
    get_channels();
  }, [])

    async function get_channels()
    {
      const res = await axios.get('get-channels/public',{params:{show_in_interests:'yes'}});
      setChannels(res.data.data);
    }

    
//submission
const formSubmit = (data)=>{

  if(!data.channels.length){
      history.push("/user-dashboard");
  }
setIsSubmitting(true);

axios.post('user/submit-user-interests', data)
.then(response => {
  let res = response.data;
  console.log(res);
  if(res.status && res.status == 'success'){
    if(user_role == 'parent'){
      history.push("/account-add-child");
    }else{
      history.push("/user-dashboard");
    }
  }else{
    setIsSubmitting(false);
      let msg_str ='';
      if(typeof(res.message)== 'string'){
          setFormErrors(res.message);
      }else{ 
          Object.keys(res.message).map((ele)=>{
              res.message[ele].map((msg,key) => {
                  msg_str += msg+'\n';
                  setFormErrors(msg_str);
              });

          });
  }
      
  }     
}).catch(error => {
    setIsSubmitting(false);
    console.log(error);
    alert('Internal server error');
  });

}
const handleQuestionnaireSkip = (event)=>{
  event.preventDefault();
  if(user_role == 'parent'){
    history.push("/account-add-child");
  }else{
    history.push("/user-dashboard");
  }
}
    return (
      <>
      <section className="signup-sec">
              <div className="container">
                <div className="row signup-form-margin">
                  <div className="col-md-8 offset-2 singup-form-div">
                  { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
                    <div className="form-box">
                      <h1>Which topics do you want to explore?</h1>
                      <h2>The information you share below will help us recommend events your family will love.</h2>
                      <form className="form-horizontal" onSubmit={handleSubmit(formSubmit)}>
                        <fieldset className="age_form">
                              {errors.channels && <p className="text-danger">{errors.channels.message}</p>}
                          <div className="row">
                            {channels && channels.map((channel,index)=>{
                              return(
                                  <div key={'int'+index} className="col-md-4">
                                    <div className="control-group">
                                      <div className="controls">
                                        <label className="radio inline" htmlFor={'channel'+index}>
                                          <input type="checkbox" name="channels" ref={register({required:'Please select atleast one option.'})} id={'channel'+index} value={channel.id} />&nbsp; {channel.channel_name} </label>
                                      </div>
                                    </div>
                                  </div>
                              )
                            })}
                          </div>
                          <div className="control-group text-center">
                            <div className="controls">
                              <button type="submit" disabled={isSubmitting==false || channels.length ? false:true} className="btn btn-success1 register-btn btn_block">Submit</button> <Link onClick={handleQuestionnaireSkip} className="btn btn-success1 register-btn clr_change btn_block">Skip</Link>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
      </>
    );
}
