import React,{useState,useEffect} from 'react'
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import {UserRole} from '../../../auth/Athentication'
import SubmittingLoadingImg from '../../../helpers/SubmittingLoadingImg'
import {lcFirst} from '../../../helpers/StringHelper'
import axios from 'axios'

export default function FriendsSec() {
  const defaultFormData = {
    defaultValues: {
      friendsGroup: [{ firstName: "", lastName: "",email:"" }]
    }
  };
  const { register, control, handleSubmit,setError ,reset,errors, watch } = useForm(defaultFormData);
  const { fields,trigger, append, remove, insert } = useFieldArray({
      control,
      name: "friendsGroup"
    });
  const userrole = UserRole();
  const [invitedGroups,setInvitedGroups] = useState([])
  const maxGroupLimit = 3;
  const maxMemberLimit = 8;
  const [isGroupFetching,setIsGroupFetching] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [showSuccessMsg,setShowSuccessMsg] = useState(false);
  const [errorMessage,setErrorMessage] = useState(null);
  const [friendGroupNumber,setFriendGroupNumber] = useState(1);
  const [selectedUser,setSelectedUser] = useState();
  const [myChildren,setMyChildren] = useState([]);
  useEffect(()=>{
    if(userrole == 'student'){
      getInvitedGroups();
    }
    getChildrens();
  },[]);
  useEffect(()=>{
    if(selectedUser){
      getInvitedGroups(selectedUser);
    }
    getChildrens();
  },[selectedUser]);

  useEffect(()=>{
    if(!showSuccessMsg) return;

    setTimeout(function(){
      setShowSuccessMsg(false)
    },5000)
  },[showSuccessMsg])

  async function getInvitedGroups(childId=null)
  {
    if(childId == 'me'){
      childId = null;
    }
    setIsGroupFetching(true);
    let userId = childId ? childId : '';
    let isChild = childId ? 'yes' : '';
    const res = await axios.get('user/friends-goups',{params:{user_id:userId,is_child:isChild}});
    if(res && res.data && res.data.status == 'success'){
      setInvitedGroups(res.data.data);
        setFriendGroupNumber(res.data.data.length+1)
      setIsGroupFetching(false);
      if(fields.length<1){
        append({firstName:'',lastName:'',email:''});
      }
    }
  }

  async function getChildrens()
  {
    const res = await axios.get('user/get-children');
    if(res && res.data && res.data.status == 'success'){
      if(res.data.data.length<=0){
        getInvitedGroups();
      }
      setMyChildren(res.data.data);
    }
  }

  const handleRemoveRecord = (e,index) =>{
    e.preventDefault();
    if(fields.length<2){
      return false;
    }
       remove(index);
  }
const handleChild = (event) =>{
  event.preventDefault();
  
  const value = event.target.value;
  setSelectedUser(value);
}
  const submitForm =(data) =>{
    setErrorMessage(null)
    setIsSubmitting(true)
    if(selectedUser){
      data.child_id = selectedUser;
    }
    axios.post('user/submit-friends-group', data).then(response => {
        let res = response.data;
        if(res.status=='success'){
          setShowSuccessMsg(true)
          reset(defaultFormData)
          getInvitedGroups(selectedUser);
          append({firstName:'',lastName:'',email:''});
        }else{
          setErrorMessage(res.message)
        }
        setIsSubmitting(false)
      }).catch(error => {
        console.log(error);
        alert('Internal server error');
    });

  }
    return (
      <>
        <section className="user-dashboard"> 
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              <form className="form-horizontal teacher_form frnds_form change-pwd-from" onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-md-12">
                    <h2> We help you stay connected.</h2>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-md-4">
                    <div className="numbers">1</div>
                    <h3>Choose an {lcFirst(process.env.REACT_APP_CLASS_HELPER)} you'll love</h3>
                  </div>
                  <div className="col-md-4">
                    <div className="numbers">2</div>
                    <h3>Invite old and new friends</h3>
                  </div>
                  <div className="col-md-4">
                    <div className="numbers">3</div>
                    <h3>Explore and learn together</h3>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                  <img src="/assets/img/friends_page_bnr.jpg" className="img-responsive frnds_bnr_img" />
                </div>
            </div>
            {myChildren.length ? 
                <div className="row">
                  <div className="col-md-3">
                    <div className="controls">
                      {/* <input id="grade" name="grade" class="form-control input-large" type="text" placeholder="Current grade"> */}
                      <div className="selectdiv">
                        <select name="child" id="child" className="form-control" onChange={handleChild}>
                            <option value="">Select Child</option>
                            <option value="me">Me</option>
                          {myChildren.map((child,index)=>{
                            return (
                            <option key={index} value={child.id} >{child.first_name+' '+child.last_name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div> 
                : ''}
                <div className="row">
                  <div className="invitedgroups">
                        <div className="col-md-12">
                {
                  invitedGroups && invitedGroups.length ? 
                    <>
                      <br clear="all" />
                        <table className="table table-bordered cohort_table">
                          <thead>
                          <tr>
                            <th>Group</th>
                            <th>Invited Members</th>
                          </tr>
                          </thead>
                          <tbody>
                        {invitedGroups.map((group,index1)=>{
                            return (
                          <tr key={index1}>
                            <td>{index1+1}</td>
                            <td>
                              <ul>
                              {group.cohort_users.map((member,index2)=>{
                                return (
                                  <li key={index2}>{member.first_name+' '+member.last_name+ (member.email ? '('+member.email+')': '')}</li>
                                )
                              })}
                            </ul>
                            </td>
                          </tr>
                              ) 
                              })}
                              </tbody>
                        </table>
                   </> :''}
                  {isGroupFetching ? <SubmittingLoadingImg />:'' }
                  </div>
                  </div>
                      </div>
              <br clear="all" />
              {errorMessage ? <div className="alert alert-danger">
                <strong>Success!</strong>{errorMessage}
              </div> : ''}
              {showSuccessMsg ? <div className="alert alert-success">
                <strong>Success!</strong> Invitations sent successfully.
              </div> : ''}
                {((selectedUser && userrole == 'parent') || userrole=='student') && friendGroupNumber <= maxGroupLimit ? 
                <>
                <h3 className="mt-20">Friend Group: {friendGroupNumber}</h3>
                { fields.map((item, index) => {
                  return (
                  <div key={index} className="control-group">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="controls">
                          <input id="first_name" name={`friendsGroup[${index}].firstName`}  ref={register({required:'This field is required.'})} className="form-control input-large search_input" type="text" placeholder="Friend's First Name (required)" />
                        </div>
                        {errors.friendsGroup && errors.friendsGroup[index] && errors.friendsGroup[index].firstName && <span className="text-danger">{errors.friendsGroup[index].firstName.message}</span> }
                      </div>
                      <div className="col-md-3">
                        <div className="controls">
                          <input id="lname" name={`friendsGroup[${index}].lastName`}  ref={register({required:'This field is required.'})}  className="form-control input-large search_input" type="text" placeholder="Friend's Last Name (required)"  />
                        </div>
                        {errors.friendsGroup && errors.friendsGroup[index] && errors.friendsGroup[index].lastName && <span className="text-danger">{errors.friendsGroup[index].lastName.message}</span> }
                      </div>
                      <div className="col-md-4">
                        <div className="controls">
                          <input id="email" name={`friendsGroup[${index}].email`}  ref={register({required:'This field is required.'})} className="form-control input-large search_input" type="email" placeholder="Email" />
                          <p className="email_text">(If your friend is below 13, please enter their parents email address)</p>
                        </div>
                        {errors.friendsGroup && errors.friendsGroup[index] && errors.friendsGroup[index].email && <span className="text-danger">{errors.friendsGroup[index].email.message}</span> }
                      </div>
                      <div className="col-md-2">
                        <div className="control-group">  
                          <div className="controls">
                            <button type="button" id="plus" onClick={(e) => {handleRemoveRecord(e,index)}}   name="plus" className="btn btn-success register-btn"><i className="icofont-close" /></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 )
               }) }
                {fields.length < maxMemberLimit ?
                <button onClick={(e) => {e.preventDefault(); append({firstName:'',lastName:'',email:''});}} className="btn btn-success1 register-btn btn_block float-right"><i className="icofont-plus"></i> Add</button>
                  : ''}
                <button type="submit" disabled={isSubmitting} className="btn btn-success1 register-btn btn_block">Invite</button>
                </>
                : friendGroupNumber > maxGroupLimit && <p>Maximum groups invited.</p>}
              </form>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}
