import React,{useState,useEffect} from "react";
import axios from 'axios';
import ReviewItem from './ReviewItem';
import { Link } from "react-router-dom";

export default function ReviewList(props)
{
    const user_id = props.teacherId;
    const [classReviews,setClassReviews] = useState({});
    const [limit,setLimit] = useState(5);
    
    useEffect(() => {
        getClassReviews();
    }, []);

    async function getClassReviews() {
        const response = await axios.get("teacher/teacher-reviews",{params:{teacher_user_id:user_id}});
        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        setClassReviews(response.data.data);
    }

    return (
        <>
        {Object.keys(classReviews).length ?
         classReviews.slice(0,limit).map((review, index) => <ReviewItem key={index} review={review} /> ) 
        : <></> } 
          {classReviews.length > limit ?
          <Link onClick={(e)=>{e.preventDefault(); setLimit(limit+5)}} to="#" class="get_button request_btn">See More Reviews</Link> : ''}
         </>
    )
}