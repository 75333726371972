import React, { useState,useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../GlobalContext";


function HeaderLoggedOutNav(props) {
  const {setLoginModelOpen,setIsModelBackdropOpen} = useContext(GlobalContext)
  function handleLoginModal() {
    setLoginModelOpen(true);
    setIsModelBackdropOpen(true);    
    props.setIsMobileMenuHidden(false)
  }

  const hideMobileMenu = ()=>{
    // props.setIsMobileMenuHidden(props.isMobileMenuHidden==false)
    // props.setIsMobileMenuHidden(false)
  }

  return (
    <>
          <nav className="nav-menu desktop_menu">
            <ul className="float-left">
              <li className="active1">
            <Link onClick={hideMobileMenu()} to="/search"><img src="/assets/img/search.svg"  className="search-icon"/> Search for Events</Link>
              </li>
              <li>
            <Link onClick={hideMobileMenu()} to="/teach">Host</Link>
              </li>
              <li>
            <Link onClick={hideMobileMenu()} to="/help">Help</Link>
              </li>
              <li>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#loginModel"
                  onClick={handleLoginModal}
                >
                  Login
                </a>
              </li>
              <li>
            <Link onClick={() => props.setIsMobileMenuHidden(false)} to="/signup"> Join
                </Link>
              </li>
            </ul>
          </nav>
          <div className={`${props.isMobileMenuHidden ? "mobile-nav-active" : ""}`}>
            <nav className="mobile-nav">
              <ul className="float-left">
              <li className="active1">
              <Link to="/" onClick={() => props.setIsMobileMenuHidden(false)}>
                    <img src="/assets/img/home.svg" /> Home
                  </Link>
                </li>
                <li>
              <Link to="/search" onClick={() => props.setIsMobileMenuHidden(false)}>
                    <img src="/assets/img/search_menu.svg" /> Search
                  </Link>
                </li>
                <li className="active1">
              <Link to="/teach" onClick={() => props.setIsMobileMenuHidden(false)}>
                      <img src="/assets/img/teacher.svg" /> Host
                    </Link>
                </li>
                <li>
              <Link to="/help" onClick={() => props.setIsMobileMenuHidden(false)}> <img src="/assets/img/question.svg" /> Help</Link>
                </li>
                <li>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#loginModel"
                  onClick={()=>{ handleLoginModal()}}>
                    <img src="/assets/img/login1.svg" /> Login
                  </a>
                </li>
                
                <li>
                  <Link to="/signup" onClick={() => props.setIsMobileMenuHidden(false)}>
                    <img src="/assets/img/plus.svg" /> Join
                  </Link>
                </li>
              </ul>
            </nav>
           </div>
    </>
  );
}

export default HeaderLoggedOutNav;
