import React,{useEffect,useState,useContext} from "react";
import GlobalContext from "../../../GlobalContext";
import parse from 'html-react-parser';
import Axios from "axios";
import HeaderTextBanner from "../../layout/HeaderTextBanner";
import LoadingIcon from '../../helpers/LoadingIcon'


function Faq(props) {
  
  const [pageLoaded,setPageLoaded] = useState(false);
  const [cmsPage,setCmsPage] = useState([]);
  const [cmsBlocks,setCmsBlocks] = useState([]);

  const {setIsInnerPage} = useContext(GlobalContext) 
  setIsInnerPage(false)
  

useEffect(() => {
  getCmsPage();
}, []);
async function getCmsPage()
  {
    const res = await Axios.get('cms/get-cms-page',{params:{cms_slug:'faq'}});
    if(res.data.status && res.data.status == 'success'){
      setCmsPage(res.data.data?res.data.data:[]); 
      setCmsBlocks(res.data.data.cms_blocks);
      setPageLoaded(true);
    }
  }

  return (
    <>
       <HeaderTextBanner heading={cmsPage.cms_title?cmsPage.cms_title : ''} />
       <section className="user-dashboard">
       {pageLoaded ? 
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              <div className="payout_div">
                <div className="row">
                  {cmsBlocks.length ? cmsBlocks.map((block,index)=>{
                  return (
                   <div className="col-md-12">
                      <div className="email_row1">
                        <p><strong><i className="icofont-question-circle" />{block.heading}</strong></p>
                        <p>{parse(block.content)}</p>
                      </div>
                  </div>
                  )
                }) : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
         : <LoadingIcon /> }
      </section>
    </>
  );
}
export default Faq;
