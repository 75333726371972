import React from 'react'
import {Link} from 'react-router-dom'


export default function FeaturedTeacherItem(props)
{
    const teacher = props.teacher;

    return (
        <div className="col-md-4">
            <div className="teach-box">
                <img src={teacher.photo} alt={teacher.fullname} />
                <Link to={'/teachers/'+teacher.unique_id}><h3>{teacher.fullname}</h3></Link>
                <p>{teacher.headline}</p>
            </div>
        </div>
    )
}