import React, { useState,useEffect, useContext } from "react";
import HeaderTextBanner from '../../layout/HeaderTextBanner';
import ClassFormTab from './tabs/ClassFormTab';
import axios from 'axios';
import ClassSummaryTab from './tabs/ClassSummaryTab';
import { useParams } from 'react-router-dom';
import GlobalContext from "../../../GlobalContext";
export default function AddNewClassStep2() {
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
    const { title,reqType } = useParams(); //for Edit title is class ID
    const [activetab, setActiveTab] = useState('ClassFormTab');
    const [classInfo, setClassInfo] = useState({});
    const [classId, setClassId] = useState(null);
    const [classTitle, setClassTitle] = useState(title);
      async function getClassInfo(c_title) {
        if(reqType == 'edit'){
          const response = await axios.get("get-class-short-details/"+c_title);
          if(!response.data.data){
            alert('Invalid Request');
            return;
          }
          setClassInfo(response.data.data);
          setClassTitle(response.data.data.title);
          setClassId(response.data.data.id);
        }else{
          setClassTitle(c_title)
        }
      }
      useEffect(() => {
        if(localStorage.getItem('classDefaultTab')!=null && localStorage.getItem('classDefaultTab')!='' && typeof(localStorage.getItem('classDefaultTab'))!='undefined' ){
          setActiveTab(localStorage.getItem('classDefaultTab'));
          }
        getClassInfo(title);
      }, []);

      useEffect(()=>{
        if(activetab){
          localStorage.setItem('classDefaultTab',activetab);
        }
      },[activetab]);
  
    return(
        <div>
            <HeaderTextBanner heading={classTitle} />
            <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {/* Bootstrap CSS */}
              <ul className="nav nav-tabs user-dashboard-ul" role="tablist" id="myTab">
              <li className="nav-item">
                  <a
                    onClick={() => setActiveTab("ClassFormTab")}
                    className={
                      "nav-link " +
                      (activetab == "ClassFormTab" ? " active " : "")
                    }
                    href="#one"
                    role="tab"
                    data-toggle="tab"
                  >
                    Edit
                  </a>
                </li>
                {
                  reqType && reqType=='add' ? '' : (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveTab("ClassSummaryTab")}
                    className={
                      "nav-link " +
                      (activetab == "ClassSummaryTab" ? " active " : "")
                    }
                    href="#two"
                    role="tab"
                    data-toggle="tab"
                  >
                    Summary
                  </a>
                </li>
                ) }
              </ul>
              {/* Tab panes */}
              {/* Tab panes */}
              <div className="tab-content profile_form">
                {
                  {
                    ClassFormTab: reqType=='add' ? <ClassFormTab title={classTitle} reqType={reqType} /> : 
                    (classId ? <ClassFormTab title={classTitle} reqType={reqType} class_id={classId}/> : <><p>Loading...</p></>),
                    ClassSummaryTab:(classId ? <ClassSummaryTab classInfo={classInfo} title={classTitle} reqType={reqType} class_id={classId}/> : <><p>Loading...</p></>),
                  }[activetab]
                }
              </div>
             
            </div>
          </div>
        </div>
      </section>  
        </div>
    )
}