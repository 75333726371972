import React from "react";
import Modal from 'react-bootstrap/Modal'
import { Link} from "react-router-dom";
import { GetStringForUrl } from "../../helpers/StringHelper";
import './alert-style.css';
function MeetingConflictErrorAlert(props) {
  const show = props.show;
  const SectionInfo = props.sectioninfo;
  const message = props.message;
  const closeModal = () => {
        props.setErrorAlertShow(false); 
    }
    const closeModals =()=>{
      props.setErrorAlertShow(false);
      props.setEnrollModalClose(false)
    }
    // setTimeout(closeModal,4000);
    return(
      <Modal show={show} onHide={closeModal} size="md" className="alert_model">
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className="singup-form-div">
                <div className="form-box flyer_popup">
                    <div className="row">
                        <div className="col-md-12 wel_text">
                          <p>{props.message}</p>
                          <p>
                          <Link onClick={closeModals} to={"/class-details/"+GetStringForUrl(SectionInfo.title)+'/'+SectionInfo.class_uuid} className="get_button request_btn">Choose another Section</Link>
                          </p>
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
      </Modal>
    );
}
export default MeetingConflictErrorAlert;
