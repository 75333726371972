import React,{useEffect,useState,useContext} from "react";
import GlobalContext from "../../../GlobalContext";
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import Axios from "axios";
import NotFoundPage from '../../layout/NotFoundPage'
import HeaderTextBanner from "../../layout/HeaderTextBanner";
import LoadingIcon from '../../helpers/LoadingIcon'


export default function CmsPage()
{
  let location = useLocation();
  const [pageLoaded,setPageLoaded] = useState(false);
  const [cmsPage,setCmsPage] = useState([]);
  const [cmsBlocks,setCmsBlocks] = useState([]);
  const currentPage = location.pathname;
  const {setIsInnerPage} = useContext(GlobalContext) 
  setIsInnerPage(false)
  

useEffect(() => {
  getCmsPage();
}, [location.pathname]);

async function getCmsPage()
  {
    const res = await Axios.get('cms/get-cms-page',{params:{cms_slug:currentPage.substring(1)}});
    if(res.data.status && res.data.status == 'success'){
      setCmsPage(res.data.data?res.data.data:[]); 
      setCmsBlocks(res.data.data.cms_blocks);
      setPageLoaded(true);
    }
  }
    return (
        <>
         <HeaderTextBanner heading={cmsPage.cms_title?cmsPage.cms_title : ''} />
      <section className="cms-sec">
        {pageLoaded ? 
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {cmsBlocks.length ? cmsBlocks.map((block,index)=>{
                let blockClass = 'color_two';
                if(index % 2 == 0){
                  blockClass = 'color_one';
                }
                return (
                <div className={'text-block '+blockClass}>
                  <h2>{block.heading}</h2>
                  {parse(block.content)}
                </div>
                )
              }) : ''}
            </div>
            </div>
            </div>
            : <LoadingIcon /> }
          </section>
        </>
    )
}