import axios from 'axios';

export async function ResendPasswordLink()
{
    const res = await axios.post('user/resend-password-link').catch(err=>{
        return 'error';
    });
    return res.data.status;
}

export async function validatePasswordToken(token)
{
    const res = await axios.get('user/check-forgot-password-token/'+token);
    if(res && res.data && res.data.status == 'success'){
        return 'success';
    }
    return 'error';
}