import React, { useState,useEffect,useContext } from "react";
import MyChannelsBanner from './blocks/MyChannelsBanner'
import MyChannelsSec from './blocks/MyChannelsSec'
import { useLocation } from "react-router-dom";
import GlobalContext from "../../../GlobalContext";


function MyChannelsPage(props) {
  const {setIsInnerPage} = useContext(GlobalContext) 
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

  return (
    <>
      <MyChannelsBanner />
      <MyChannelsSec />
    </>
  );
}
export default MyChannelsPage;
