import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import LoadingIcon from '../../../../helpers/LoadingIcon'
import SubmittingLoadingImg from '../../../../helpers/SubmittingLoadingImg'
import {Link} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {getLoggedFullname} from '../../../../auth/Athentication'
import './css/learners.css'

export default function ClassRoomTab(props) {
    const sectionId = props.section_id;
    const classId = props.class_id;
    const [enrollers,setEnrollers] = useState([]);
    const [callPosts,setCallPosts] = useState([]);
    const [classInfo,setClassInfo] = useState()
    const [selectedSectionId,setSelectedSectionId] = useState()
    const [isFetched,setIsFetched]=useState(false);
    const [isSubmitting,setIsSubmitting]=useState(false);

    const [formErrors,setFormErrors]= useState({});
    const [isPostsFetching, setIsPostsFetching] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [editedPostMessage, setEditedPostMessage] = useState('')
    const [isOpenEditPost, setIsOpenEditPost] = useState(false)
    const [isSubmittingModal, setIsSubmittingModal] = useState(false)
    const [postMessge,setPostMessage]=useState('');
    const [selectedPostId,setSelectedPostId]=useState('');

    useEffect(()=>{
      getClassRoomInfo();
      getEnrollers();
    },[]); 
    
    useEffect(() => {
      if(selectedSectionId){
        getTeacherClassPosts();
      }
    }, [selectedSectionId])
    
    useEffect(() => {
      if(selectedPostId){
        setIsOpenEditPost(true);
      }
    }, [selectedPostId])
    
    useEffect(() => {
      if(isOpenEditPost == false){
        setSelectedPostId('');
      }
    }, [isOpenEditPost])
    
    useEffect(() => {
      if(successMessage){
        setTimeout(function(){
          setSuccessMessage(null);
        },5000)
      }
    }, [successMessage])
    async function getClassRoomInfo()
        {
            const res = await axios.get('zoom/get-classroom-info/'+sectionId);
            if(res.data && res.data.status=='success'){
                console.log(res.data.data)
                if(res.data.data){
                   setClassInfo(res.data.data); 
                   setSelectedSectionId(res.data.data.call_section_id)
                }
            }
        } 

    async function getEnrollers(){
        const res = await axios.get('teacher/section-enrollers',{params:{section_id:sectionId}});
        if(typeof(res.data.status) !='undefined' && res.data.status == 'success'){
            setEnrollers(res.data.data);
            setIsFetched(true)
        }else{
            alert('something went wrong while fetching enrollers.')
            return;
        }
    }

    const getTeacherClassPosts = async () =>{
      setIsPostsFetching(true);
      const res = await axios.get("get-teacher-class-posts",{params:{section_id:selectedSectionId}});
      if(res.data.data){
        setCallPosts(res.data.data);
      }
      setIsPostsFetching(false)
    }

    const handlePostMessage = (e) =>{
      const input = e.target;
      setPostMessage(input.value);
    }

   //submit post
   const handleSubmitPost = (e) =>{
     setIsSubmitting(true);
     e.preventDefault();
     if(postMessge == ''){
       setFormErrors(formErrors => ({...formErrors, message:'Please enter message.'}));
       setIsSubmitting(false);
       return false;
      }else{
        setFormErrors(formErrors => ({...formErrors, message:''}));
      }
      const postData = {
        section_id:selectedSectionId,
        post_message : postMessge,
        class_id:classId,
      };
        axios.post('teacher/submit-section-post',postData).then((res)=>{
          if(res.data.status=='success'){
            setPostMessage('');
            getTeacherClassPosts();
            setSuccessMessage('Post added successfully.');
            setIsSubmitting(false);
          }
        }).catch(err=>{
          alert('Error occured while submitting Post.');
          return;
        });    
  } 
  const deleteTeacherPost = (e,post_id) =>{
    e.preventDefault();
    if(post_id){
      axios.post('teacher/delete-class-post',{post_id:post_id}).then((res)=>{
        if(res.data.status='success'){
          getTeacherClassPosts();
        }else{
          alert('Error occured while deleting Post')
        }
      })
    }
  }

  const handelEditPostModal = (e,post_id,post_message) =>{
    e.preventDefault();
    setSelectedPostId(post_id);
    setEditedPostMessage(post_message);
  }
  const onchangeEditPost = (e) =>{
    const input = e.target;
    setEditedPostMessage(input.value);
  }

  const handleUpdatePost = (e) =>{
    e.preventDefault();
    if(editedPostMessage == ''){
      setFormErrors(formErrors => ({...formErrors, editedPostError:'Please Enter your Post message.'}));
    }
    axios.post('teacher/update-call-post',{post_message:editedPostMessage,post_id:selectedPostId}).then((res)=>{
      if(res.data.status == 'success'){
        setSuccessMessage('Post Updated Successfully.');
        getTeacherClassPosts();
        setIsOpenEditPost(false);
      }else{
        alert('Error occured while updating post.');
      }
    })
  }

    return (
      <>
        <div className="learner_sec">
            {isFetched ? 
            <div className="row">
                <div className="col-md-3 left_div">
                    <h3>Enrolled ({enrollers.length})</h3>
                    {enrollers.map((enroll,index)=>{
                        return (
                            <p key={index}>{enroll.child?enroll.child.first_name+' '+enroll.child.last_name : enroll.enrolled_user ? enroll.enrolled_user.first_name+' '+enroll.enrolled_user.last_name :<p key={index}>-</p> }</p>
                        )
                    })}
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="meet_box">
                                { !classInfo || !classInfo.zoom_meeting_id ?  <p>No meeting found.</p> :
                                (classInfo.is_expired == true ? <a href="#" className="get_button">Meeting Ended</a> : <Link target="_blank" to={"/classroom-zoom-meting-start/"+classInfo.zoom_meeting_id+'/'+classInfo.id} className="get_button">Start Live Meeting</Link>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="post_box">
                            <h2>Add new post</h2>
                            <form onSubmit={handleSubmitPost}>
                                <div className="col-md-12">
                                {successMessage ? <div className="alert alert-success">{successMessage}</div> : ''} 
                                <label className="label_info" htmlFor="teacher">Only {process.env.REACT_APP_TEACHER_HELPER}s can add new posts.</label> <br />
                                <div className="controls">
                                    <textarea value={postMessge} onChange={handlePostMessage} className="form-control text-area" placeholder="Type your message here.."/>
                                    {Object.keys(formErrors).length > 0 && formErrors.message ? <span className="text-danger">{formErrors.message}</span> : ''}
                                </div>
                                <div className="controls">
                                  {isSubmitting ? <SubmittingLoadingImg /> : ''}
                                    <button type="submit" disabled={isSubmitting} className="btn btn-success register-btn">Send</button>
                                </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
          <div className="col-md-12">
            {callPosts.length > 0 && callPosts.map((post,index) => {
              return(
            <div className="post_box" key={index}>
              <div className="row">
                <div className="col-md-7">
                  <div className="tb-row">
                    <div className="tb-cell">
                      <img src={post.teacher_photo} />
                    </div>
                      <div className="tb-cell text-left pd-left">
                        <p>{post.created_date}</p>
                        <p><a href="#teacher"><strong>{post.teacher_name}</strong></a></p>
                      </div>
                  </div>
                </div>
                <div className="col-md-5 text-right">
                  <button onClick={(e)=>handelEditPostModal(e,post.id,post.post_message)} className="btn btn-warning mr-1">Edit Post</button>
                  <button onClick={(e)=>deleteTeacherPost(e,post.id)} className="btn btn-danger">Delete Post</button>
                </div>
              </div>
              <div className="col-md-12">
                <p>{post.post_message}</p>
              </div>
              <hr />
              <div className="col-md-12">
                {post.comments.length ? post.comments.map((comment,index2)=>{
                  return (
                      <div key={index2} className="card card-body post-comment mb-2">
                    <p><img className="img rounded-circle" src={comment.photo} alt="user photo" height={40} width={40} /> <strong>{comment.is_current_user_comment ? 'You' : comment.student_name}</strong> <span>{comment.created_date}</span></p>
                    <p className="mb-0">{comment.comment}</p>
                    </div>
                  )
                }):''}
              </div>
            </div>
            )
          }) }
          </div>
        </div>
                </div>
            </div>
            : <LoadingIcon />}
        </div>
        <Modal dialogClassName="profile-child-modal" show={isOpenEditPost} onHide={()=>{setIsOpenEditPost(false)}} >
        <Modal.Header closeButton>
        <div className="img-box  align-items-center">
            <img src="/assets/img/icon-logo.svg" />
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="form-box">
            <h3>Edit Post</h3>
            <div className="row">
              <div className="col-md-12">
                <form className="form-horizontal login_model" onSubmit={handleUpdatePost} >
                  <fieldset>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="controls">
                            <textarea name="post_message" onChange={onchangeEditPost} value={editedPostMessage} className="form-control text-area" placeholder="Write your Post here." />
                          </div>
                          {formErrors.editedPostError && <p className="text-danger">{formErrors.editedPostError}</p>}
                        </div>
                      </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <button id="confirmsignup" disabled={isSubmittingModal} type="submit" name="confirmsignup" className="btn btn-success register-btn">Update Post</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <button type="button" onClick={()=>setIsOpenEditPost(false)} className="btn btn-success register-btn clr_change">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
        </>
    )
}