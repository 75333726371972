import React from 'react'
import '../../../../css/friends-group.css'
export default function FriendsBanner() {
    return (
      <>
      <section className="bnr_section teacher_bnr_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Friend Groups</h1>
                <p>Attending {process.env.REACT_APP_CLASS_HELPER}s is more fun with friends! Create Squads with friends that
                  <br/>
                  share similar interests and we can send them an invite when you want us to.
                </p>
              </div>
            </div>
          </div>
        </section> 
      </>
    );
}
