import React,{useEffect,useState,useContext} from "react";
import GlobalContext from "../../../GlobalContext";
import parse from 'html-react-parser';
import Axios from "axios";
import HeaderTextBanner from "../../layout/HeaderTextBanner";
import LoadingIcon from '../../helpers/LoadingIcon'

function About() {
  const [pageLoaded,setPageLoaded] = useState(false);
  const [cmsPage,setCmsPage] = useState([]);
  const [cmsBlocks,setCmsBlocks] = useState([]);
  const [cmsIcons,setCmsIcons] = useState([]);

  const {setIsInnerPage} = useContext(GlobalContext) 
  setIsInnerPage(false)
  

useEffect(() => {
  getCmsPage();
}, []);
async function getCmsPage()
  {
    const res = await Axios.get('cms/get-cms-page',{params:{cms_slug:'about'}});
    if(res.data.status && res.data.status == 'success'){
      setCmsPage(res.data.data?res.data.data:[]); 
      setCmsBlocks(res.data.data.cms_blocks);
      setCmsIcons(res.data.data.icons);
      setPageLoaded(true);
    }
  }

  return (
    <>
      <HeaderTextBanner heading={cmsPage.cms_title?cmsPage.cms_title : ''} />
      <section className="cms-sec">
        {pageLoaded ? 
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {cmsBlocks.length ? cmsBlocks.map((block,index)=>{
                let blockClass = 'color_two';
                if(index % 2 == 0){
                  blockClass = 'color_one';
                }
                return (
                <div className={'text-block '+blockClass}>
                  <h2>{block.heading}</h2>
                  {parse(block.content)}
                </div>
                )
              }) : ''}
            </div>
            <div className="col-md-12">
              <div className="text-block color_two">
                <div className="row text-center learn_sec">
                  <div className="col-md-3">
                    <div className="circle-img color-one">
                      <img src="assets/img/sale.png" />
                    </div>
                      <h3>{cmsIcons.block1_title}</h3>
                      <p> {cmsIcons.block1_content}</p>
                  </div>
                  <div className="col-md-3">
                    <div className="circle-img color-two">
                      <img src="assets/img/question.png" />
                    </div>
                    <h3>{cmsIcons.block2_title}</h3>
                      <p> {cmsIcons.block2_content}</p>
                  </div>
                  <div className="col-md-3">
                    <div className="circle-img color-four">
                      <img src="assets/img/teacher.png" />
                    </div>
                    <h3>{cmsIcons.block3_title}</h3>
                      <p> {cmsIcons.block3_content}</p>
                  </div>
                  <div className="col-md-3">
                    <div className="circle-img color-three">
                      <img src="assets/img/school.png" />
                    </div>
                    <h3>{cmsIcons.block4_title}</h3>
                      <p>{cmsIcons.block4_content}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : <LoadingIcon /> }
      </section>
      </>
  );
}
export default About;
