import React from 'react';
import axios from 'axios';



export async function setSocialLogin(source,userInfo)
{
    const res = await axios.post('user/social-login',userInfo).then(response=>{
            return response
    }).catch(err=>{
        return false;
        console.log(err);
        alert('Error occred while login.');
    });
    return res;
}