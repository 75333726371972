import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios';
import { Link } from "react-router-dom";
import {GetStringForUrl} from '../../../helpers/StringHelper';
import LoadingIcon from '../../../helpers/LoadingIcon'
import { siteName } from '../../../auth/Athentication';

export default function TeacherScheduleTab(props) {

  // const events = [{ title: "today's event", date: '2020-08-06' }];
  const [events, setEvents] = useState([]);
  const [show,setShow]= useState(false);
  const [isLoaded,setIsLoaded]= useState(false);
    const [isFetching,setIsFetching]= useState(false);
    const [meetingInfo,setMeetingInfo]= useState({});
    const [meetingId,setMeetingId]= useState();
  
  useEffect(() => {
    axios.get('teacher/schedule').then(res=>{      
      setEvents(res.data.data);
      setIsLoaded(true)
    });
  },[]);

  useEffect(() => {
    setIsFetching(false);
  },[meetingInfo]);
  
  useEffect(() => {
    if(!meetingId) return
    axios.get('user/schedule-meeting-info',{params:meetingId}).then(res=>{      
      setMeetingInfo(res.data.data);
    });
  },[meetingId]);

  const renderEventContent = (eventInfo) => {
    return (
           <div className={eventInfo.event.extendedProps.is_favorited == 'yes' ? 'fc-event-content text-favcolor':'fc-event-content'}>
             <div className="fc-event-id opacity-0">{eventInfo.event.id}</div>
             <div className="fc-event-time d-block">{eventInfo.timeText}</div>
             <div className="fc-event-title">{eventInfo.event.title}</div>
           </div>
    )
};

  const handelEventOnclick = ({ event, el }) =>{
    const id = event.id //id
    setIsFetching(true);
    setMeetingId({meeting_id:id});
    setShow(true);
  }

  return (
      <div role="tabpanel" id="four">
       { isLoaded ?
         <>
        <div id="calendar" />
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            eventClick={handelEventOnclick}
            eventContent={renderEventContent}
            events={events}
            eventTimeFormat= {{ 
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true }}
          />
      </> : <LoadingIcon /> }

<Modal show={show} onHide={()=>{setShow(false)}} size="md" className="schedule_model">
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className="singup-form-div">
                <div className="form-box flyer_popup">
                  {isFetching ? <LoadingIcon/> : 
                    meetingInfo && Object.keys(meetingInfo).length ? 
                      <div className="row">
                        <div className="col-md-12 mt-0">
                          <div className="row">
                            <div className="col-md-4 pl-0">
                            <Link to={"/class-details/"+GetStringForUrl(meetingInfo.title)+'/'+meetingInfo.uuid}><img src={meetingInfo.classPhotoSrc} className="img-thumbnail" alt={meetingInfo.title}/></Link>
                            </div>
                            <div className="col-md-8 pl-0">
                            <Link to={"/class-details/"+GetStringForUrl(meetingInfo.title)+'/'+meetingInfo.uuid}><h3>{meetingInfo.title}</h3></Link>
                            <p className="modal-short-desc">{meetingInfo.short_description}</p>
                            </div>
                            <div className="w-100 p-3">
                            <div className="row">
                              <ul className="class-table-ul calendar-modal-ul">
                              <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-users-social" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Enrolls <span>{meetingInfo.enrolled_count?meetingInfo.enrolled_count+' Learners':'Empty'}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-ui-clock" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Meeting Time <span>{meetingInfo.meeting_time}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-user" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>{process.env.REACT_APP_TEACHER_HELPER} <span><Link to={'/teachers/'+meetingInfo.teacher_unique_id}>{meetingInfo.teacher_name}</Link></span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-people" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Age Range <span>{parseInt(meetingInfo.age_range_start) > 19 ? 'Parents' :meetingInfo.age_range_start+'-'+meetingInfo.age_range_end+'Yrs'}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-mic" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p className="euqal-p">{process.env.REACT_APP_CLASS_HELPER} is taught <span>{meetingInfo.language_name}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-dollar" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Price Per Learner 
                                          <span>{meetingInfo.pricing_type == 'Free'? 'Included at no cost': 
                                          <>
                                          {meetingInfo.pricing_type == 'Discounted' ? <span className="cross">${meetingInfo.actual_price}</span>:''} ${meetingInfo.price}
                                          </>}
                                          </span>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-users-social" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>{process.env.REACT_APP_CLASS_HELPER} Size <span>{meetingInfo.min_guests+'-'+meetingInfo.max_guests} Kids</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-headphone-alt-1" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Call Length <span> {meetingInfo.length_name}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-network-tower" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p className="euqal-p">{process.env.REACT_APP_CLASS_HELPER} Frequency <span>{meetingInfo.no_of_weeks} Week{parseInt(meetingInfo.no_of_weeks)>1?'s':''}, {meetingInfo.calls_in_week}x Week</span></p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </div>
                            <div className="w-100 text-center mb-2">
                              {meetingInfo.zoom_link && meetingInfo.is_expired == false ?
                              <Link to={meetingInfo.zoom_link} target="_blank" className="get_button request_btn" >Schedule a Zoom meeting</Link>
                              :''}
                              {meetingInfo.is_expired == true ? 
                              <a href="#" onClick={(e)=>{e.preventDefault()}} className="get_button request_btn">Meeting has ended</a> : ''
                              }
                            </div>
                            <div className="w-100 text-center">
                              <Link onClick={()=>{localStorage.setItem('sectionAcitveTab','ClassRoom')}} className="get_button request_btn" to={"/section/"+meetingInfo.class_uid+'/'+meetingInfo.section_uid}>View</Link>
                              <Link className="get_button request_btn ml-2" to={"/class/edit/"+meetingInfo.uuid}>Edit</Link>
                            </div>
                          </div>
                        </div>
                    </div>
                    :''
                  }
                </div>
            </div>
            </Modal.Body>
      </Modal>
      </div>
    );
    
   
}
