import React, { useState,useEffect,useContext } from "react";
import HeaderTextBanner from '../../layout/HeaderTextBanner';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import GlobalContext from "../../../GlobalContext";
export default function ConvertToTeacher(props) {
  
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [inputs,setInputs] = useState({old_password:'',password:'',password_confirmation:''});
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [resume, setResume] = useState({});          
  const [successMessage,setSuccessMessage] = useState(null);
  const [errorMsgBlock, setErrorMsgBlock] = useState({});  
  const { register,handleSubmit,clearErrors,setError,errors } = useForm();
        const [formErrors,setFormErrors] = useState(null);
        const formdata = new FormData();
          //for form inputs 
     const handleInputChange = async (event) => {
      event.persist();
      const input = event.target; 
      if(input.type == 'file'){
        const file = input.files[0];
        const fileSize = Math.round((file.size / 1024)); 
        if(input.name == 'resume'){
            var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
            if(!allowedExtensions.exec(input.value)){
                setError("resume",{type:"manual",message:"File extension should be PDF,DOC or DOCX."});
                return false;
            }else if(fileSize >= 2084){
                setError("resume",{type:"manual",message:"Resume should not exceeds 2MB."});
                return false;
            }else{
                clearErrors(['resume']);
            }
            setResume({file:file,name:file.name});
            }
        }else{
          setInputs(inputs => ({...inputs, [input.name]: input.value}));
        }
    };
  //submission
const formSubmit = (data)=>{

  setIsSubmitting(true);
  for ( var key in inputs ) {
    formdata.append(key, inputs[key]);
  }
  if(Object.keys(resume).length){
    formdata.append('resume',resume.file,resume.name)
  }

  var post_url ='teacher/convert-to-teacher';
  axios.post(post_url, formdata)
      .then(response => {
          let res = response.data;
          if(res.status=='success'){
              window.scrollTo(0, 0)
              setIsSubmitting(false);
              setSuccessMessage(res.message);
          }else{
              let msg_str ='';
              // console.log(res.message.max_guests[0]);
              Object.keys(res.message).map((ele)=>{
                  res.message[ele].map((msg,key) => {
                      console.log(msg)
                      msg_str += msg+'\n';
                      setIsSubmitting(false);
                      setFormErrors(msg_str);
                      window.scrollTo(0, 0)
                  });
  
              });
              
          }     
      })
      .catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  
  }; 
        return (
          successMessage ? <Redirect 
        to={{pathname:"/teacher-dashboard",state:{message:parse(successMessage)}}} /> :
        <>
        <HeaderTextBanner heading="Convert to Teacher" />
        <section className="signup-sec">
        <div className="container-fluid cf">
          <div className="row signup-form-margin" id="main-content">
            <div className="col-md-12 singup-form-div teach_bg content" id="content">
            <div className="row">
              <div className="col-md-10 m-auto">
              {formErrors ? <div className="alert alert-danger">{formErrors}</div>:''}
                <Form className="form-horizontal teacher_form mb-3" onSubmit={handleSubmit(formSubmit)}>
                  {/* Text input*/}
                  <div className="teacher_block box">
                <div className="step-div">
                    { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
                </div>
                <h2>Convert to Teacher</h2>
                {/* Text input*/}
                
                <div className="control-group">
                  <label className="label_info" htmlFor="teacher">Education </label><br clear="all" />
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="education1_school" className="form-control input-large" name="education1_school" type="text" ref={register} onChange={handleInputChange}  placeholder="School/Program (Optional)"  />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="education1_degree" className="form-control input-large" ref={register} onChange={handleInputChange} name="education1_degree" type="text" placeholder="Degree (Optional)"  />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="education1_year" className="form-control input-large" ref={register} onChange={handleInputChange} name="education1_year" type="text" placeholder="Graduation Year (Optional)" />
                      </div>
                    </div>
                  </div>
                  {/* <label class="label_info" for="teacher">Education 2: </label><br clear="all"> */}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="wifi" className="form-control input-large" ref={register} onChange={handleInputChange} name="education2_school" type="text" placeholder="School/Program (Optional)" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="wifi" className="form-control input-large" ref={register} onChange={handleInputChange} name="education2_school_degree" type="text" placeholder="Degree (Optional)" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="controls">
                        <input id="wifi" className="form-control input-large" ref={register} onChange={handleInputChange} name="education2_school_year" type="text" placeholder="Graduation Year (Optional)" />
                      </div>
                    </div>
                  </div>
                </div>
                <h3>Skills and Interests</h3>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">What topics are you passionate about teaching on Actyvatelearning.com and why?</label> <br />
                      <div className="controls">
                        <textarea name="passion"  ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">How do you keep your students engaged and motivated? </label> <br />
                      <div className="controls">
                        <textarea name="engaged_motivated" ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">Describe how you promote diversity, equity, and inclusion in your classes? </label> <br />
                      <div className="controls">
                        <textarea name="diversity_equity" ref={register} onChange={handleInputChange} className="form-control text-area" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control-group">
                  <label className="label_info" htmlFor="teacher">Are you currently teaching, Where? (Optional)</label><br clear="all" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="current_school" className="form-control input-large" name="current_school" ref={register} onChange={handleInputChange} type="text" placeholder="Name of school" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="current_school_city" className="form-control input-large" name="current_school_city" ref={register} onChange={handleInputChange} type="text" placeholder="City" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="current_school_state" className="form-control input-large" name="current_school_state" ref={register} onChange={handleInputChange} type="text" placeholder="State" />
                      </div>
                    </div>
                  </div>
                  <label className="label_info" htmlFor="teacher">List of schools previously taught? (Optional)</label><br clear="all" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="previous_school1" className="form-control input-large" name="previous_school1" ref={register} onChange={handleInputChange} type="text" placeholder="Name of school" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school1_city" className="form-control input-large" name="previous_school1_city" ref={register} onChange={handleInputChange}  type="text" placeholder="City"  />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school1_state" className="form-control input-large" name="previous_school1_state" ref={register} onChange={handleInputChange}  type="text" placeholder="State" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="controls">
                        <input id="previous_school2" className="form-control input-large" name="previous_school2" ref={register} onChange={handleInputChange} type="text" placeholder="Name of school" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school2_city" className="form-control input-large" name="previous_school2_city" ref={register} onChange={handleInputChange} type="text" placeholder="City" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="controls">
                        <input id="previous_school2_state" className="form-control input-large" name="previous_school2_state" ref={register} onChange={handleInputChange} type="text" placeholder="State" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="label_info" htmlFor="teacher">Resume</label> <br />
                      <div className="controls">
                        <input id="resume" name="resume" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large resume-input" type="file"  />
                        {errors.resume && <p className="text-danger">{errors.resume.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="control-group">
                        {isSubmitting && <span>Please wait the form is submitting...</span>}
                    <div className="controls">
                      <button id="confirmsignup" name="confirmsignup" disabled={isSubmitting} className="btn btn-success register-btn">Submit Application</button>
                    </div>
                  </div>
                </div>
              </div>
                </Form>
              </div>
            </div>
          </div>
          </div>
          </div>
        </section>
        </>
      );
    } 