import React, { useState,useContext,useEffect } from "react";
import Axios from 'axios'
import {Link,withRouter } from 'react-router-dom'
import {setUserInfo,checkRememberMe,remembered_email,remembered_password,clearRememberMeData,setRememberMeData} from '../auth/Athentication'
import {getUserFavorites} from '../api/FavoritesApi'
import {setSocialLogin} from '../auth/SocialLogin'
import GlobalContext from "../../GlobalContext";
import SubmittingLoadingImg from '../helpers/SubmittingLoadingImg'
//google
import { GoogleLogin } from 'react-google-login';

//facebook
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
//Apple
import AppleLogin from 'react-apple-login'


function LoginModel(props) {
    const [email, setEmail] = useState(remembered_email());
    const [password, setPassword] = useState(remembered_password());
    const [formErrors, setFormErrors] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
    const [socialErrors, setSocialErrors] = useState(null);
    const {setLoggedIn,setLoginModelOpen,loginModelOpen,setIsModelBackdropOpen,setMyFavorites} = useContext(GlobalContext)

    //GOOGLE
    const responseOnSuccessGoogle = async (response) => {
      console.log(response);
      var id_token = response.getAuthResponse().id_token;
      const userProfile = response.profileObj;
      const userInfo ={
        first_name:userProfile.familyName,
        last_name:userProfile.givenName,
        social_token:id_token,
        email:userProfile.email,
        image:userProfile.imageUrl,
        source:'gmail',
      };
      const loginRes = await setSocialLogin('gmail',userInfo);
      if(loginRes){
        handleLoginResponse(loginRes,'gmail'); //respose, isSocailLogin
      }
    }

    useEffect(() => {
      if(checkRememberMe()){
        setIsRememberMeChecked(true);
      }
    }, [])

    const responseFacebook = async (response) => {
      console.log(response);
      if(response.accessToken){
        const userInfo ={
          first_name:response.name,
          social_token:response.accessToken,
          email:response.email,
          image:response.picture.data.url,
          userID:response.userID,
          source:'facebook',
        };
        const loginRes = await setSocialLogin('facebook',userInfo);
        if(loginRes){
          handleLoginResponse(loginRes,'facebook'); //respose, isSocailLogin
        }

      }else{
        alert('Could not login with facebook.')
      }
    }

    const responseOnErrorGoogle = (response) => {
      setSocialErrors('Error occure while login from G-mail');
    }

    async function handleLoginSubmit(e) { 
      setIsSubmitting(true);
        e.preventDefault();
        setFormErrors(null)
        try { 
            const response = await Axios.post(
              "/login",
              { email, password }
            );  
            if(isRememberMeChecked){
              setRememberMeData(email,password)
            }else{
              clearRememberMeData()
            }
            handleLoginResponse(response);
        }catch (e) {
          setIsSubmitting(false);
            alert('there was a problem');
         }
    }
    async function getMyFavorites()
    {
      const favorites = await getUserFavorites();
      setMyFavorites(favorites);
    }
    function update_initial_login(){
      Axios.post('user/update-initial_login');
    }

    function handleLoginResponse(response,isSocialLogin=null)
    {
      console.log(response);
      setIsSubmitting(false)
      if (response.data.status=='success') {
        setUserInfo(response.data.user);
        setLoggedIn(true)
        getMyFavorites();
        if(isSocialLogin){
          localStorage.setItem('socialLogin',true);
        }
        closeLoginModel();
        if(response.data.user.initial_login == '0'){
          update_initial_login();
          props.history.push("/change-password")
        }
        if(response.data.user.user_type == 'teacher'){
          props.history.push("/teacher-dashboard")
        }else{
          props.history.push("/user-dashboard")
        }

      } else {
        var msg_str ='';
        Object.keys(response.data.message).map((ele)=>{
          response.data.message[ele].map((msg,key) => {
              console.log(msg)
              msg_str += msg+'\n';
              console.log("Incorrect username / password.")
              setFormErrors(msg_str);
            });
          });
      }
    }

    function reponseAppleLogin(response)
    {
      console.log(response)
    }
    function closeLoginModel() { 
        setLoginModelOpen(false);
        setIsModelBackdropOpen(false);
    }
     return (
       <>
         <div
           className={
             "modal fade model_" +
             (loginModelOpen == true ? "show" : "hide")
           }
           id="loginModel"
           tabIndex={-1}
           role="dialog"
           aria-labelledby="loginModelLabel"
           aria-hidden="true"
         >
           <div className="modal-dialog login_model" role="document">
             <div className="modal-content">
               <div className="modal-header">
                 <div className="img-box  align-items-center">
                   <img src="/assets/img/icon-logo.svg" />
                 </div>
                 <button
                   type="button"
                   className="close"
                   data-dismiss="modal"
                   aria-label="Close"
                   onClick={closeLoginModel}
                 >
                   <span aria-hidden="true">×</span>
                 </button>
               </div>
               <div className="modal-body singup-form-div">
                 <div className="form-box">
                   <h3>Login</h3>
                      {formErrors?<div className="alert alert-danger">{formErrors}</div> : ''}
                   <div className="row">
                     <div className="col-md-6">
                       <form
                         className="form-horizontal login_model"
                         onSubmit={handleLoginSubmit}
                       >
                         <fieldset>
                           {/* Sign Up Form */}
                           {/* Text input*/}
                           <div className="control-group">
                             <div className="row">
                               <div className="col-md-12">
                                 <div className="controls">
                                   <input
                                     onChange={(e) =>
                                       setEmail(e.target.value)
                                     }
                                     id="email"
                                     name="email"
                                     className="form-control input-large"
                                     type="text"
                                     defaultValue={remembered_email()}
                                     placeholder="Username / Email"
                                    required
                                    autoFocus
                                   />
                                   <img src="/assets/img/form-user.svg" />
                                 </div>
                               </div>
                             </div>
                           </div>
                           {/* Password input*/}
                           <div className="control-group">
                             <div className="controls">
                               <input
                                 onChange={(e) => setPassword(e.target.value)}
                                 id="password"
                                 name="password"
                                 defaultValue={remembered_password()}
                                 className="form-control input-large"
                                 type="password"
                                 placeholder="Password"
                                 required
                               />
                               <img src="/assets/img/form-password.svg" />
                               <span>
                                 <Link onClick={closeLoginModel} to="/forgot-password">Forgot your password?</Link>
                               </span>
                             </div>
                           </div>
                           {/* Terms input*/}
                           <div className="control-group">
                             <div className="controls">
                               <label
                                 className="radio inline rememberme"
                                 htmlFor="rememberme"
                               >
                                 <input
                                   type="checkbox"
                                   name="rememberme"
                                   value="rememberme"
                                   checked={isRememberMeChecked}
                                   id="rememberme"
                                   onChange={() => setIsRememberMeChecked(!isRememberMeChecked)}
                                 />
                                 &nbsp; Remember Me
                               </label>
                             </div>
                           </div>
                           <div className="row align-items-center">
                             <div className="col-md-12">
                               {isSubmitting ? <SubmittingLoadingImg width={50} /> : ''}
                               <div className="control-group">
                                 <div className="controls">
                                   <button
                                     id="confirmsignup"
                                     name="confirmsignup"
                                     className="btn btn-success register-btn"
                                   >
                                     Login
                                   </button>
                                 </div>
                               </div>
                             </div>
                             <div className="col-md-12 social-sec text-center">
                               <p>OR</p>
                                    {socialErrors && <div className="alert alert-danger">{socialErrors}</div>}
                               <ul className="login-form-media row">
                                 <li className="col">
                                   <FacebookLogin
                                      appId="3421237811286363"
                                      callback={responseFacebook}
                                      render={renderProps => (
                                        <a className="facebook1" onClick={(event)=>{event.preventDefault();renderProps.onClick()}}><img src="/assets/img/facebook-1.svg" /></a>
                                      )}
                                    />
                                 </li>
                                 <li className="col">
                                     <GoogleLogin
    clientId="1024650581664-b8i0qelr5r0h2geivqp958rvtfuuv3eo.apps.googleusercontent.com"
    render={renderProps => (
      <a href="#" onClick={(event)=>{event.preventDefault();renderProps.onClick();}} className="google" disabled={renderProps.disabled}><img src="/assets/img/google.svg" /></a>
    )}
    buttonText="Google"
    onSuccess={responseOnSuccessGoogle}
    onFailure={responseOnErrorGoogle}
    cookiePolicy={'single_host_origin'}
  />
                                 </li>
                                 <li className="col">
                                   <AppleLogin  
                                      clientId={"showmacktest@gmail.com"} 
                                      redirectURI={"https://localhost:3000"}   
                                      responseType={"code"} 
                                      responseMode={"query"}  
                                      callback={reponseAppleLogin}
                                      designProp={
                                          {
                                          height: 30,
                                          width: 140,
                                          color: "black",
                                          border: false,
                                          type: "sign-in",
                                          border_radius: 15,
                                          scale: 1,
                                          locale: "en_US", 
                                        }
                                      }
                                      />
                                 </li>
                               </ul>
                             </div>
                           </div>
                         </fieldset>
                       </form>
                     </div>
                     <div className="col-md-6">
                       <img
                         src="/assets/img/rightt-img.jpg"
                         className="side-img"
                       />
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </>
     );
}
 export default withRouter(LoginModel);