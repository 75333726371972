import React, { useState,useEffect,useContext } from "react";
import { useForm,Controller  } from "react-hook-form";
import axios from 'axios';
import {Link} from 'react-router-dom'
import { useHistory } from "react-router-dom";
import GlobalContext from "../../../../GlobalContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";

import '../../../../css/about.css'

export default function AgeSelection() {
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
    let history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState(false);  
    const [formErrors, setFormErrors] = useState();      
    const { register,handleSubmit,errors,control } = useForm();
    const startDate= new Date(new Date().setFullYear(new Date().getFullYear() - 20));
    const endDate= new Date(new Date().setFullYear(new Date().getFullYear() - 3));
    
    useEffect(() => {
        getChildrens();
    },[]);
    const ages = [];
    for (let i = 3; i < 20; i++) {
        ages.push(i);
    }  
    async function getChildrens(){
        //  const response = await Axios.get('user/childrens');
        // if(typeof(response.data.status) !='undefined' && response.data.status == 'success'){
        //     setChildAges(response.data.data);
        // }
    }


    
//submission
const formSubmit = (data)=>{
console.log(data);
  setIsSubmitting(true);
  
  axios.post('user/save-child', data)
  .then(response => {
    let res = response.data;
    if(res.status && res.status == 'success'){
        history.push("/user-profile");
    }else{
        setIsSubmitting(false);
        let msg_str ='';
        if(typeof(res.message)== 'string'){
            setFormErrors(res.message);
        }else{ 
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'\n';
                    setFormErrors(msg_str);
                });

            });
    }
        
    }     
  })
  .catch(error => {
    setIsSubmitting(false);
    console.log(error);
    alert('Internal server error');
  });
  
  };
  console.log("Errors:", errors);
  return (
    <section className="signup-sec">
        <div className="container">
          <div className="row signup-form-margin">
            <div className="col-md-8 offset-2 singup-form-div">
                { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
              <div className="form-box">
                <h1>Tell us about your child</h1>
                {/* <h2>The information you share below will help us recommend the right classes for your kiddo!</h2> */}
                <form className="form-horizontal" onSubmit={handleSubmit(formSubmit)}>
                  <fieldset>
                    <div className="row age_form">
                       <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">First Name </label>
                            <input type="text" name="first_name" ref={register({required:'This field is required.'})} className="form-control" />
                            {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>} 
                          </div>
                       </div>
                       <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Last Name (Optional) </label>
                            <input type="text" name="last_name" ref={register} className="form-control" />
                            {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>} 
                          </div>
                       </div>
                    </div>
                    <div className="form-group age_form">
                      <label htmlFor="">Date of Birth</label>
                      <Controller
                        control={control}
                        name="date_of_birth"
                        rules={{ required: true }}
                        render={({ onChange, onBlur, value }) => (
                          <DatePicker
                            onChange={onChange}
                            dateFormat="MM/yyyy"
                            onBlur={onBlur}
                            className="form-control"
                            maxDate={endDate}
                            minDate={startDate}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            selected={value}
                          />
                        )}
                      />
                        {errors.date_of_birth && <p className="text-danger">This field is required.</p>}                         
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                          <div className="controls">
                            <Link to="/user-dashboard" className="btn btn-success1 register-btn btn_block">Skip for Now</Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="controls text-right">
                            <button type="submit" disabled={isSubmitting} className="btn btn-success1 register-btn btn_block">Save and continue</button>
                          </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
