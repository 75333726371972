import React from 'react'

export default function WelcomeTeacherBanner(props) {
  const title = props.heading
    return (
      <>
      <section className="teach_hero_bnr">
        <div className="overflow" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{title}</h1>
              <div className="controls">
                <a onClick={()=>props.updateCurrentTab('teacherTab')} className="btn register-btn upload_video clr_orange tablinks">Apply to Host</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}
