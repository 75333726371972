import React from 'react'
import {IsAuthenticated} from '../auth/Athentication'
import EmailConfirmationWarning from '../layout/alert/EmailConfirmationWarning'
export default function HeaderTextBanner(props) {
    return (
      <>
        {IsAuthenticated() ?  <EmailConfirmationWarning /> : <></> }
        <section className="bnr_section teacher_bnr_sec">
            <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                <div className="teacher-profile">
                    <h1>{props.heading}</h1>
                </div>
                </div>
            </div>
            </div>
        </section>
      </>
    );
}
