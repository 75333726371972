import React from 'react'


export default function TestimonialItem(props)
{
    const testimony = props.item;

    return (
        <div className="feedback-text">
            <h4>
                <img src={testimony.photo} /> {testimony.message}
            </h4>
            <h5>- {testimony.fullname}</h5>
        </div>
    )
}