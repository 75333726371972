import React from 'react'

export default function Faq(props) {
  const faqs =props.faqs;
    return (
      <>
        <div className="row">
        <div className="col-md-12 why">
        {faqs && Object.keys(faqs).length ? <h2>FAQ</h2> : ''}
        </div>
        {faqs && Object.keys(faqs).length ? 
        faqs.map((faq,index)=>{
          return (
            <div key={index} className="col-md-12 faq_sec">
              <div className="email_row1">
          <p><strong><i className="icofont-question-circle" /> {faq.question}</strong></p>
          <p>{faq.answer}</p>
              </div>
            </div>
          )
        }) : '' }
        
      </div>
      </>
    );
}
