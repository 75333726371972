import React from "react";
import parse from 'html-react-parser';

function Welcome(props) {
  const cms = props.cms;
  return (
    <>
      <section className="welcome_section">
        <div className="container-fluid cf">
          <div className="row learn_sec">
            <div className="col-md-12">
              {parse(cms.homepage_heading)}
            </div>
          </div>
          <div className="row text-center learn_sec">
            <div className="col-md-3">
              <div className="circle-img color-one">
              <img src="assets/img/group1.svg" />
              </div>
              {parse(cms.block1_title)}
              {parse(cms.block1_content)}
            </div>
            <div className="col-md-3">
              <div className="circle-img color-two">
                <img src="assets/img/teacher.png" />
              </div>
              {parse(cms.block2_title)}
              {parse(cms.block2_content)}
            </div>
            <div className="col-md-3">
              <div className="circle-img color-four">
              <img src="assets/img/personal.png" />
              </div>
              {parse(cms.block3_title)}
              {parse(cms.block3_content)}
            </div>
            <div className="col-md-3">
              <div className="circle-img color-three">
              <img src="assets/img/portfolio.svg" />
              </div>
              {parse(cms.block4_title)}
              {parse(cms.block4_content)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
