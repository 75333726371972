import React, { useState,useEffect,useContext } from "react";
import HeaderTextBanner from '../layout/HeaderTextBanner';
import axios from 'axios';
import { Link,useHistory } from "react-router-dom";
import {UserRole} from '../auth/Athentication'
import {ResendPasswordLink} from '../api/ForgotPasswordApi'
import PasswordStrengthBar from 'react-password-strength-bar';
import { useForm } from "react-hook-form";
import SubmittingLoadingImg from '../helpers/SubmittingLoadingImg'
import { Form } from 'react-bootstrap';
import GlobalContext from "../../GlobalContext";
import Success from '../layout/alert/Success'
export default function ChangePassword(props) {
  
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  let history = useHistory();
  const [inputs,setInputs] = useState({old_password:'',password:'',password_confirmation:''});
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const [successMessage,setSuccessMessage] = useState(null);
  const [alertShow,setAlertShow] = useState(false);
  const redirectURL = UserRole() == 'teacher' ? '/teacher-dashboard':'/user-settings'; 
  const { register,handleSubmit,clearErrors,watch,setError,errors } = useForm();
        const [formErrors,setFormErrors] = useState(null);
        const formdata = new FormData();
          //for form inputs 
     const handleInputChange = async (event) => {
      event.persist();
      const input = event.target; 
          setInputs(inputs => ({...inputs, [input.name]: input.value}));
     };

     useEffect(() => {
       if(!alertShow) return;

      setTimeout(function(){
        history.push(redirectURL)
      },3000);

      },[alertShow]);

      const handleReqSetNewPassword = async (event) =>{
        event.preventDefault();
        const res = await ResendPasswordLink();
        if(res == 'success'){
          setAlertShow(true);
          setSuccessMessage('Password link send to your email.');
          setIsSubmitted(true)
        }
      }


  //submission
const formSubmit = (data)=>{
  setSuccessMessage(null)
  if(inputs.password !=inputs.password_confirmation){
    setError("password_confirmation",{type:"manual",message:"Password and confirm password should be match."});
    return false;
  }else{
    clearErrors(['password_confirmation']);
  }

  setIsSubmitting(true);
  for ( var key in inputs ) {
    formdata.append(key, inputs[key]);
  }
  var post_url ='user/update-password';
  axios.post(post_url, formdata)
      .then(response => {
          let res = response.data;
          if(res.status=='success'){
              window.scrollTo(0, 0)
              setAlertShow(true)
              setIsSubmitting(false);
              setSuccessMessage(res.message);
              setIsSubmitted(true);
          }else{
              let msg_str ='';
              // console.log(res.message.max_guests[0]);
              Object.keys(res.message).map((ele)=>{
                  res.message[ele].map((msg,key) => {
                      console.log(msg)
                      msg_str += msg+'\n';
                      setIsSubmitting(false);
                      setFormErrors(msg_str);
                      window.scrollTo(0, 0)
                  });
  
              });
              
          }     
      })
      .catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  
  }; 
        return (
          <>
          { isSubmitted ? <Success show={alertShow} message={successMessage} setAlertShow={setAlertShow} /> : ''}
        <HeaderTextBanner heading="Change password" />
        <section className="user-dashboard">
          <div className="container-fluid cf">
            <div className="row">
              <div className="col-md-6 offset-3">
              {formErrors ? <div className="alert alert-danger">{formErrors}</div>:''}
                <Form className="form-horizontal teacher_form change-pwd-from" onSubmit={handleSubmit(formSubmit)}>
                  {/* Text input*/}
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="controls">
                          <input id="old_password" name="old_password" onChange={handleInputChange} ref={register({required:'This field is required.'})} className="form-control input-large" type="password" placeholder="Current password" />
                              {errors.old_password && <p className="text-danger">{errors.old_password.message}</p>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="controls">
                          <input id="password" name="password" onChange={handleInputChange} ref={register({required:'This field is required.'})} className="form-control input-large" type="password" placeholder="New password" />
                        <PasswordStrengthBar password={watch("password")} barColors={['#ddd', '#ef4836', '#2b90ef', '#2b90ef', '#25c281']} minLength={6} scoreWords={['weak','weak','good','good', 'strong']} />
                              {errors.password && <p className="text-danger">{errors.password.message}</p>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="controls">
                          <input id="confirm_password" onChange={handleInputChange} ref={register({required:'This field is required.'})} name="password_confirmation" className="form-control input-large" type="password" placeholder="Enter your new password again to confirm" />
                              {errors.password_confirmation && <p className="text-danger">{errors.password_confirmation.message}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p><Link to="#" onClick={handleReqSetNewPassword}>Request to set new password</Link></p>
                  </div>
                  {isSubmitting ? <SubmittingLoadingImg /> : ''}
                  <div className="control-group">
                    <div className="controls">
                      <button id="confirmsignup"  type="submit" name="confirmsignup" className="btn btn-success register-btn">Update password</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        </>
        )
    } 