import axios from 'axios';

export async function getUserFavorites()
{
    const res = await axios.get('user/favorite-classes').catch(err=>{
        return {data:{data:[]}};
    });
    return res.data.data;
}

export async function addToFavorites(classId)
{
     const res =  await axios.post('user/add-class-to-favorite',{call_id:classId}).then(res=>{
        if(res.data.status =='success'){
            return res.data.data;
        }
    }).catch(err=>{
        return 0;
    });
    return res;
}

export function isFovoriteClass(favorites,id)
{
    if(favorites && Object.keys(favorites).length){
        const value= favorites.find(ele=>ele.call_id == id);
        if(typeof(value) == 'undefined'){
            return false;
        }
            return true;  
        }
    return false; 
}

export async function removeFavorites(callId)
{
    return await axios.post('user/remove-class-from-favorite',{call_id:callId}).then(res=>{
        if(res.data.status =='success'){
            return res.data.data;
        }
        return 0;
    }).catch(err=>{
        return 0;
    });
}