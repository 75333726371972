import React from 'react';
import 'spinkit/spinkit.min.css';

export default function LoadingIcon()
{
    return (
        <div className="sk-chase loader-icon m-auto">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
    );
}