import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import parse from 'html-react-parser';

export default function FlyerPopup(props){
    const content = props.content;
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
return (
        content && content.length ? 
        <Modal show={show} onHide={handleClose} size="lg" className="flyer_model">
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className="singup-form-div">
                <div className="form-box flyer_popup">
                    <div className="row">
                        <div className="col-md-12 mt-20 wel_text">
                        {parse(content)}
                        </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
      </Modal>
    :<></>
);
}