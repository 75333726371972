import React, { useState,useContext } from "react";
import HeaderTextBanner from '../../layout/HeaderTextBanner';
import { Redirect } from "react-router-dom";
import GlobalContext from "../../../GlobalContext";
export default function AddNewClassStep1(props) {
    
    const {setIsInnerPage} = useContext(GlobalContext)
    setIsInnerPage(true)
    const [classTitle, setClassTitle] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
   
    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(classTitle == null){
            alert('Enter '+process.env.REACT_APP_CLASS_HELPER+' Title');
        }else{
            localStorage.setItem('classDefaultTab','ClassFormTab');
            setIsSubmitted(true);
        }
    }
    return(
        isSubmitted ? <Redirect 
        to={"/class/add/" +classTitle} /> :
        <div>
            <HeaderTextBanner heading='Create a new class' />
            <section className="user-dashboard">
                <div className="container-fluid cf">
                    <div className="row">
                        <div className="col-md-6 offset-3">
                        <form className="form-horizontal teacher_form change-pwd-from" onSubmit={handleSubmit}>
                            {/* Text input*/}
                            <div className="control-group">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="controls">
                                    <label className="label_info" htmlFor="classTitle">{process.env.REACT_APP_CLASS_HELPER} title:</label>
                                    <input id="classTitle" name="classTitle" required onChange={e => setClassTitle(e.target.value)} className="form-control input-large" value={classTitle} type="text" placeholder={"Exciting and Descriptive "+process.env.REACT_APP_CLASS_HELPER+" Name"} />
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* Button */}
                            <div className="control-group">
                            <div className="controls">
                                <button type="submit" id="confirmsignup"  name="confirmsignup" className="btn btn-success register-btn">Next</button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </section>    
        </div>
    )
}