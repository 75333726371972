import React,{useState,useEffect,useContext} from "react";
import axios from 'axios';
import Slider from "react-slick";
import EnrollClass from './enroll/EnrollClass';
import Modal from 'react-bootstrap/Modal'
import GlobalContext from '../../../GlobalContext';
import {lcFirst} from '../../helpers/StringHelper'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function AvailableTimes(props){
    const {setLoginModelOpen,loggedIn,setIsModelBackdropOpen} = useContext(GlobalContext)
    const classId = props.class_id;
    const [showDetailsShow, setShowDetailsShow] = useState(false);
    const [sectionInfo, setSectionInfo] = useState({});
    const [EnrollSectionID, setEnrollSectionID] = useState();
    const [timeSlots,setTimeSlots] = useState({});
    const handleDetailsModalClose = () => setShowDetailsShow(false);
    const handleDetailsModalShow = () => setShowDetailsShow(true);
//enroll modal
  const [EnrollModalShow,setEnrollModalShow] = useState(false); 

    useEffect(() => {
        getClassTimeslots();
    }, []);

    const handleEntrollModel = (e,sectionID) =>{
        e.preventDefault();
        if(loggedIn){
            setEnrollSectionID(sectionID)
            setEnrollModalShow(true);
        }else{
            setShowDetailsShow(false)
            setLoginModelOpen(true)
            setIsModelBackdropOpen(true)
        }
    }
    async function getClassTimeslots() {
        const response = await axios.get("class-timeslots",{params:{class_id:classId}});
        if(!response.data.data){
          alert('Invalid Request');
          return;
        }
        setTimeSlots(response.data.data);
    }

    async function showTimeSlotDetails(event,timslot)
    {
        event.preventDefault()
        const response = await axios.get("section-meetings/"+timslot.id);
        if(!response.data.data){
            alert('Invalid Request');
            return;
        }
        const data = response.data.data;
        data.nextMeet = timslot;
        setSectionInfo(data);
        if(data.meetings){
            handleDetailsModalShow();
        }     
    }

    var settings = {
        dots: false,
            rows: 1,
            autoplay: true,
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 4,
            infinite: Object(timeSlots).length > 4,
            arrows:true,  
            speed: 200,
            slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 320,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
            },
            {
                breakpoint: 580,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false },
            },
            {
                breakpoint: 1024,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false },
            },
        ],
      };
    return(
        Object.keys(timeSlots).length ? 
        <div className="row avl_time_sec" key={0} id="Available">
            <div className="col-md-12">
            <h3>Available Times</h3>
            </div>
            <div className="ss-slick price-sec">
            <Slider {...settings}>
                {Object.keys(timeSlots).map((index) =>{
                    const timeSlot = timeSlots[index];
                    return (
                        <div key={index} className="ss-item">
                        <div className="s-class-box text-center">
                        <h3>{timeSlot.section_date}</h3>
                        <p><strong>{timeSlot.class_length}</strong></p>
                        <p>{timeSlot.start_time+'-'+timeSlot.end_time}</p>
                        <p>{timeSlot.timezone_name} <i className="icofont-ui-clock" /></p>
                        <a href="#" onClick={(event)=>{showTimeSlotDetails(event,timeSlot)}} className="show-link">Show Details </a>
                        {parseInt(timeSlot.available_space) < 1 ?
                        <p><span>No more spot(s).</span></p>
                        : parseInt(timeSlot.available_space) < 5 ? <p><span>Only {timeSlot.available_space} spot(s) remaining</span></p> : ''}
                        {parseInt(timeSlot.available_space) == 0 ?
                            <a className="show-link1" onClick={(e)=>e.preventDefault()}>{process.env.REACT_APP_CLASS_HELPER} is full<br/>Request another {lcFirst(process.env.REACT_APP_CLASS_HELPER)}/time</a>
                        :
                        <button className="get_button" onClick={(event)=>handleEntrollModel(event,timeSlot.id)}>Enroll</button>
                        }
                        </div>
                    </div>
                    )
                } )}
            
            </Slider>
        </div>
                <Modal dialogClassName="login_model available_model" show={showDetailsShow} onHide={handleDetailsModalClose}>
                <Modal.Header closeButton>
                    <div className="img-box  align-items-center">
                        <img src='/assets/img/icon-logo.svg'/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h3>Available Times</h3>
                    <p>This {process.env.REACT_APP_TEACHER_HELPER} may live in a different time zone.</p>
                    <div className="row">
                        <div className="col-md-12">
                            <form className="form-horizontal  text-center">
                            <fieldset>
                            {sectionInfo.nextMeet ? 
                                <div className="s-class-box text-center">
                                    <h3>Next on {sectionInfo.nextMeet.section_date?sectionInfo.nextMeet.section_date:''}</h3>
                                    <p><strong>{sectionInfo.nextMeet.class_frequency}</strong></p>
                                    <p>{sectionInfo.nextMeet.start_time+'-'+sectionInfo.nextMeet.end_time}</p>
                                    <p>({sectionInfo.nextMeet.timezone_name})</p>
                                </div>
                                : ''}
                                <div className="hidemeet_class">
                                    {sectionInfo.meetings && sectionInfo.meetings.map((meeting,index)=>{
                                        return (
                                            <div key={index} className="row align-items-center">
                                                <div className="col-md-12 time_p">
                                                <p>{meeting.meeting_time}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                <br />
                                </div>
                                <div className="control-group">
                                <div className="controls">
                                    {parseInt(sectionInfo.available_space) == 0 ?
                            <button className="btn btn-light btn-block" onClick={(e)=>e.preventDefault()}>{process.env.REACT_APP_CLASS_HELPER} is full</button>
                        :
                        <button id="confirmsignup" onClick={(event)=>handleEntrollModel(event,sectionInfo.id)} className="btn btn-success register-btn">Enroll</button>
                        }
                                </div>
                                </div>
                            </fieldset>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
      </Modal>
      {EnrollSectionID ? <EnrollClass key={EnrollSectionID} EnrollModalShow={EnrollModalShow} EnrollSectionID={EnrollSectionID} setEnrollModalShow={setEnrollModalShow} /> : ''}                                    
        </div>
        :<>No meetings found.</>

    ); 
}
    
export default AvailableTimes;