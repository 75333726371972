import React,{effect} from "react";
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import {lcFirst} from '../../helpers/StringHelper'


function HeaderHero(props) {
  const heading = props.heading;
  const content = props.content;
  const vimeoID = props.vimeo_id; 
  const vimeoVideoSrc = 'https://player.vimeo.com/video/'+vimeoID+'?background=1&autoplay=1&loop=1&byline=0&title=0';
 
  return (
    <section className="slider-section align-items-center">
      <div className="vimeo-wrapper">
        <iframe
          src={vimeoVideoSrc}
          frameBorder="0"
          allow="autoplay; fullscreen"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen={true}
        ></iframe>
      </div>
      <div className="container-fluid cf">
        <div className="row">
          <div className="col-md-12">
            {parse(heading)}
            {parse(content)}
            <Link
              to={'/search'}
              data-toggle="modal"
              data-target="#getModal"
              className="get_button"
            >
              Explore our {lcFirst(process.env.REACT_APP_CLASS_HELPER)}s
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeaderHero;
