import React from "react";

function BlogGrid() {
    return (
      <section className="trending_section blog-sec">
        <div className="container-fluid cf">
          <div className="row">
            {/* Blog-1 */}
            <div className="col-md-4">
              <div className="blog">
                <img src="assets/img/blog1.jpg" className="img-fluid" />
                <div className="blog_info">
                  <h3>Lorem Ipsum is simply dummy text... </h3>
                  <p>
                    <span>BY Admin-1 / FEB 10, 2020</span>
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <a href="javascript:void(0);" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/* Blog-1 */}
            {/* Blog-2 */}
            <div className="col-md-4">
              <div className="blog">
                <img src="assets/img/blog2.jpg" className="img-fluid" />
                <div className="blog_info">
                  <h3>Lorem Ipsum is simply dummy text... </h3>
                  <p>
                    <span>BY Admin-1 / FEB 10, 2020</span>
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <a href="javascript:void(0);" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/* Blog-2 */}
            {/* Blog-3 */}
            <div className="col-md-4">
              <div className="blog">
                <img src="assets/img/blog3.jpg" className="img-fluid" />
                <div className="blog_info">
                  <h3>Lorem Ipsum is simply dummy text... </h3>
                  <p>
                    <span>BY Admin-1 / FEB 10, 2020</span>
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <a href="javascript:void(0);" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/* Blog-3 */}
            {/* Blog-4 */}
            <div className="col-md-4">
              <div className="blog">
                <img src="assets/img/blog4.jpg" className="img-fluid" />
                <div className="blog_info">
                  <h3>Lorem Ipsum is simply dummy text... </h3>
                  <p>
                    <span>BY Admin-1 / FEB 10, 2020</span>
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <a href="javascript:void(0);" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/* Blog-4 */}
            {/* Blog-5 */}
            <div className="col-md-4">
              <div className="blog">
                <img src="assets/img/blog5.jpg" className="img-fluid" />
                <div className="blog_info">
                  <h3>Lorem Ipsum is simply dummy text... </h3>
                  <p>
                    <span>BY Admin-1 / FEB 10, 2020</span>
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <a href="javascript:void(0);" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/* Blog-5 */}
            {/* Blog-6 */}
            <div className="col-md-4">
              <div className="blog">
                <img src="assets/img/blog6.jpg" className="img-fluid" />
                <div className="blog_info">
                  <h3>Lorem Ipsum is simply dummy text... </h3>
                  <p>
                    <span>BY Admin-1 / FEB 10, 2020</span>
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <a href="javascript:void(0);" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            {/* Blog-6 */}
          </div>
        </div>
      </section>
    )
  }

  export default BlogGrid