import React from 'react'

export default function ContactBanner() {
    return (
      <>
      <section className="bnr_section teacher_bnr_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}
