import React,{useState,useEffect} from 'react'
import Axios from 'axios';
import LoadingIcon from '../../../helpers/LoadingIcon'
import { useForm } from "react-hook-form";
import axios from 'axios'
import SubmittingLoadingImg from '../../../helpers/SubmittingLoadingImg'
import parse from 'html-react-parser';
import Success from '../../../layout/alert/Success'

export default function AboutSec() {
  const [contactInfo,setContactInfo] = useState([]);
  const [isLoaded,setIsLoaded] = useState(false);
  const { register,handleSubmit,getValues,setValue,reset,setError,errors } = useForm();
  const [alertShow,setAlertShow]=useState(false);
  const [isSubmitting,setIsSubmitting]=useState(false);
  const [successMessage,setSuccessMessage]=useState(null);
  const [errorMessage,setErrorMessage]=useState(null);

  useEffect(() => {
    getContactDetails();
  }, [])

  async function getContactDetails()
  {
    const res = await Axios.get('get-contact-details');
    if(res && res.data.status == 'success'){
      setContactInfo(res.data.data);
      setIsLoaded(true)
    }
  }

  //submit contact form
  const formSubmit = (data) =>{
    setIsSubmitting(true);
      axios.post('home/submit-contact-form', data)
      .then(response => {
              let res = response.data;
              if(res.status=='success'){
                setIsSubmitting(false);
                setAlertShow(true);
                setSuccessMessage(res.message)
               }
      }).catch(error => {
        alert('Internal server Error.')
      });
    }
    return (
      <>
      {alertShow && successMessage && <Success setAlertShow={setAlertShow} message={parse(successMessage)} show={alertShow} /> }
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              {isLoaded && Object.keys(contactInfo).length ? 
              <div className="payout_div">
                <div className="row">
                  <div className="col-md-6">
                    <div className="cnt_box">
                      <h3>Contact Info</h3>
                      <div className="row">
                        {/* <div className="col-md-6">
                          <ul className="ftr-links">
                            <li><span><i className="icofont-google-map" /></span> {contactInfo.contact_address}
                            </li>
                          </ul>
                        </div> */}
                        <div className="col-md-6">
                          <ul className="ftr-links">
                            {/* <li><span><img src="assets/img/phone.svg" className="icon" /></span> Phone No: <a href={'tel:'+contactInfo.site_phone}>{contactInfo.site_phone}</a></li> */}
                            <li><span><img src="assets/img/mail.svg" className="icon" /></span> Email ID: <a href={'mailto:'+contactInfo.site_email}>{contactInfo.site_email}</a></li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                        {errorMessage?<div className="alert alert-danger">{errorMessage}</div> : ''}
                          <form className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="controls">
                                  <input id="name" name="name" ref={register({required:'This field is required.'})} className="form-control input-large" type="text" placeholder="Name" />
                                  { errors.name && <p className="text-danger">{errors.name.message}</p> } 
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <input id="email" name="email" ref={register({ required: "Required",
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                      }})} className="form-control input-large" type="text" placeholder="Email" />
                                  { errors.email && <p className="text-danger">{errors.email.message}</p> } 
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <input id="phone" name="phone" ref={register} className="form-control input-large" type="text" placeholder="Phone Number" />
                                  { errors.phone && <p className="text-danger">{errors.phone.message}</p> } 
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  <textarea placeholder="Enter your message" name="message" ref={register({required:'This field is required.'})} className="form-control text-area" />
                                  { errors.message && <p className="text-danger">{errors.message.message}</p> } 
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="controls">
                                  {isSubmitting ? <SubmittingLoadingImg /> : ''}
                                  <button type="submit" id="confirmsignup" disabled={isSubmitting} name="confirmsignup" className="btn btn-success register-btn">Submit</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9552209.414043237!2d-13.425761316038678!3d54.23173073395106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sin!4v1596882933827!5m2!1sen!2sin" width="100%" height={540} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} />
                  </div>
                </div>
              </div>
              : <LoadingIcon /> }
            </div>
          </div>
        </div>
      </section>
      </>
    );
}
