import React,{useState,useEffect} from "react";
import axios from 'axios';
import Carousel from "react-bootstrap/Carousel";
import TestimonialItem from './items/TestimonialItem';

function Testimonials() {
  const [testimonials,setTestimonials] = useState({});
  useEffect(() => {
    getTestimonials();
  }, []);

  async function getTestimonials() {
    const response = await axios.get("home/testimonials");

      if(!response.data.data){
        alert('Invalid Request');
        return;
      }
      setTestimonials(response.data.data);
  }
    return (
      Object.keys(testimonials).length ?
      <section className="review_sec" id="testimonials">
        <div className="container">
          <div className="row">
              <Carousel controls={false}>
                {testimonials.map((item,index)=>{
                  return (
                    <Carousel.Item key={index}>
                      <TestimonialItem item={item} />
                    </Carousel.Item>
                  )

                })}
              </Carousel>
          </div>
        </div>
      </section>
      :<></>
    );
}

export default Testimonials;
