import React, { useState,useEffect,useContext } from "react";
import HeaderTextBanner from '../layout/HeaderTextBanner';
import axios from 'axios';
import { Link } from "react-router-dom";
import SubmittingLoadingImg from '../helpers/SubmittingLoadingImg'
import {UserRole} from '../auth/Athentication'
import { useForm } from "react-hook-form";
import GlobalContext from "../../GlobalContext";

export default function ForgotPassword(props) {
    const {setIsInnerPage,setLoginModelOpen,loggedIn,setIsModelBackdropOpen} = useContext(GlobalContext)
    setIsInnerPage(true)
    const [isSubmitting,setIsSubmitting] = useState(false);
    const [isSubmitted,setIsSubmitted] = useState(false);
    const [successMessage,setSuccessMessage] = useState(null);
    const { register,handleSubmit,clearErrors,setError,errors } = useForm();
    const [formErrors,setFormErrors] = useState(null);

     const handleLogin = (event) =>{
        event.preventDefault();
        setLoginModelOpen(true)
        setIsModelBackdropOpen(true)
     }
  //submission
const formSubmit = (data)=>{
  setIsSubmitting(true);
  setSuccessMessage(null)
  setFormErrors(null)
  clearErrors('email');

  axios.post('user/submit-forgot-password', data)
      .then(response => {
          setIsSubmitted(true);
          setIsSubmitting(false);
          let res = response.data;
          if(res.status=='success'){
              setSuccessMessage(res.message);
              return;
          }else
             setError('email',{type:'manual',message:res.message});  
      }).catch(error => {
          setIsSubmitting(false);
          console.log(error);
          alert('Internal server error');
      });
  
  }; 
        return (
        <>
        <HeaderTextBanner heading="Forgot password" />
        <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-6 offset-3">
            {formErrors ? <div className="alert alert-danger">{formErrors}</div>:''}
            {successMessage ?<div className="alert alert-success"><strong>Success!</strong> {successMessage}</div> :''}
              <form className="form-horizontal teacher_form change-pwd-from" onSubmit={handleSubmit(formSubmit)}>
                <p>Enter the email address you use for Actyvate Learning, and we'll help you set a new password.</p>
                <div className="control-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="controls">
                        <input id="email" name="email" ref={register({required:'Please enter your email.'})} className="form-control input-large" type="text" placeholder="Email" />
                      </div>
                      {errors.email && <p className="text-danger">{errors.email.message}</p>}
                    </div>
                  </div>
                </div>
                {isSubmitting ? <SubmittingLoadingImg /> : ''}   
                <div className="control-group">
                  <div className="controls">
                    <button id="confirmsignup" disabled={isSubmitting} type="submit" name="confirmsignup" className="btn btn-success register-btn">Continue</button>
                  </div>
                  <p>You can also <Link to="#" onClick={handleLogin}>log in</Link> or <Link to="/signup">Join.</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
        </>
      );
    } 