import React from 'react'

export default function WhyTeach(props) {
  const cms = props.cms;
    return (
      cms ?
      <>
      <div className="row">
        <div className="col-md-12 why">
    <h2>{cms.page_heading}</h2>
        </div>
      </div>
      <div className="row text-center why_img">
        <div className="col-md-3">
          <img src="assets/img/fist.png" />
          <h3>{cms.block1_title}</h3>
          <p>{cms.block1_content}</p>
        </div>
        <div className="col-md-3">
          <img src="assets/img/mental.png" />
          <h3>{cms.block2_title}</h3>
            <p>{cms.block2_content}</p>
        </div>
        <div className="col-md-3">
          <img src="assets/img/money.png" />
          <h3>{cms.block3_title}</h3>
          <p>{cms.block3_content}</p>
        </div>
        <div className="col-md-3">
          <img src="assets/img/people.png" />
          <h3>{cms.block4_title}</h3>
          <p>{cms.block2_content}</p>
        </div>
      </div>  
      </> : ''
    );
}
