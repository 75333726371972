import React, { useState,useEffect,useContext } from "react";
import Modal from 'react-bootstrap/Modal'
import HeaderTextBanner from '../../../layout/HeaderTextBanner';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios';
import { Link } from "react-router-dom";
import {GetStringForUrl} from '../../../helpers/StringHelper';
import GlobalContext from "../../../.././GlobalContext";
import LoadingIcon from '../../../helpers/LoadingIcon'
import '../../../../css/calendar.css'
export default function UserSchedule() {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(false)
    const [events, setEvents] = useState([]);
    const [show,setShow]= useState(false);
    const [isFetching,setIsFetching]= useState(false);
    const [meetingInfo,setMeetingInfo]= useState({});
    const [meetingId,setMeetingId]= useState();
  
    useEffect(() => {
      axios.get('user/schedule').then(res=>{      
        setEvents(res.data.data);
      });
    },[]);
    
    useEffect(() => {
      setIsFetching(false);
    },[meetingInfo]);
    
    useEffect(() => {
      if(!meetingId) return;
      axios.get('user/schedule-meeting-info',{params:meetingId}).then(res=>{      
        setMeetingInfo(res.data.data);
      });
    },[meetingId]);
  

    const renderEventContent = (eventInfo) => {
      return (
        <div className={eventInfo.event.extendedProps.is_favorited == 'yes' && eventInfo.event.extendedProps.is_enrolled == 'yes' ? 'fc-event-content fav-enroled-event' : (eventInfo.event.extendedProps.is_favorited == 'yes' ? 'fc-event-content text-favcolor':'fc-event-content')}>
               <div className="fc-event-id opacity-0">{eventInfo.event.id}</div>
               <div className="fc-event-is_favorited opacity-0">{eventInfo.event.extendedProps.is_favorited}</div>
               <div className="fc-event-is_enrolled opacity-0">{eventInfo.event.extendedProps.is_enrolled}</div>
               <div className="fc-event-time d-block">
               {eventInfo.event.extendedProps.is_favorited == 'yes' ?
                 <i className="bx bxs-heart"></i> :''}
                 {eventInfo.timeText}</div>
               <div className="fc-event-title">{eventInfo.event.title}</div>
             </div>
      )
  };

    const handelEventOnclick = ({ event, el }) =>{
      const id = event.id //id
      const isFavorited = event.extendedProps.is_favorited; //isFavorited
      const isEnrolled = event.extendedProps.is_enrolled; //isEnrolled
      setIsFetching(true);
      setMeetingId({meeting_id:id,is_favorited:isFavorited,is_enrolled:isEnrolled});
      setShow(true);
    }
        return (
        <>
        <HeaderTextBanner heading="User Schedule" />
        <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
            <><div id="calendar" />
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                initialView='dayGridMonth'
                selectable={true}
                eventClick={handelEventOnclick}
                eventContent={renderEventContent}
                events={events}
                eventTimeFormat= {{ 
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true }} />
              </>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={()=>{setShow(false)}} size="md">
            <Modal.Header closeButton>
            <div className="img-box  align-items-center">
                <img src="/assets/img/icon-logo.svg" />
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className="singup-form-div">
                <div className="form-box flyer_popup">
                  {isFetching ? <LoadingIcon/> : 
                    meetingInfo && Object.keys(meetingInfo).length ? 
                      <div className="row">
                        <div className="col-md-12 mt-0">
                          <div className="row">
                            <div className="col-md-4 pl-0">
                            <Link to={"/class-details/"+GetStringForUrl(meetingInfo.title)+'/'+meetingInfo.uuid}><img src={meetingInfo.classPhotoSrc} className="img-thumbnail" alt={meetingInfo.title}/></Link>
                            </div>
                            <div className="col-md-8 pl-0">
                            <Link to={"/class-details/"+GetStringForUrl(meetingInfo.title)+'/'+meetingInfo.uuid}><h3>{meetingInfo.title}</h3></Link>
                            <p className="modal-short-desc">{meetingInfo.short_description}</p>
                            </div>
                            <div className="w-100 p-3">
                            <div className="row">
                              <ul className="class-table-ul calendar-modal-ul">
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-ui-clock" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Meeting Time <span>{meetingInfo.meeting_time}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-user" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Teacher <span><Link to={'/teachers/'+meetingInfo.teacher_unique_id}>{meetingInfo.teacher_name}</Link></span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-people" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Age Range <span>{parseInt(meetingInfo.age_range_start) > 19 ? 'Parents' :meetingInfo.age_range_start+'-'+meetingInfo.age_range_end+'Yrs'}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-mic" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p className="euqal-p">Class is taught <span>{meetingInfo.language_name}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-dollar" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Price Per Learner 
                                          <span>{meetingInfo.pricing_type == 'Free'? 'Included at no cost': 
                                          <>
                                          {meetingInfo.pricing_type == 'Discounted' ? <span className="cross">${meetingInfo.actual_price}</span>:''} ${meetingInfo.price}
                                          </>}
                                          </span>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-users-social" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Class Size <span>{meetingInfo.min_guests+'-'+meetingInfo.max_guests} Kids</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-headphone-alt-1" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p>Call Length <span> {meetingInfo.length_name}</span></p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="tb-row">
                                    <div className="tb-cell">
                                      <p><i className="icofont-network-tower" /></p>
                                    </div>
                                    <div className="tb-cell text-left pd-left">
                                      <p className="euqal-p">Class Frequency <span>{meetingInfo.no_of_weeks} Week{parseInt(meetingInfo.no_of_weeks)>1?'s':''}, {meetingInfo.calls_in_week}x Week</span></p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </div>
                              {meetingInfo.is_enrolled == 'yes' && meetingInfo.is_expired == true ?
                                <div className="w-100 text-center mb-2">
                                  <a href="#" onClick={(e)=>e.preventDefault()} className="text-danger" >This meeting has ended.</a>
                                </div> : ''
                            }

                              {meetingInfo.is_enrolled == 'yes' && meetingInfo.is_expired == false && !meetingInfo.join_url ? 
                                <span href="#" onClick={(e) => e.preventDefault()} className="p-2 text-success" >Zoom link will be available 2 hours prior to the meeting</span>
                              :''}
                              {meetingInfo.is_enrolled == 'yes' && meetingInfo.is_expired == false && meetingInfo.join_url && meetingInfo.join_url.length > 0 ?

                            <div className="w-100 text-center mb-2">
                              <a href={meetingInfo.join_url} target="_blank" className="get_button request_btn" >Join Zoom Meeting</a>
                            </div>
                            :""}
                            <div className="w-100 text-center">
                                <Link className="get_button request_btn" to={"/class-details/" + GetStringForUrl(meetingInfo.title) + '/' + meetingInfo.uuid}>{meetingInfo.is_enrolled == 'yes' ? 'View' : 'View/Enroll'}</Link>
                            </div>
                          </div>
                        </div>
                    </div>
                    :''
                  }
                </div>
            </div>
            </Modal.Body>
      </Modal>
        </>
      );
    } 