import React, { useState,useEffect,useContext } from "react";
import SignUpSec from './blocks/SignUpSec'
import { useLocation } from "react-router-dom";
import GlobalContext from "../../../GlobalContext";


function SignUpPage(props) {
  
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAlertShown, setIsAlertShown] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
  },[]);

  return (
    <>
      <SignUpSec />
    </>
  );
}
export default SignUpPage;
