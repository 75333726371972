import React,{ useState,useEffect } from 'react'


export default function BlogItem(props)
{
const [blog,setBlog] = useState({});

useEffect(() => {
  if(props.item){
    setBlog(props.item);
  }
}, []);
const removeTags = (str) => {
  if ((str===null) || (str===''))
  return false;
  else
  str = str.toString();
  str = str.replace( /(<([^>]+)>)/ig, '');
  str = str.replace('&#8217;',"'");
  return str.substring(0,300);
}


    return (
        Object.keys(blog).length?
        <div className="col-md-4">
              <div className="blog">
                <img src={blog._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url} className="img-fluid" />
                <div className="blog_info">
                  <h3>{blog.title.rendered}</h3>
                  <p>
                    {/* <span>By {blog.created_by} / {blog.created_date}</span> */}
                  </p>
                    <p>{removeTags(blog.content.rendered)}</p>
                  <a href={blog.link} target="_blank" className="read_button">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            : <></>
    )
}
