import React from 'react'
import { Link } from "react-router-dom";


export default function WelcomeTeacherBanner() {
    return (
      <>
        <section className="bnr_section teacher_bnr_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="teacher-profile">
                  <div className="tb-row">
                    <div className="tb-cell">
                      <img src={localStorage.getItem("Avatar")} />
                    </div>
                    <div className="tb-cell text-left pd-left">
                      <h1>
                        Welcome,
                        <span>
                          { localStorage.getItem("Firstname") +  " " + localStorage.getItem("Lastname") }
                        </span>
                      </h1>
                        <p>joined {localStorage.getItem('created_at')}</p>
                      <a href="/teacher-dashboard/#profile" onClick={()=>{localStorage.setItem('teacherTabPage','Profile'); window.location.reload();}} className="get_button">
                        Edit Profile
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}
