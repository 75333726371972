import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import {useHistory,Link} from 'react-router-dom'
import parse from 'html-react-parser';
import { useForm } from "react-hook-form";
import Success from "../../../layout/alert/Success";
import {siteName} from '../../../auth/Athentication'
import ErrorAlert from "../../../layout/alert/ErrorAlert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAge } from '../../../helpers/StringHelper';

export default function VolunteerTab(props) {
  const displayTeachVideo = props.displayTeachVideo;
  let history = useHistory();
  const [inputs, setInputs] = useState({});
  const triggerRef = useRef();
  const [formErrors, setFormErrors] = useState(null);      
  const [showErrorAlert, setShowErrorAlert] = useState(false);      
  const [errorAlertMessage, setErrorAlertMessage] = useState(false);      
  const [isSubmitting, setIsSubmitting] = useState(false);  
  const [formEle, setFormEle] = useState(null);  
  const [errorMsgBlock, setErrorMsgBlock] = useState({});  
  const [countries, setCountries] = useState(null);             
  const [introVideo, setIntroVideo] = useState({});          
  const [resume, setResume] = useState({});          
  const [isIntroVideoSelected, setIsIntroVideoSelected] = useState(null);  
  const [successMessage, setSuccessMessage] = useState(null);     
  const { register,handleSubmit,clearErrors,setError,watch,errors } = useForm();
  const [alertShow, setAlertShow] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const watchInputs = watch(['skip_video']);
  const DobMaxDate = ()=>{
    var date = new Date();
    date.setFullYear(date.getFullYear() - 12);
    return date;
}

  useEffect(() => {
    setCountries(props.Countries);
  },[]);

  const handleOnChangeVideo = (event) => {
    var inputName = event.target.name;
    const file = event.target.files[0];
    if(!file){
        return;
    }
    const fileSize = Math.round((file.size / 1024)); 
        if (fileSize >= 5096) { 
            setError("intro_video",{type:"manual",message:"File too large, please select less then 5MB."});
            setIsIntroVideoSelected(false);
            setIntroVideo({});
        }else{
            clearErrors(['intro_video']);
            setIsIntroVideoSelected(true);
            setIntroVideo({file:file,name:file.name});
        }
    }

    const handleDateOfBirth = (date,e) =>{
      var dob = e.target.value;
      var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
      if (dob != undefined && (dob == null || !pattern.test(dob))) {
        console.log('invalid date' + dob)
      }else if(date){
        console.log('valid date')
        const maxTime = DobMaxDate().getTime();
        const inputTime = date.getTime();
        
        if(maxTime < inputTime){
          setShowErrorAlert(true);
          setError("dateOfBirth",{type:"manual",message:"Please select valid date of birth."});
          setErrorAlertMessage(`Sorry!! Your age should be 13 or older as per COPPA (Children's online privacy protection rule) to continue the below application.`);
          return;
        }else{
          clearErrors(['dateOfBirth']);
        }
        setDateOfBirth(date); 
      }
    }
    //for form inputs 
    const handleInputChange = async (event) => {
        event.persist();
        const input = event.target;
        if(input.type == 'file'){
            const file = input.files[0];
            const fileSize = Math.round((file.size / 1024)); 
            if(input.name == 'resume'){
                var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
                if(!allowedExtensions.exec(input.value)){
                    setError("resume",{type:"manual",message:"File extension should be PDF,DOC or DOCX."});
                    return false;
                }else if(fileSize >= 2084){
                    setError("resume",{type:"manual",message:"Resume should not exceeds 2MB."});
                    return false;
                }else{
                    clearErrors(['resume']);
                }
                setResume({file:file,name:file.name});
            }
        }else{
            setInputs(inputs => ({...inputs, [input.name]: input.value}));
        }
   };
   
   //submission
   const formSubmit = (data)=>{
    const formdata = new FormData();   //formdata object
    setIsSubmitting(true);
    if(!dateOfBirth){
        errorMsgBlock.focus();
        setError("dateOfBirth",{type:"manual",message:"This field is required."});
        setIsSubmitting(false);
        return false;
      }else if(DobMaxDate().getTime() < dateOfBirth.getTime()){
        errorMsgBlock.focus();
        setError("dateOfBirth",{type:"manual",message:"Please select valid date of birth."});
        setIsSubmitting(false);
        return false;
    }
for ( var key in inputs ) {
  formdata.append(key, inputs[key]);
}
formdata.append('date_of_birth',dateOfBirth.toLocaleString());

if(Object.keys(introVideo).length){
  formdata.append('introduction_video',introVideo.file,introVideo.name)
}
if(Object.keys(resume).length){
  formdata.append('resume',resume.file,resume.name)
}

var post_url ='teacher/register/volunteer';
axios.post(post_url, formdata)
.then(response => {
    setIsSubmitting(false);
        let res = response.data;
        if(res.status && res.status == 'success'){
            setAlertShow(true)
            setDateOfBirth(null);
            formEle.reset();
            setInputs({});
            setFormErrors(null);
            setSuccessMessage(res.message);
        }else{
            let msg_str ='';
            if(typeof(res.message)== 'string'){
                setFormErrors(res.message);
                errorMsgBlock.focus();
            }else{ 
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                    console.log(msg)
                    msg_str += msg+'-';
                    setFormErrors(msg_str);
                    errorMsgBlock.focus();
                });
            });
        }
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

};

  const introVideoValidation = (watchInputs.skip_video) !== false
    ? false
    : 'This field is required';

      return (
        <div className="tabcontent form-box" id="Volunteer">
        {successMessage && <Success setAlertShow={setAlertShow} message={parse(successMessage)} show={alertShow} /> }
          {showErrorAlert && <ErrorAlert setErrorAlertShow={setShowErrorAlert} message={[errorAlertMessage]} show={showErrorAlert} /> }
        <h1>Apply to Volunteer</h1>
        <Form className="form-horizontal teacher_form" ref={(el) => setFormEle(el)} onSubmit={handleSubmit(formSubmit)}>
          <fieldset>
            <div className="step-div">
              <div className="row">
                <div className="col-md-12">
                  <p>It's easy to volunteer with {siteName()}. First, work with your school or organization to ensure that the volunteer hours you earn on our platform will be accepted. Then complete a short application form, share a brief introduction video, and pass a background check. We don’t require formal credentials, just passion for the topics you offer. At this time, we do require that our volunteers be residents of the US or Canada.
                  </p>
                  <p>When your volunteer application is approved, we will work with you to offer events on our platform so that you can start earning volunteer credits.</p>
                    <div className="row">
                      <div className="col-md-4 m-auto">
                        {displayTeachVideo == '1' ? <img className="w-100" src="/assets/img/sample-vid.png"/> : ''}
                      </div>
                    </div>
                </div>
              </div>
              <hr />
            </div>
            <div className="volunteers_block box">
              <div className="step-div" />
                { formErrors && <div className="alert alert-danger">{formErrors}</div> } 
              <h2><span>Step 1:</span> Complete the Volunteer (host) form</h2>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="first_name" name="first_name" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large" type="text" placeholder="First Name"/>
                    </div>
                    {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="last_name" name="last_name" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large" type="text" placeholder="Last Name"/>
                    </div>
                    {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="nick_name" name="nick_name" ref={register} onChange={handleInputChange} className="form-control input-large" type="text" placeholder="Preferred name to be displayed (optional)" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="controls">
                      <span className="calendar_icon"><i className="bx bx-calendar-week" /></span>
                      <DatePicker selected={dateOfBirth} showMonthDropdown showYearDropdown dropdownMode="select" className="form-control input-large" onChange={(date,e) =>handleDateOfBirth(date,e)} placeholderText="Date of Birth" />
                    </div>
                    {errors.dateOfBirth && <p className="text-danger">{errors.dateOfBirth.message}</p>}
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="email" name="email" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="Email" />
                    </div>
                    {errors.email && <p className="text-danger">{errors.email.message}</p>}
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="phone" name="phone" className="form-control input-large" type="text" ref={register} onChange={handleInputChange} placeholder="Phone Number" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="control-group">
                <div className="row">
                  
                  <div className="col-md-3">
                    <div className="controls">
                    <input type="radio" className="hidden-input-file" ref={(ele)=>setErrorMsgBlock(ele)}/>
                      <input id="city" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} name="city" type="text" placeholder="City" />
                    </div>
                        {errors.city && <p className="text-danger">{errors.city.message}</p>}
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input id="state" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} name="state" type="text" placeholder="State" />
                    </div>
                        {errors.state && <p className="text-danger">{errors.state.message}</p>}
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                    <select id="country_id" className="form-control input-large" name="country_id" placeholder="Country" ref={register({required:'This field is required.'})} onChange={handleInputChange} >
                        <option value="">Country</option>  
                            {countries && countries.map((country,index)=>{
                              return <option key={index} value={country.id}>{country.country_name}</option>
                            })}
                        </select>
                        {errors.country_id && <p className="text-danger">{errors.country_id.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-3">
                      <div className="controls">
                        <input id="zipcode" className="form-control input-large" name="zipcode" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="Zip Code" />
                      </div>
                      {errors.zipcode && <p className="text-danger">{errors.zipcode.message}</p>}
                    </div>
                </div>
              </div>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">Education </label> <br />
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="education1_school" name="education1_school" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="School/Program (required)" />
                    </div>
                        {errors.education1_school && <p className="text-danger">{errors.education1_school.message}</p>}
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="education1_degree" name="education1_degree" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="Field of Interest" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="controls">
                      <input id="education1_year" name="education1_year" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="Graduation Year" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="controls">
                      <div className="selectdiv">
                        <select name="current_school_grade" id="current_school_grade" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control">
                          <option value={''}>Current grade</option>
                          <option value="9th Grade">9th grade</option>
                          <option value="10th Grade">10th grade</option>
                          <option value="11th Grade">11th grade</option>
                          <option value="12th Grade">12th grade</option>
                          <option value="other">other</option>
                        </select>
                      </div>
                      {errors.current_school_grade && <p className="text-danger">{errors.current_school_grade.message}</p>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="controls">
                      <input id="linkedin" name="linkedin" className="form-control input-large" ref={register} onChange={handleInputChange} type="text" placeholder="LinkedIn (optional)" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="control-group">
                <label className="label_info" htmlFor="teacher">Personal Reference </label><br clear="all" />
                <div className="row">
                  <div className="col-md-3">
                    <div className="controls">
                      <input id="referred_firstname" name="referred_firstname" className="form-control input-large" type="text" ref={register({required:'This field is required.'})} onChange={handleInputChange} placeholder="First Name"  />
                    </div>
                    {errors.referred_firstname && <p className="text-danger">{errors.referred_firstname.message}</p>}
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input id="referred_lastname" name="referred_lastname" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="Last Name" />
                    </div>
                    {errors.referred_lastname && <p className="text-danger">{errors.referred_lastname.message}</p>}
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input id="referred_relationship" name="referred_relationship" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="Relationship" />
                    </div>
                    {errors.referred_relationship && <p className="text-danger">{errors.referred_relationship.message}</p>}
                  </div>
                  <div className="col-md-3">
                    <div className="controls">
                      <input id="referred_email" name="referred_email" className="form-control input-large" ref={register({required:'This field is required.'})} onChange={handleInputChange} type="text" placeholder="Email" />
                    </div>
                    {errors.referred_email && <p className="text-danger">{errors.referred_email.message}</p>}
                  </div>
                </div>
              </div>
              <h2><span>Step 2:</span> Share your skills and Interests</h2>
              <div className="control-group">
                <div className="row">
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">How would you describe your work ethic?</label> <br />
                    <div className="controls">
                      <textarea name="work_ethic" className="form-control text-area" ref={register} onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">What type of events are you interested in hosting? Please give an example of how you would structure the event.</label> <br />
                    <div className="controls">
                      <textarea name="passion" className="form-control text-area" ref={register} onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">What age student would you like to work with?</label> <br />
                    <div className="controls">
                      <textarea name="age_of_children_to_work" className="form-control text-area" ref={register} onChange={handleInputChange} />
                    </div> 
                  </div>
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">Any additional information you want to share?</label> <br />
                    <div className="controls">
                      <textarea name="additional_information" className="form-control text-area" ref={register} onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="label_info" htmlFor="teacher">How many hours a week are you available?</label> <br />
                    <div className="controls">
                      <textarea name="hours_available_in_week" className="form-control text-area" ref={register} onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="label_info" htmlFor="teacher">Resume/Brag Sheet</label> <br />
                    <div className="controls">
                        <input id="resume" name="resume" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" ref={register({required:'This field is required.'})} onChange={handleInputChange} className="form-control input-large resume-input" type="file"  />
                        {errors.resume && <p className="text-danger">{errors.resume.message}</p>}
                    </div>
                  </div>
                  
                </div>
              </div>
              <hr />
              <h2><span>Step 3:</span> Introduce yourself</h2>
              <div className="control-group">
                <label className="label_info" htmlFor="teacher">Please submit a brief 2 minute (maximum) video where you tell us about yourself, share one of your talents, read a story, dance, play a musical instrument, or sing a song. Show us how you will present an authentic, supportive, and enthusiastic personality. These videos will only be viewed by our team so we can learn a little more about you and your style.</label>
                <div className="row">
                    <div className="col-md-12 pt-2">
                      <p><label><input type="checkbox" ref={register} name="skip_video" id="skip_video" onChange={handleInputChange} value="robot" /> If you prefer to wait for a live video meeting with an Oggi Connects team member to introduce yourself, you can skip this step by checking this box.</label></p>
                    </div>
                  <div className="col-md-4">
                    <div className="form-group">
                        <input id="youtube_link" className="form-control input-large" name="youtube_link" ref={register({ required: introVideoValidation,
                          pattern: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/})} onChange={handleInputChange} type="text" placeholder="Youtube URL" />
                      <p><small className="text-dark pl-3">Eg: https://youtube.com/* * * *</small></p>
                      {errors.youtube_link && errors.youtube_link.type=='pattern' && <p className="text-danger">Please enter valid Youtube URL.</p>}
                    {errors.youtube_link && errors.youtube_link.message && <p className="text-danger">{errors.youtube_link.message}</p>}
                    </div>
                    {/* <div className="controls">
                      <label className="btn text-white register-btn" htmlFor="intro_video">Submit Video</label>
                      <input type="file" name="intro_video" id="intro_video" accept="video/*" onChange={handleOnChangeVideo} className="hidden-input-file"/>
                    </div> */}
                    {isIntroVideoSelected && <p className="text-success">Selected video: {introVideo.name?introVideo.name:''}</p>}
                        {errors.intro_video && <p className="text-danger">{errors.intro_video.message}</p>}
                  </div>
                  <div className="col-md-8">
                    {/* <div className="controls arrow_img">
                      <span><strong>You should:</strong></span>
                      <p> Show us your online teaching space </p>
                      <p> Make sure your personality shines through.</p>
                      <p> Show us how you’ll keep students actively engaged.</p>
                      <p>Teach us something that you are excited about.</p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* Terms input*/}
              <div className="control-group">
                <label className="label_info" htmlFor="teacher">If you’re under 18, please provide your parent(s) email address so we can verify their consent.</label> <br />
                <div className="row">
                  <div className="col-md-6">
                    <input id="parent1_email" name="parent1_email" className="form-control input-large" 
                    ref={register({validate:{
                      parentEmailValidation:(value) =>{
                        if(dateOfBirth && value == ''){
                          const getAge =  Math.floor((new Date() - new Date(dateOfBirth).getTime()) / 3.15576e+10)
                          return getAge > 18 || 'This field is required.'
                        }
                      }
                    }})} 
                    onChange={handleInputChange} type="email" placeholder="Parent 1 Email (required if under 18)" />
                    {errors.parent1_email && <p className="text-danger">{errors.parent1_email.message}</p>}
                  </div>
                  <div className="col-md-6">
                    <input id="parent2_email" name="parent2_email" className="form-control input-large" ref={register} onChange={handleInputChange} type="email" placeholder="Parent 2 Email (optional)" />
                  </div>
                </div>
              </div>
            </div>
            <br />
            
            <div className="row">
            <div className="col-md-12">
                <p> <label><input type="checkbox" ref={register({required:'This field is required.'})} name="terms_check" id="terms" onChange={handleInputChange} value="robot" /> * I understand that if my application is approved, I will be a non-paid volunteer and I can earn community service credits if my school or program accepts them. </label>
                        {errors.terms_check && <p class="text-danger">{errors.terms_check.message}</p>}
                        </p>
                        <p> <label><input type="checkbox" onChange={handleInputChange} ref={register({required:'This field is required.'})} name="terms_check2" id="terms1" value="robot" /> * I understand that I must pass a criminal background check before I am approved to volunteer on Oggi Connects. </label>
                        {errors.terms_check2 && <p class="text-danger">{errors.terms_check2.message}</p>}
                        </p>
                </div>
                <div className="col-md-12">
                <div className="control-group" id="partner">
              <div className="controls">
                <label className="radio inline" htmlFor="terms">
                  {/* <input type="checkbox" name="terms" id="terms" value="robot">&nbsp;*/} By submitting your application, you agree to the <Link to={'/terms'} className="terms">Terms of Service</Link> </label> 
              </div>
            </div>
                </div>
              <div className="col-md-4">
                {/* Button */}
                <div className="control-group">
                    {isSubmitting && <span>Please wait the form is submitting...</span>}
                  <div className="controls">
                    <button id="confirmsignup" name="confirmsignup" disabled={isSubmitting} className="btn btn-success register-btn">Submit Application</button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </Form>
      </div>
      );
    }