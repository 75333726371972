import React, { useState, useEffect,useRef,useContext } from 'react';
import axios from 'axios';
import HeaderTextBanner from '../../../layout/HeaderTextBanner'
import LoadingIcon from '../../../helpers/LoadingIcon';
import {UserRole} from  '../../../auth/Athentication'; 
import Modal from 'react-bootstrap/Modal'
import {Link,useLocation,useHistory} from 'react-router-dom';
import { HiddenCropper } from "react-bootstrap-image-cropper";
import { useForm,Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Success from '../../../layout/alert/Success'

import '../../../../css/about.css'
import GlobalContext from "../../../../GlobalContext";

export default function UserProfile(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const triggerRef = useRef();
  let location = useLocation();
  let history = useHistory();
  const userRole = UserRole();
  const [alertShow, setAlertShow] = useState(false);
  const [Languages, setLanguages] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isSubmitted,setIsSubmitted] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);            
  const [timezones, setTimezones] = useState([]);      
  const [profilePhotoSrc, setProfilePhotoSrc] = useState('/assets/img/smile.png');        
  const [formErrors, setFormErrors] = useState(null);      
  const [successMessage, setSuccessMessage] = useState(null);
  const { register,handleSubmit,getValues,setValue,reset,setError,errors } = useForm();
  const {register: register2,reset:reset2,watch:watch2,errors: errors2, control:control2, setError:setError2,handleSubmit: handleSubmit2} = useForm();
  const [children,setChildren] = useState([]);
  const [myChannels,setMyChannels] = useState([]);
  const [isChildModalOpen,setIsChildModalOpen]= useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const redirectURL = UserRole() == 'teacher' ? '/teacher-dashboard':'/user-dashboard'; 
  const startDate= new Date(new Date().setFullYear(new Date().getFullYear() - 20));
  const endDate= new Date(new Date().setFullYear(new Date().getFullYear() - 3));
  const isChildEdited = watch2('id');
  
  useEffect(() => {
    getTimezones();
    getChannels();
    getLanguages();
    getUserdetail();
  },[]);

  useEffect(() => {
    if(!alertShow) return;

   setTimeout(function(){
     history.push(redirectURL)
   },3000);
   },[alertShow]);
   
  async function getTimezones(){
    const dbTimezones = await axios.get("get-timezones");
    setTimezones(dbTimezones.data.data);
  }
  async function getChannels(){
    const dbChannels = await axios.get("get-channels",{params:{show_in_interests:'yes'}});
    setChannels(dbChannels.data.data);
  }
  async function getLanguages(){
    const dbLanguages = await axios.get("get-class_languages");
    setLanguages(dbLanguages.data.data);
  }

  
    async function getUserdetail()
    {
      const response = await axios.get("user/get-user-profile");
      if(typeof(response.data.data)!='undefined' && response.data.status=='success'){
        const info = response.data.data;
        if(info.role.role == 'parent' && info.children.length){
          setChildren(info.children);
        }
        const formInfo = {
          first_name:info.first_name,
          last_name:info.last_name?info.last_name:'',
          nick_name:info.nick_name?info.nick_name:'',
          interests:info.search_interests,
          zipcode:info.user_setting.zipcode,
          about_learner:info.user_setting.about_learner,
          languages:info.user_setting.languages,
          timezone_id:info.user_setting.timezone_id,
        }
        if(info.profile_picture){
          setProfilePhotoSrc(info.profile_picture);
        }
        if(info.my_channels){
          setMyChannels(info.my_channels);
        }
        console.log(formInfo)
        reset(formInfo);
        setTimeout(() => {
          let values =getValues();
          if(!values.languages && info.user_setting.languages){
            setValue('languages',info.user_setting.languages)
          }
          setIsFetched(true);
      }, 2800);
      } 
    }
    useEffect(() => {
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
  });

     //profile pic  
     function handleCropped(file) {
      setProfilePhotoSrc(URL.createObjectURL(file));  
      setProfilePhoto({file:file,name:'profilePhoto.png'}); 
    }
    const handleChildModal = (event,child=null)=>{
      event.preventDefault();
      if(child){
        const modalChild = {
          id:child.id,
          first_name:child.first_name,
          date_of_birth:new Date(child.date_of_birth),
          last_name:child.last_name,
          age:child.age,
          about_learner:child.about_learner,
          email:child.email,
        }
        reset2(modalChild);
      }else{
        reset2({
          id:'',
          first_name:'',
          last_name:'',
          age:'',
          about_learner:'',
          email:'',
        });
      }
      setIsChildModalOpen(true);
    }

    const formSubmit = (data)=>{
      const formdata = new FormData();
      for ( var key in data ) {
        formdata.append(key, data[key]);
      }
      if(profilePhoto){
        formdata.append('photo',profilePhoto.file,profilePhoto.name)
      }
      setIsSubmitting(true);
      setFormErrors(null);
      setSuccessMessage(null);
      axios.post("user/update-user-profile", formdata).then(response => {
        let res = response.data;
        if(res.status=='success'){
            window.scrollTo(0, 0)
            setSuccessMessage(res.message);
            setAlertShow(true)
            setIsSubmitted(true)
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                  setError(ele,{type:'manual',message:msg,shouldFocus:true});
                    console.log(msg)
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                    setFormErrors(msg_str);
                    window.scrollTo(0, 0)
                });

            });
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

    }

    const remove_affiliaction = (event,assoc_id)=>{
      event.preventDefault();
        if(!window.confirm('Are you sure want to remove this channel..?')){
          return false;
        }
        axios.post("user/remove-user-channel-association", {associate_id:assoc_id}).then(response => {
          if(response.data.status == 'success'){
            setMyChannels(myChannels.filter((channel) => channel.associate_id != assoc_id));
          }
        }).catch(err=>{
          alert('Sorry something went wrong.')
          return false;         
        })
    }

//child form submit
const childFormsubmit = (data) => {
  setIsSubmitting(true);
axios.post('user/save-child', data)
.then(response => {
        let res = response.data;
        if(res.status=='success'){
          if(data.id){
            // let currentChildren = children.filter((ch) => ch.id !== res.data.id);
            //   currentChildren.push(res.data);
            //   const childrenObj = currentChildren.sort((a, b) => b.id - a.id);
            //   setChildren(childrenObj)
              const index = children.findIndex(child => child.id === res.data.id);
              console.log(index); 
              let myChildren = children;
              myChildren[index] = res.data;
        setChildren(myChildren);
          }else{
            const childrensObj = children.filter((ch) => ch.id !== res.data.id);
            setChildren([...childrensObj,res.data]);
          }
         
          setIsSubmitting(false);
          setIsChildModalOpen(false)
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                  setError2(ele,{type:'manual',message:msg,shouldFocus:true});
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                });

            });
            
        }     
    }).catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });
}    
  return (
    <>
    { isSubmitted && successMessage ? <Success show={alertShow} message={successMessage} setAlertShow={setAlertShow} /> : ''}
    <HeaderTextBanner heading={userRole == 'parent'? 'Parent Profile':'Student Profile'} />
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="parent-profile">
            <div className="row">
              <div className="col-md-8 offset-2">
              {(formErrors?<div className="alert alert-danger">{formErrors}</div> 
      : '')}
      {isFetched ?
                <form className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
                  <h2>{userRole == 'parent'? 'Parent Profile':'Student Profile'}</h2>
                  <img src={profilePhotoSrc} />
              <br />
              <a onClick={() => triggerRef.current.trigger()} className="clr_link">
                Select Photo..
              </a>
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="label_info" htmlFor="first_name">First Name</label> 
                        <div className="controls">
                          <input id="first_name" name="first_name" ref={register} className="form-control input-large" type="text"/>
                        </div>
                          {errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
                      </div>
                      <div className="col-md-6">
                        <label className="label_info" htmlFor="last_name">Last Name</label> 
                        <div className="controls">
                          <input id="last_name" name="last_name" ref={register} className="form-control input-large" type="text"/>
                        </div>
                          {errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="label_info" htmlFor="nick_name">Preferred name to be displayed</label> 
                        <div className="controls">
                          <input id="nick_name" name="nick_name" ref={register} className="form-control input-large" type="text"/>
                        </div>
                          {errors.nick_name && <p className="text-danger">{errors.nick_name.message}</p>}
                      </div>
                    </div>
                    {userRole == 'student' ? 
                    <div className="row">
                      <div className="col-md-12">
                        <label className="label_info" htmlFor="about_learner">What should hosts know to support this student?</label> 
                        <div className="controls">
                          <textarea id="about_learner" name="about_learner" ref={register} className="form-control input-large"></textarea>
                        </div>
                          {errors.about_learner && <p className="text-danger">{errors.about_learner.message}</p>}
                      </div>
                    </div>
                    :''}
                  </div>
                  {userRole == 'parent' ? 
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>My children</h3>
                        <p>We’ll show you events that your kids will love.</p>
                        {children.length ? children.map((child,index)=>{
                          const childName = child.first_name +' '+ (child.last_name?child.last_name:'');
                          return (
                            <div key={index} className="tb-row child_name">
                              <div className="tb-cell">
                                <img className="img-responsive teacher-img" src="assets/img/smile.png" />
                              </div>
                              <div className="tb-cell text-left pd-left">
                                <p><strong>{childName+' ('+child.age+')'}</strong></p>
                                <p><Link to="#" onClick={(event)=>handleChildModal(event,child)}>Edit</Link></p>
                              </div>
                            </div>
                          )
                        }) : '' }

                        <div className="controls">
                          <Link to="#" onClick={handleChildModal} className="get_button"><i className="icofont-plus" /> Add Child</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  :''}
                  <div className="control-group mt-20">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Update User Interests</h3>
                        <div className="row mt-10">
                          {channels.length && channels.map((channel,index)=>{
                            return (
                              <div className="col-md-4" key={'interest'+index}>
                                <div className="control-group">
                                  <div className="controls">
                                    <label className="radio inline" htmlFor={'interest-'+channel.id}>
                                      <input type="checkbox" ref={register} name="interests" id={'interest-'+channel.id} value={channel.id} />&nbsp; {channel.channel_name} </label>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div> 
                
                   <div className="control-group mt-10">
                     {myChannels && myChannels.length ? 
                     myChannels.map((channel,index) =>{
                      return (
                    <div className="row pt-3 bg-light mb-1" key={index}>
                      <div className="col-md-12">
                        <h3>Exclusive Channel Affiliation: {index+1}</h3>
                      </div>
                      <div className="col-md-4">
                        <div className="school_name">
                          {channel.channel_name}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="controls">
                         <label htmlFor="">{channel.valid_from}</label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="controls">
                         <label htmlFor="">{channel.valid_to}</label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <a href="#" onClick={(event)=>remove_affiliaction(event,channel.associate_id)} className="delete">Delete</a>
                      </div>
                    </div>
                      )
                    })
                    :''}
                  </div> 
                  {/* Text input*/}
                  <div className="control-group">
                    <div className="row">
                    </div>
                  </div>
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Preferred language</h3>
                        <div className="row mt-10">
                        {Languages.length && Languages.map((language,index)=>{
                            return (
                          <div className="col-md-3" key={index}>
                            <div className="control-group">
                              <div className="controls">
                                <label className="radio inline" htmlFor={'language-'+index}>
                                  <input type="checkbox" name="languages" ref={register} className="search_input1" value={language.id} id={'language-'+index} /> {language.language} </label>
                              </div>
                            </div>
                          </div>
                            )
                        })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Zipcode</h3>
                        <div className="row mt-0">
                          <div className="col-md-6">
                            <div className="controls">
                                <input type="number" className="form-control" name="zipcode" ref={register}/>
                            </div>
                            {errors.zipcode && <p className="text-danger">{errors.zipcode.message}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group mt-10">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Time Zone</h3>
                        <div className="row mt-0">
                          <div className="col-md-6">
                            <div className="controls">
                              <div className="selectdiv">
                                <select name="timezone_id" ref={register} id="timezone_id" className="form-control">
                                <option value="">Select</option>  
                                  {timezones.length && timezones.map((timezone,index)=>{
                                    return <option key={index}  value={timezone.id}>{timezone.timezone_name}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                            {errors.timezone_id && <p className="text-danger">{errors.timezone_id.message}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="controls">
                          <button id="confirmsignup" disabled={isSubmitting} name="confirmsignup" className="btn btn-success register-btn">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </form>
                : <LoadingIcon /> }
              </div>
            </div>
          </div>
        </div>
      </section>
      <HiddenCropper
        triggerRef={triggerRef}
        onCropped={handleCropped}
        cropOptions={{ aspect: 4 / 3, maxZoom: 10 }}
        outputOptions={{ maxWidth: 300, maxHeight: 300 }}
        previewOptions={{ width: 300, height: 300 }}
        displayOptions ={{ removeButtonText :'Cacel' }}
      />

      <Modal dialogClassName="profile-child-modal" show={isChildModalOpen} onHide={()=>{setIsChildModalOpen(false)}} >
        <Modal.Header closeButton>
        <div className="img-box  align-items-center">
            <img src="/assets/img/icon-logo.svg" />
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className="form-box">
            <h3>{isChildEdited ? 'Edit Child' : isChildEdited+ 'Add Child' }</h3>
            <h5>Information for Teachers</h5>
            <div className="row">
              <div className="col-md-12">
                <form className="form-horizontal" onSubmit={handleSubmit2(childFormsubmit)} >
                  <fieldset>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="controls">
                            <input id="fname" name="first_name" ref={register2({required:'This field is required.'})} className="form-control input-large" type="text" placeholder="First Name - Required" />
                          </div>
                          {errors2.first_name && <p className="text-danger">{errors2.first_name.message}</p>}
                        </div>
                        <div className="col-md-6">
                          <div className="controls">
                            <input id="lastname" ref={register2} name="last_name" className="form-control input-large" type="text" placeholder="Last Name - Optional" />
                          </div>
                          {errors2.last_name && <p className="text-danger">{errors2.last_name.message}</p>}
                        </div>
                      </div>
                    </div>
                    {/* Password input*/}
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="">Date of Birth</label>
                          <div className="selectdiv">
                          <Controller
                        control={control2}
                        name="date_of_birth"
                        rules={{ required: true }}
                        render={({ onChange, onBlur, value }) => (
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            dateFormat="MM/yyyy"
                            className="form-control"
                            placeholderText="Date of Birth"
                            maxDate={endDate}
                            minDate={startDate}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            selected={value}
                          />
                        )}
                      />
                          </div>
                          {errors2.date_of_birth && <p className="text-danger">Please select Date of birth</p>}
                        </div>
                      </div>
                      {isChildEdited  ? 
                      <>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Learner email (optional)</label>
                          <p>If entered, class notifications are also sent here. Your learner will need to log in using your account to view class content.</p>
                          <div className="controls">
                            <input id="email" name="email" className="input-large form-control" ref={register2} type="email"placeholder="Enter a valid email address" />
                          </div>
                          {errors2.email && <p className="text-danger">{errors2.email.message}</p>}
                        </div>
                      </div>
                    </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">What should hosts know to support this student?</label> <br />
                          <div className="controls">
                            <textarea name="about_learner" ref={register2} className="form-control text-area" placeholder="Learner names are only shared with teachers and classmates after you enroll in a class" />
                          </div>
                          {errors2.about_learner && <p className="text-danger">{errors2.about_learner.message}</p>}
                        </div>
                      </div>
                      </> : ''}
                    </div>
                   
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <input type="hidden" name="id" ref={register2}/>
                            <button id="confirmsignup" disabled={isSubmitting} type="submit" name="confirmsignup" className="btn btn-success register-btn">Save</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* Button */}
                        <div className="control-group">
                          <div className="controls">
                            <button type="button" onClick={()=>setIsChildModalOpen(false)} className="btn btn-success register-btn clr_change">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}
