import React from 'react'

export default function MyChannelsSec() {
    return (
      <>
         <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="row">
            <div className="col-md-12">
              <div className="payout_div">
                <div className="row">
                  <div className="col-md-6">
                    <div className="myclass">
                      <div className="tb-row">
                        <div className="tb-cell">
                          <img className="img-responsive my_channel_img" src="assets/img/search-class/4.jpg" />
                          <span className="date_span">Exclusive</span>
                        </div>
                        <div className="tb-cell text-left pd-left">
                          <p className="class-name"><a href="#teacher"><strong>Beginning Piano 1 - (12 and Under) </strong></a></p>
                          <h3 className="channel_info">Music</h3>
                          <p><a href="moderate-teacher.html">Kimberly Hanson, M. Ed.</a></p>
                          <p>- Crossroads School of Music</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="myclass">
                      <div className="tb-row">
                        <div className="tb-cell">
                          <img className="img-responsive my_channel_img" src="assets/img/search-class/5.jpg" />
                          <span className="date_span">Exclusive</span>
                        </div>
                        <div className="tb-cell text-left pd-left">
                          <p className="class-name"><a href="#teacher"><strong>Level 1! Rock Out Beginners. Learn Incredible Acoustic &amp; Electric Guitar Skills</strong></a></p>
                          <h3 className="channel_info">Music</h3>
                          <p><a href="moderate-teacher.html">Kimberly Hanson, M. Ed.</a></p>
                          <p>- Crossroads School of Music</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="myclass">
                      <div className="tb-row">
                        <div className="tb-cell">
                          <img className="img-responsive my_channel_img" src="assets/img/search-class/6.jpg" />
                          <span className="date_span">Exclusive</span>
                        </div>
                        <div className="tb-cell text-left pd-left">
                          <p className="class-name"><a href="#teacher"><strong>TWO WEEK WORKSHOP - Exploring Your Musical Theatre Voice Through Performance! </strong></a></p>
                          <h3 className="channel_info">Music</h3>
                          <p><a href="moderate-teacher.html">Kimberly Hanson, M. Ed.</a></p>
                          <p>- Crossroads School of Music</p>
                        </div>
                      </div>
                    </div> 
                  </div>
                  <div className="col-md-6">
                    <div className="myclass">
                      <div className="tb-row">
                        <div className="tb-cell">
                          <img className="img-responsive my_channel_img" src="assets/img/search-class/3.jpg" />
                          <span className="date_span">Exclusive</span>
                        </div>
                        <div className="tb-cell text-left pd-left">
                          <p className="class-name"><a href="#teacher"><strong>Rudimentary Drumming Camp Intermediate </strong></a></p>
                          <h3 className="channel_info">Music</h3>
                          <p><a href="moderate-teacher.html">Kimberly Hanson, M. Ed.</a></p>
                          <p>- Crossroads School of Music</p>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>
    );
}
