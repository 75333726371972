import React, { useState,useEffect,useContext } from "react";
import axios from 'axios'
import HeaderTextBanner from '../../../layout/HeaderTextBanner'
import {isSocialLogin} from '../../../auth/Athentication'
import { useForm } from "react-hook-form";
import { useLocation,Link } from "react-router-dom";
import Success from '../../../layout/alert/Success'
import LoadingIcon from '../../../helpers/LoadingIcon'
import '../../../../css/about.css'
import GlobalContext from "../../../../GlobalContext";

export default function UserSettings(props) {
  const {setIsInnerPage} = useContext(GlobalContext)
  setIsInnerPage(true)
  const [alertShow, setAlertShow] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [formErrors, setFormErrors] = useState(null);      
  const [isSubmitting, setIsSubmitting] = useState(false);            
  const [allowOtherEmail, setAllowOtherEmail] = useState(false);
  const [countries,setCountries] = useState([])
  const { register,handleSubmit,reset,errors,setError,shouldFocus,setValue,getValues } = useForm();
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  let location = useLocation();
  
    useEffect(() => {
      getCountries();
      if(isAlertShown == false && location.state && typeof(location.state.message)!='undefined'){
        setSuccessMessage(location.state.message);
        setAlertShow(true);
      }
      setIsAlertShown(true);
      getUserSettingsDetails();
      
  },[]);


  async function getUserSettingsDetails(){
    const response = await axios.get("user/profile-settings");
    if(typeof(response.data.data)!='undefined' && response.data.status=='success'){
      const info = response.data.data;
      const formInputs = {
        phone:info.phone,
        email:info.email,
        other_email:info.other_email?info.other_email:'',
        city:info.user_setting.city,
        state:info.user_setting.state,
        country_id:info.user_setting.country_id,
        education_approach:info.user_setting.education_approach,
        how_did_you_hear:info.user_setting.how_did_you_hear,
      }
      if(info.other_email){
        setAllowOtherEmail(true);
      } 
      reset(formInputs);
      setTimeout(() => {
          const selectedCntry =getValues().country_id;
          if(!selectedCntry && info.user_setting.country_id){
            setValue('country_id',info.user_setting.country_id)
          }
          setIsFetched(true)
      }, 2800);
    } 
  }
  async function getCountries(){
    const dbcountries = await axios.get("get-countries");
    setCountries(dbcountries.data.data);
  }


        //submission
const formSubmit = (data)=>{
  setIsSubmitting(true);
  setFormErrors(null);
  setSuccessMessage(null);

var post_url ='user/update-settings';
axios.post(post_url, data)
    .then(response => {
        let res = response.data;
        if(res.status=='success'){
            window.scrollTo(0, 0)
            setSuccessMessage(res.message);
            setIsSubmitting(false);
        }else{
            let msg_str ='';
            // console.log(res.message.max_guests[0]);
            Object.keys(res.message).map((ele)=>{
                res.message[ele].map((msg,key) => {
                  setError(ele,{type:'manual',message:msg,shouldFocus:true});
                    console.log(msg)
                    msg_str += msg+'\n';
                    setIsSubmitting(false);
                    setFormErrors(msg_str);
                    window.scrollTo(0, 0)
                });

            });
            
        }     
    })
    .catch(error => {
        setIsSubmitting(false);
        console.log(error);
        alert('Internal server error');
    });

}; 

  return (
    <>
    {alertShow && successMessage && <Success setAlertShow={setAlertShow} message={[successMessage]} show={alertShow} /> }
    <HeaderTextBanner heading="Settings" />
    <section className="user-dashboard">
        <div className="container-fluid cf">
          <div className="parent-profile">
            <div className="row">
              <div className="col-md-8 offset-2">
    {!alertShow && successMessage ? 
      <div className="alert alert-success">{successMessage}</div> :(formErrors?<div className="alert alert-danger">{formErrors}</div> 
      : '')}
          {isFetched ?
                <form  className="form-horizontal teacher_form" onSubmit={handleSubmit(formSubmit)}>
                  <h2>Settings</h2>
                  <div className="control-group">
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Location</label> 
                        </div>
                        <div className="col-md-4">
                          <div className="controls">
                            <input id="city" className="form-control input-large" ref={register} name="city" type="text" placeholder="City" required />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="controls">
                            <input id="state" className="form-control input-large" ref={register} name="state" type="text" placeholder="State" required />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="controls">
                          <select
                          id="country_id"
                          ref={register} 
                          className="form-control input-large"
                          name="country_id" 
                          >
                            <option value="">Country</option>  
                            {countries && countries.map((country,index)=>{
                              return <option key={index}  value={country.id}>{country.country_name}</option>
                            })}
                          </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="label_info" htmlFor="teacher">How did you hear about us?</label> 
                        <div className="controls">
                          <div className="selectdiv">
                            <select name="how_did_you_hear" ref={register} id="how_did_you_hear" className="form-control">
                              <option value="">Select</option>
                              <option value="online">Online</option>
                              <option value="friend">From a friend</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="label_info" htmlFor="teacher">Education Approach</label> 
                        <div className="controls">
                          <div className="selectdiv">
                            <select name="education_approach" ref={register} id="education_approach" className="form-control">
                              <option value="">Select</option>
                              <option value="Local public/charter school">Local public/charter school</option>
                              <option value="Local private/parochial school">Local private/parochial school</option>
                              <option value="Homeschooling with charter school">Homeschooling with charter school</option>
                              <option value="Independent Homeschooling">Independent Homeschooling</option>
                              <option value="Unschooling">Unschooling</option>
                              <option value="Multiple kids, varies">Multiple kids, varies</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label className="label_info" htmlFor="teacher">Phone</label><br />
                        {/* <label class="label_info" for="teacher">For customer support. We won't share it with anyone else.</label> */}
                        <div className="controls">
                          <input id="phone" name="phone" ref={register}  className="form-control input-large" type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label className="label_info" htmlFor="teacher">Primary Email</label> <br />
                        <label className="label_info" htmlFor="teacher">You will recieve all email notifications here.</label> 
                        <div className="controls">
                          <input id="email" ref={register} className="form-control input-large" name="email" type="text" readOnly={true} />
                        </div>
                        { errors.email && <p className="text-danger">{errors.email.message}</p> } 
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Other Email</label> 
                          {allowOtherEmail ? 
                            <><input
                                id="other_email"
                                className="form-control input-large"
                                name="other_email"
                                type="text"
                                ref={register}
                                />
                                { errors.other_email && <p className="text-danger">{errors.other_email.message}</p> } 
                                </>:
                            <div className="controls">
                          <a onClick={()=>{setAllowOtherEmail(true)}} className="get_button add_mail">
                            + Add another email
                          </a>
                        </div>
                      }
                        </div>
                      </div>
                    </div>
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Email Settings</label> 
                          <div className="controls">
                            <Link to="/user/email-settings" className="get_button add_mail">Choose which emails you will receive</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  {!isSocialLogin() ?   
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Password</label> 
                          <div className="controls">
                            <Link to="/change-password" className="get_button add_mail">Change your password</Link><br />
                            <span>Do you have multiple Actyvate Learning accounts? <a href>Merge accounts</a></span>
                          </div>
                        </div>
                      </div>
                    </div>
                     :''}
                    <div className="control-group">
                      <div className="row">
                        <div className="col-md-12">
                          <label className="label_info" htmlFor="teacher">Facebook</label> 
                          <div className="controls">
                            <span>Link your Facebook account to log in easily from any device.</span><br />
                            <a href="#" className="get_button facebook-btn"><span><img src="assets/img/facebook.svg" /></span> Connect with Facebook</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="control-group">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="controls">
                          <button id="confirmsignup" disabled={isSubmitting} name="confirmsignup" className="btn btn-success register-btn">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                : <LoadingIcon /> }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
